<div style="border: 1px solid darkgray;
border-radius: 5px;
background-color: white;
display: flex;
flex-direction: column;
box-shadow: 7px 10px 28px -10px rgba(0,0,0,0.63);
text-align: center;
">

  <div class="section-header">
    <h1>Cámaras</h1>
  </div>
  <table mat-table [dataSource]="cameraList" style="width: 100%;margin-top: 20px;">

    <ng-container matColumnDef="ID">
      <th mat-header-cell *matHeaderCellDef> Identificador </th>
      <td mat-cell *matCellDef="let camera">   Cámara {{ camera.id.split('_')[1] }}  </td>
    </ng-container>

    <ng-container matColumnDef="Nombre">
      <th mat-header-cell *matHeaderCellDef> Nombre </th>
      <td mat-cell *matCellDef="let camera"> {{camera.name}} </td>
    </ng-container>

    <ng-container matColumnDef="Estado">
      <th mat-header-cell *matHeaderCellDef> Estado </th>
      <td mat-cell *matCellDef="let camera">
       
        <span class="material-icons connected" *ngIf="camera.isActive" style="color: green;"> sensors </span>
        <span class="material-icons disconnected" *ngIf="!camera.isActive" style="color: gray;"> sensors </span>
      </td>
      
    
    </ng-container>

    <ng-container matColumnDef="Opciones">
      <th width="25%" mat-header-cell *matHeaderCellDef> Opciones </th>
      <td width="25%" mat-cell *matCellDef="let camera">
        <button mat-flat-button color="primary" (click)="seeDetails(camera)" [disabled]="camera.isActive==false">
          <span class="material-icons"> visibility </span>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnas"></tr>
    <tr mat-row *matRowDef="let row; columns: columnas;"></tr>
  </table>
</div>