import { AirQuality } from 'src/app/models/zamakona/airQuality';
import { HttpClient } from '@angular/common/http';
import { IZamakonaSensorService } from './parent/zamakonaSensorService';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { mockAirQuality } from 'src/app/models/zamakona/mocks/mockAirQuality';

@Injectable({
  providedIn: 'root',
})
export class AirQualityService implements IZamakonaSensorService {
  private baseUrl: string = environment.backendUrl;

  constructor(private http: HttpClient, private socket: Socket) { }

  changeSignalSwitch(body: { id: string; signal: string; }): Observable<{ id: string; signal: string; }> {
    throw new Error('Method not implemented.');
  }

  newData(): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on('new-data', (dato: any) => {
        subscriber.next(dato);
      });
    });
  }

  getAll(): Observable<AirQuality[]> {
    const url: string = 'airQuality/getAll';
    return this.http.get<AirQuality[]>(this.baseUrl + url).pipe(map((result: AirQuality[]) => this.typingDataArr(result)));
    // if (!environment.enableMock) {
    //   return this.http.get<AirQuality[]>(this.baseUrl + url).pipe(map((result: AirQuality[]) => this.typingDataArr(result)));
    // } else {
    //   const info = new Observable<AirQuality[]>((subscriber) => {
    //     subscriber.next([mockAirQuality]);
    //   });
    //   return info;
    // }
  }
  getByName(name: string): Observable<AirQuality[]> {
    const url: string = "airQuality/getByName";
    if (!environment.enableMock) {
      return this.http.get<AirQuality[]>(this.baseUrl + url + '?name=' + name).pipe(map((result: AirQuality[]) => this.typingDataArr(result)));
    } else {
      const info = new Observable<AirQuality[]>((subscriber) => {
        subscriber.next([mockAirQuality]);
      });
      return info;
    }
  }

  getById(id: string): Observable<AirQuality> {
    const url: string = 'airQuality/getById';
    if (!environment.enableMock) {
      return this.http.get<AirQuality>(
        this.baseUrl + url + '?roomId=' + id,
      ).pipe(map((result: AirQuality) => this.typingData(result)));
    } else {
      const info = new Observable<AirQuality>((subscriber) => {
        subscriber.next(mockAirQuality);
      });
      return info;
    }
  }

  create(sensor: AirQuality): Observable<AirQuality> {
    const url: string = 'airQuality/create';
    return this.http.post<AirQuality>(this.baseUrl + url, sensor).pipe(map((result) => this.typingData(result)));
  }

  update(sensor: AirQuality): Observable<AirQuality> {
    const url: string = 'airQuality/update';
    return this.http.put<AirQuality>(this.baseUrl + url, sensor).pipe(map((result) => this.typingData(result)));
  }

  delete(id: string): Observable<AirQuality> {
    const url: string = 'airQuality/delete';
    return this.http.delete<any>(this.baseUrl + url + '?id=' + id).pipe(map((result) => this.typingData(result)));
  }

  typingDataArr(result: AirQuality[]): AirQuality[] {
    return result.map((sensor: AirQuality) => {
      return AirQuality.createByJson(sensor);
    });
  }

  typingData(result: AirQuality): AirQuality {
    return AirQuality.createByJson(result);
  }
}
