/* eslint-disable max-len */
import { DialogRoomMapComponent } from './dialog-room-map/dialog-room-map.component';
import { Switch } from './../../models/switch';
import { AdvancedAmbience } from './../../models/advanced-ambience';
import { Magnetic } from './../../models/magnetic';
import { SensorService } from './../../services/sensor.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Room } from './../../models/room';
import { RoomService } from './../../services/room.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-room-map',
  templateUrl: './room-map.component.html',
  styleUrls: ['./room-map.component.scss'],
})
export class RoomMapComponent implements OnInit {
  buildingId: string = '';
  roomList: Room[] = [];
  selectedRoom: Room = new Room('', 'room', '', '', '', new Date(), new Date(), '');
  magneticList: Magnetic[] = [];
  ambienceList: AdvancedAmbience[] = [];
  switchList: Switch[] = [];
  loading = true;
  constructor(private dialog: MatDialog,
              private route: ActivatedRoute,
              private snackbar: MatSnackBar,
              private roomService: RoomService,
              private sensorService: SensorService) { }

  async ngOnInit() {
    this.buildingId = this.route.snapshot.params.buildingId;
    await this.roomService.getRoomsByBuilding(this.buildingId).toPromise()
        .then((res) => {
          this.roomList = res as Room[];
        })
        .catch((err) => {
          this.snackbar.open(err, 'OK', {duration: 5000});
        });
    this.selectedRoom = this.roomList[0];
    await this.getSensorsByRoomId();
  }

  async getSensorsByRoomId(): Promise<any> {
    const promiseSensorList: Array<Promise<any>> = [];
    promiseSensorList.push(this.sensorService.getMagneticByRoomId(this.selectedRoom.id).toPromise());
    promiseSensorList.push(this.sensorService.getAdvancedAmbienceByRoomId(this.selectedRoom.id).toPromise());
    promiseSensorList.push(this.sensorService.getSwitchByRoomId(this.selectedRoom.id).toPromise());
    await Promise.all(promiseSensorList)
        .then((res: any[]) => {
          this.magneticList = [...res[0]] as Magnetic[];
          this.ambienceList = [...res[1]] as AdvancedAmbience[];
          this.switchList = [...res[2]] as Switch[];
        })
        .catch((err)=> {
          this.snackbar.open(err, 'OK', {duration: 5000});
        })
        .finally(() => {
          this.loading = false;
          // window.setTimeout((()=> this.loading = false), 2000);
        });
  }

  infoDialogMagnetic() {
    this.infoDialog($localize`Magnetic Sensors`, this.magneticList);
  }
  infoDialogAmbience() {
    this.infoDialog($localize`Advanced Ambience Sensors`, this.ambienceList);
  }
  infoDialogSwitch() {
    this.infoDialog($localize`Switch Sensors`, this.switchList);
  }

  infoDialog(title: string, sensorList: any) {
    this.dialog.open(DialogRoomMapComponent, {
      data: {
        title,
        sensorList,
      },
      width: '80%', maxWidth: '600px'});
  }
}
