import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectForm } from 'src/app/interfaces/selectForm';
import { Basic } from 'src/app/models/basic';
import { Room } from 'src/app/models/room';
import { Switch } from 'src/app/models/switch';
import { RoomService } from 'src/app/services/room.service';
import { SensorService } from 'src/app/services/sensor.service';

@Component({
  selector: 'app-sensor-dialog',
  templateUrl: './sensor-dialog.component.html',
  styleUrls: ['./sensor-dialog.component.scss'],
})
export class SensorDialogComponent implements OnInit {
  newSensor: boolean = true;
  sensor!: any;
  typeList: SelectForm[] = [
    { value: 'magnetic', name: $localize`Magnetic` },
    { value: 'advanced_ambience', name: $localize`Advanced Ambience` },
    { value: 'switch', name: $localize`Switch` },
  ];
  roomList: Room[] = [];
  deviceList: SelectForm[] = [
    { value: '', name: '' },
    { value: 'light', name: $localize`Light` },
    { value: 'air-conditioning', name: $localize`Air-Conditioning` },
    { value: 'heating', name: $localize`Heating` },
    { value: 'camera', name: $localize`Camera` },
    { value: 'others', name: $localize`Plug (others)` },
  ];
  isNew = false;

  sensorForm: FormGroup;
  idField: FormControl;
  typeField: FormControl;
  roomIdField: FormControl;
  nameField: FormControl;
  descriptionField: FormControl;
  device1Field: FormControl;
  device2Field: FormControl;

  constructor(
    private dialogRef: MatDialogRef<SensorDialogComponent>,
    private sensorService: SensorService,
    private roomService: RoomService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any) {
    this.isNew = dataDialog.new;
    this.idField = new FormControl('', [Validators.required]);
    this.typeField = new FormControl('', [Validators.required]);
    this.roomIdField = new FormControl('', [Validators.required]);
    this.nameField = new FormControl('', [Validators.required]);
    this.descriptionField = new FormControl('', [Validators.required]);
    this.device1Field = new FormControl('', []);
    this.device2Field = new FormControl('', []);
    this.sensorForm = new FormGroup({
      id: this.idField,
      type: this.typeField,
      roomId: this.roomIdField,
      name: this.nameField,
      description: this.descriptionField,
      device1: this.device1Field,
      device2: this.device2Field,
    });
  }

  ngOnInit(): void {
    this.roomService.getAllRooms().subscribe((result) => {
      this.roomList = result;
      if (!this.newSensor) {
        this.idField.setValue(this.sensor.id);
        this.typeField.setValue(this.sensor.type);
        this.roomIdField.setValue(this.sensor.roomId);
        this.nameField.setValue(this.sensor.name);
        this.descriptionField.setValue(this.sensor.description);
        this.device1Field.setValue(this.sensor.device1 || '');
        this.device2Field.setValue(this.sensor.device2 || '');
        this.typeField.disable();
        this.idField.disable();
        this.typeField.setValidators([]);
      } else {
        this.idField.setValue('');
        this.idField.enable();
        this.typeField.enable();
      }
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });
  }

  onSubmit(): void {
    this.newSensor ? this.createSensor() : this.updateSensor();
  }

  createSensor(): void {
    const newSensor = new Basic(this.idField.value,
        this.typeField.value, this.roomIdField.value, this.nameField.value,
        this.descriptionField.value, new Date(), new Date(),
        100, 'connected');
    switch (this.typeField.value) {
      case "magnetic":
        this.sensorService.createMagnetic(newSensor).subscribe(() => {
          this.closeDialog();
        }, (error) => {
          this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
          this.closeDialog();
        });
        break;
      case "advanced_ambience":
        this.sensorService.createAdvancedAmbience(newSensor).subscribe(() => {
          this.closeDialog();
        }, (error) => {
          this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
          this.closeDialog();
        });
        break;
      case "switch":
        const newSwitch = new Switch(newSensor.id, newSensor.type, newSensor.roomId, newSensor.name,
            newSensor.description, newSensor.dateCreated, newSensor.dateModified, newSensor.battery,
            newSensor.signal, this.device1Field.value, 'off', this.device2Field.value, 'off');
        this.sensorService.createSwitch(newSwitch).subscribe(() => {
          this.closeDialog();
        }, (error) => {
          this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
          this.closeDialog();
        });
        break;
      default:
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        this.closeDialog();
        break;
    }
  }

  updateSensor(): void {
    const modifiedSensor = new Basic(this.sensor.id,
        this.sensor.type, this.roomIdField.value, this.nameField.value,
        this.descriptionField.value, this.sensor.dateCreated, new Date(),
        this.sensor.battery, this.sensor.signal);
    switch (this.sensor.type) {
      case "magnetic":
        this.sensorService.updateMagnetic(modifiedSensor).subscribe(() => {
          this.closeDialog();
        }, (error) => {
          this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
          this.closeDialog();
        });
        break;
      case "advanced_ambience":
        this.sensorService.updateAdvancedAmbience(modifiedSensor).subscribe(() => {
          this.closeDialog();
        }, (error) => {
          this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
          this.closeDialog();
        });
        break;
      case "switch":
        const modifiedSwitch = new Switch(modifiedSensor.id,
            modifiedSensor.type, modifiedSensor.roomId, modifiedSensor.name,
            modifiedSensor.description, modifiedSensor.dateCreated, modifiedSensor.dateModified,
            modifiedSensor.battery, modifiedSensor.signal, this.device1Field.value,
            this.sensor.status1, this.device2Field.value, this.sensor.status2);
        this.sensorService.updateSwitch(modifiedSwitch).subscribe(() => {
          this.closeDialog();
        }, (error) => {
          this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
          this.closeDialog();
        });
        break;
      default:
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        this.closeDialog();
        break;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
