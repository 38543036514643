/* eslint-disable camelcase */

import { WaterQuality } from "../waterQuality";

export const mockWaterQuality = WaterQuality.createByJson({
  id: "00-04-a3-b0-01-19-5e-da",
  type: "water_quality",
  battery: 98,
  conductivity9487: 53.893001556,
  conductivityNotCompensated9487: 54.005001068,
  dateCreated: "13/9/2023 12:20:00",
  dateModified: "13/9/2023 13:05:27",
  description: "",
  name: "Estación de Calidad del Agua",
  pOxygenMgl9488: 7.64509964,
  pOxygenPpm9488: 7.64509964,
  pOxygenSat9488: 92.459999084,
  pPh9485: 8.11950016,
  pPhehtHMV9485: -66.056999207,
  pRedox9485: 192.629989624,
  pTemperature9485: 24.892000198,
  pTemperature9488: 24.906000137,
  pxTemperature9353: 27.669998169,
  pxTurbidityMgl9353: 15.973999023,
  pxTurbidityNtu9353: 24.916999817,
  salinity9487: 35.653999329,
  signal: "connected",
  status: "status",
  temperature9487: 25.107999802,
  lat: 28.15973186350595, 
  lng: -15.407801734084606
});
