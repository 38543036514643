import { Component, Inject } from '@angular/core';
import { ZamakonaSensorDetailDialogComponent } from '../zamakona-sensor-detail-dialog.component';
import { AirQualityService } from 'src/app/services/zamakona/airQuality.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AirQuality } from 'src/app/models/zamakona/airQuality';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { HistoricData } from 'src/app/models/zamakona/historicData';

@Component({
  templateUrl: './../zamakona-sensor-detail-dialog.component.html',
  styleUrls: ['./../zamakona-sensor-detail-dialog.component.scss']
})


export class ZamakonaAirQualityDetailComponent extends ZamakonaSensorDetailDialogComponent<AirQualityService> {

  sensor!: AirQuality;
  grafanaUrlSafe: SafeResourceUrl;

  constructor(
    protected snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) protected matDialogData: HistoricData<AirQuality>,
    private sanitizer: DomSanitizer) {
    super(snackBar, matDialogData);

    this.sensorType = 'Air Quality';

    this.dataView = [
      {label: 'Type', value: this.sensor.type},
      {label: 'Battery', value: this.sensor.battery},
      {label: 'Charging Status', value: this.sensor.chargingStatus},
      {label: 'Date Created', value: this.sensor.dateCreated},
      {label: 'Date Modified', value: this.sensor.dateModified},
      {label: 'Description', value: this.sensor.description},
      {label: 'Humidity', value: this.sensor.humidity},
      {label: 'Hydrogen', value: this.sensor.hydrogen},
      {label: 'Methane', value: this.sensor.methane},
      {label: 'Monoxide', value: this.sensor.monoxide},
      {label: 'Name', value: this.sensor.name},
      {label: 'Noise Fast', value: this.sensor.noiseFast},
      {label: 'Noise Slow', value: this.sensor.noiseSlow},
      {label: 'Particle Pmx01', value: this.sensor.particlePmx01},
      {label: 'Particle Pmx02', value: this.sensor.particlePmx02},
      {label: 'Particle Pmx03', value: this.sensor.particlePmx03},
      {label: 'Pressure', value: this.sensor.pressure},
      {label: 'Signal', value: this.sensor.signal},
    ];
    
    this.grafanaUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.matDialogData.grafanaUrlSafe);
  }
}
