<mat-grid-list class="dialog-header" cols="2" rowHeight="30px">
  <mat-grid-tile>
    <span i18n *ngIf="isNew">Add Building</span>
    <span i18n *ngIf="!isNew">Edit Building</span>
  </mat-grid-tile>
  <mat-grid-tile>
    <button (click)="closeDialog()">
      <span class="material-icons"> close </span>
    </button>
  </mat-grid-tile>
</mat-grid-list>
<div mat-dialog-content>
  <form autocomplete="off" class="building-form" (ngSubmit)="onSubmit()" [formGroup]="buildingForm">

    <input id="username" style="display:none" type="text" name="fakeusernameremembered">
    <input id="password" style="display:none" type="password" name="fakepasswordremembered">

    <mat-grid-list cols="2" rowHeight="60px">
      <mat-grid-tile>
        <mat-form-field class="id-building-container">
          <mat-label i18n>Id:</mat-label>
          <input id="id" matInput formControlName="id" readonly>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field class="name-building-container">
          <mat-label i18n>Building Name:</mat-label>
          <input matInput type="text" formControlName="buildingName">
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field class="description-building-container">
          <mat-label i18n>Description:</mat-label>
          <input matInput type="text" formControlName="description">
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field class="building-type-building-container">
          <mat-label i18n>Building type:</mat-label>
          <mat-select formControlName="buildingType">
            <mat-option *ngFor="let type of buildingTypes" [value]="type.value">
              {{ type.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field class="latitude-building-container">
          <mat-label i18n>Latidude:</mat-label>
          <input matInput type="number" formControlName="latitude">
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field class="longitude-building-container">
          <mat-label i18n>Longitude:</mat-label>
          <input matInput type="number" formControlName="longitude">
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list><br>
    <button i18n mat-raised-button class="submit" color="primary" type="submit">Submit</button>
  </form>
</div>