import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectForm } from 'src/app/interfaces/selectForm';
import { Building } from 'src/app/models/building';
import { BuildingService } from 'src/app/services/building.service';
import { Types } from 'src/app/shared/type-constants';

@Component({
  selector: 'app-building-dialog',
  templateUrl: './building-dialog.component.html',
  styleUrls: ['./building-dialog.component.scss'],
})
export class BuildingDialogComponent implements OnInit {
  newBuilding: boolean = true;
  building!: Building;

  buildingForm: FormGroup;
  idField: FormControl;
  nameField: FormControl;
  descriptionField: FormControl;
  buildingTypeField: FormControl;
  latitudeField: FormControl;
  longitudeField: FormControl;

  buildingTypes: SelectForm[] = Types.BUILDING_TYPES;

  isNew: boolean;

  constructor(
  private dialogRef: MatDialogRef<BuildingDialogComponent>,
  private buildingService: BuildingService,
  private snackBar: MatSnackBar,
  @Inject(MAT_DIALOG_DATA) public dataDialog: any) {
    this.isNew = dataDialog.new;
    this.idField = new FormControl('newBuilding', []);
    this.nameField = new FormControl('', [Validators.required]);
    this.descriptionField = new FormControl('', [Validators.required]);
    this.buildingTypeField = new FormControl('', [Validators.required]);
    this.latitudeField = new FormControl('', [Validators.required]);
    this.longitudeField = new FormControl('', [Validators.required]);
    this.buildingForm = new FormGroup({
      id: this.idField,
      buildingName: this.nameField,
      description: this.descriptionField,
      buildingType: this.buildingTypeField,
      latitude: this.latitudeField,
      longitude: this.longitudeField,
    });
  }

  ngOnInit(): void {
    if (!this.newBuilding) {
      this.idField.setValue(this.building.id);
      this.nameField.setValue(this.building.name);
      this.descriptionField.setValue(this.building.description);
      this.buildingTypeField.setValue(this.building.buildingType);
      this.latitudeField.setValue(this.building.lat);
      this.longitudeField.setValue(this.building.lng);
    }
    this.idField.disable();
  }

  onSubmit(): void {
    this.newBuilding ? this.createBuilding() : this.updateBuilding();
  }

  createBuilding(): void {
    const newBuilding = new Building('newBuilding',
        'building', this.nameField.value, this.descriptionField.value,
        new Date(), new Date(), this.buildingTypeField.value,
        this.latitudeField.value, this.longitudeField.value);
    this.buildingService.createBuilding(newBuilding).subscribe(() => {
      this.closeDialog();
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
      this.closeDialog();
    });
  }

  updateBuilding(): void {
    const modifiedBuilding = new Building(this.building.id,
        'building', this.nameField.value, this.descriptionField.value,
        this.building.dateCreated, new Date(), this.buildingTypeField.value,
        this.latitudeField.value, this.longitudeField.value);
    this.buildingService.updateBuilding(modifiedBuilding).subscribe(() => {
      this.closeDialog();
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
      this.closeDialog();
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
