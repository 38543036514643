<div style="border: 1px solid darkgray;
border-radius: 5px;
background-color: white;
display: flex;
flex-direction: column;
box-shadow: 7px 10px 28px -10px rgba(0,0,0,0.63);
">
  <div id="prueba" class="section-header">
    <h1 style="margin-left: 20px;
    font-weight: 300;">Zona de Observación</h1>
  </div>

  <div id="canvas-container" class="canvas-container"></div>

  <ng-template #dialogTemplate>
    <h2 mat-dialog-title></h2>
    <mat-dialog-content>
      <div style="display:flex; flex-direction: row; " class="container-dialog-detail">
        <div id="canvas-dialog-container" class="canvas-dialog-container"></div>
        <div id="canvas-box-data" class="canvas-box-data">
          <p style="font-weight: 600; font-size: 16px; margin-left: 25px;">{{selectedTask.name}}</p>
          <ul style="list-style: none;" class="parent-ul">
            <li *ngFor="let data of detectionData" class="parent-li">
              <strong>Nº: {{data.id}}</strong> - {{data.name}} - conf: {{data.confidence | number:'1.2-2'}} %
              <ul *ngIf="data.equipment && data.equipment.length > 0" class="child-ul">
                <li *ngFor="let equipment of data.equipment" class="child-li">
                  {{equipment.name}} - conf: {{equipment.confidence | number:'1.2-2' }} %
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button style="background-color: lightgray;" mat-raised-button onclick="this.blur()"
        (click)="closeDialog()">Cerrar</button>
    </mat-dialog-actions>
  </ng-template>


</div>