import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-room-map',
  templateUrl: './dialog-room-map.component.html',
  styleUrls: ['./dialog-room-map.component.scss'],
})
export class DialogRoomMapComponent implements OnInit {
  title: string = '';
  sensorList: any[] = [];
  constructor(private dialogRef: MatDialogRef<DialogRoomMapComponent>,
              @Inject(MAT_DIALOG_DATA) public dataDialog: {title: string, sensorList: any[]}) {
    this.title = dataDialog.title;
    this.sensorList = dataDialog.sensorList;
  }

  ngOnInit() {
  }
}
