<mat-card>
  <div class="section-header">
    <h1 i18n>Buildings</h1>
  </div>

  <div class="buildings-container">
    <button i18n mat-raised-button class="new-building" color="primary" (click)="createBuilding()">
      <span class="material-icons"> add_circle_outline </span>
      Add Building
    </button><br>
  
    <table mat-table [dataSource]="buildingList">
  
      <ng-container matColumnDef="name">
        <th i18n mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let building"> {{ building.name }} </td>
      </ng-container>

      <ng-container matColumnDef="buildingType">
        <th i18n mat-header-cell *matHeaderCellDef> Building's Type </th>
        <td mat-cell *matCellDef="let building"> {{ building.buildingType | buildingType }} </td>
      </ng-container>
  
      <ng-container matColumnDef="options">
        <th i18n width="25%" mat-header-cell *matHeaderCellDef> Options </th>
        <td width="25%" mat-cell *matCellDef="let building">
          <button mat-flat-button color="primary" (click)="seeRooms(building.id)">
            <span class="material-icons"> meeting_room </span>
          </button>
          <button mat-flat-button color="primary" (click)="updateBuilding(building)">
            <span class="material-icons"> edit </span>
          </button>
          <button mat-flat-button color="primary" (click)="deleteBuilding(building.id)">
            <span class="material-icons"> delete </span>
          </button>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</mat-card>