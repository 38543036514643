import { ZamakonaSensor } from "./parent/zamakonaSensor";


/* eslint-disable camelcase */
export class WeatherStation extends ZamakonaSensor {
  absoluteHumidity: number
  airDensity: number
  avgWindDirection: number
  avgWindGustDirection: number
  avgWindGustSpeed: number
  avgWindSpeed: number
  charging_state: number
  compass: number
  corectedWindDirection: number
  current: number
  dewPoint: number
  heatIndex: number
  max: number
  precipIntensity: string
  precipStatus: string
  precipTotal: string
  pressure: number
  pressureSeaLevel: number
  pressureStation: number
  relativeHumidity: number
  secuence: number
  solarRadiation: number
  status: string
  sunNoonTime: string
  sunPosition: string
  sunRiseTime: string
  sunsetTime: string
  sunshineHours: number
  supplyVoltage: number
  temperature: number
  timestamp: string
  twilightAstonom: string
  twilightCivil: string
  twilightNautica: string
  wetBulbTemperature: number
  windChill: string
  windDirection: number
  windSensorStatus: string
  windSpeed: number
  xTilt: number
  yTilt: number
  zOrient: number

  constructor(
    id: string,
    type: string,
    absoluteHumidity: number,
    airDensity: number,
    avgWindDirection: number,
    avgWindGustDirection: number,
    avgWindGustSpeed: number,
    avgWindSpeed: number,
    battery: number,
    charging_state: number,
    compass: number,
    corectedWindDirection: number,
    current: number,
    dateCreated: string,
    dateModified: string,
    description: string,
    dewPoint: number,
    heatIndex: number,
    max: number,
    name: string,
    precipIntensity: string,
    precipStatus: string,
    precipTotal: string,
    pressure: number,
    pressureSeaLevel: number,
    pressureStation: number,
    relativeHumidity: number,
    secuence: number,
    signal: string,
    solarRadiation: number,
    status: string,
    sunNoonTime: string,
    sunPosition: string,
    sunRiseTime: string,
    sunsetTime: string,
    sunshineHours: number,
    supplyVoltage: number,
    temperature: number,
    timestamp: string,
    twilightAstonom: string,
    twilightCivil: string,
    twilightNautica: string,
    wetBulbTemperature: number,
    windChill: string,
    windDirection: number,
    windSensorStatus: string,
    windSpeed: number,
    xTilt: number,
    yTilt: number,
    zOrient: number,
    lat: number,
    lng: number
  ) {
    super(id, type, battery, dateCreated, dateModified, description, name, signal, lat, lng);
    this.absoluteHumidity = absoluteHumidity
    this.airDensity = airDensity
    this.avgWindDirection = avgWindDirection
    this.avgWindGustDirection = avgWindGustDirection
    this.avgWindGustSpeed = avgWindGustSpeed
    this.avgWindSpeed = avgWindSpeed
    this.charging_state = charging_state
    this.compass = compass
    this.corectedWindDirection = corectedWindDirection
    this.current = current
    this.dewPoint = dewPoint
    this.heatIndex = heatIndex
    this.max = max
    this.precipIntensity = precipIntensity
    this.precipStatus = precipStatus
    this.precipTotal = precipTotal
    this.pressure = pressure
    this.pressureSeaLevel = pressureSeaLevel
    this.pressureStation = pressureStation
    this.relativeHumidity = relativeHumidity
    this.secuence = secuence
    this.solarRadiation = solarRadiation
    this.status = status
    this.sunNoonTime = sunNoonTime
    this.sunPosition = sunPosition
    this.sunRiseTime = sunRiseTime
    this.sunsetTime = sunsetTime
    this.sunshineHours = sunshineHours
    this.supplyVoltage = supplyVoltage
    this.temperature = temperature
    this.timestamp = timestamp
    this.twilightAstonom = twilightAstonom
    this.twilightCivil = twilightCivil
    this.twilightNautica = twilightNautica
    this.wetBulbTemperature = wetBulbTemperature
    this.windChill = windChill
    this.windDirection = windDirection
    this.windSensorStatus = windSensorStatus
    this.windSpeed = windSpeed
    this.xTilt = xTilt
    this.yTilt = yTilt
    this.zOrient = zOrient
  }

  static createByJson(data: {
    id: string,
    type: string,
    absoluteHumidity: number,
    airDensity: number,
    avgWindDirection: number,
    avgWindGustDirection: number,
    avgWindGustSpeed: number,
    avgWindSpeed: number,
    battery: number,
    charging_state: number,
    compass: number,
    corectedWindDirection: number,
    current: number,
    dateCreated: string,
    dateModified: string,
    description: string,
    dewPoint: number,
    heatIndex: number,
    max: number,
    name: string,
    precipIntensity: string,
    precipStatus: string,
    precipTotal: string,
    pressure: number,
    pressureSeaLevel: number,
    pressureStation: number,
    relativeHumidity: number,
    secuence: number,
    signal: string,
    solarRadiation: number,
    status: string,
    sunNoonTime: string,
    sunPosition: string,
    sunRiseTime: string,
    sunsetTime: string,
    sunshineHours: number,
    supplyVoltage: number,
    temperature: number,
    timestamp: string,
    twilightAstonom: string,
    twilightCivil: string,
    twilightNautica: string,
    wetBulbTemperature: number,
    windChill: string,
    windDirection: number,
    windSensorStatus: string,
    windSpeed: number,
    xTilt: number,
    yTilt: number,
    zOrient: number,
    lat: number,
    lng: number,
    location: {
      coordinates: any
      type: string
    }
  }) {
    return new WeatherStation(
      data.id,
      data.type,
      data.absoluteHumidity,
      data.airDensity,
      data.avgWindDirection,
      data.avgWindGustDirection,
      data.avgWindGustSpeed,
      data.avgWindSpeed,
      data.battery,
      data.charging_state,
      data.compass,
      data.corectedWindDirection,
      data.current,
      data.dateCreated,
      data.dateModified,
      data.description,
      data.dewPoint,
      data.heatIndex,
      data.max,
      data.name,
      data.precipIntensity,
      data.precipStatus,
      data.precipTotal,
      data.pressure,
      data.pressureSeaLevel,
      data.pressureStation,
      data.relativeHumidity,
      data.secuence,
      data.signal,
      data.solarRadiation,
      data.status,
      data.sunNoonTime,
      data.sunPosition,
      data.sunRiseTime,
      data.sunsetTime,
      data.sunshineHours,
      data.supplyVoltage,
      data.temperature,
      data.timestamp,
      data.twilightAstonom,
      data.twilightCivil,
      data.twilightNautica,
      data.wetBulbTemperature,
      data.windChill,
      data.windDirection,
      data.windSensorStatus,
      data.windSpeed,
      data.xTilt,
      data.yTilt,
      data.zOrient,
      data.location.coordinates[1],
      data.location.coordinates[0]
    );
  }
}
