import { RouterModule, Routes } from '@angular/router';

import { ActionsComponent } from './components/rooms/actions/actions.component';
import { AdvancedAmbienceDetailComponent } from
  './components/sensors/advanced-ambience-detail/advanced-ambience-detail.component';
import { BuildingsComponent } from './components/buildings/buildings.component';
import { CurrentDashboardComponent }
  from './components/current-dashboard/current-dashboard.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MagneticDetailComponent } from
  './components/sensors/magnetic-detail/magnetic-detail.component';
import { MapComponent } from './components/map/map.component';
import { NgModule } from '@angular/core';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { RoomMapComponent } from './components/room-map/room-map.component';
import { RoomsComponent } from './components/rooms/rooms.component';
import { SensorsComponent } from './components/sensors/sensors.component';
import { SensorsTypesComponent } from './components/sensors-types/sensors-types.component';
import { SwitchDetailComponent } from './components/sensors/switch-detail/switch-detail.component';
import { ZamakonaSensorsComponent } from './components/zamakona-sensors/zamakona-sensors.component';
import { ZamakonaSensorRealTimeDashboardComponent } from './components/zamakona-sensors/zamakona-sensor-real-time-dashboard/zamakona-sensor-real-time-dashboard.component';
import { ZamakonaCamerasComponent } from './components/zamakona-cameras/zamakona-cameras/zamakona-cameras.component';
import { ZamakonaCameraDetailComponent } from './components/zamakona-cameras/zamakona-camera-detail/zamakona-camera-detail.component';
import { ZamakonaPlcComponent } from './components/zamakona-plc/zamakona-plc.component';
import { ZamakonaPlcDetailComponent } from './components/zamakona-plc/zamakona-plc-detail/zamakona-plc-detail.component';
import { LoginComponent } from './components/Login/Login.component';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', component: MapComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  // { path: 'current-dashboard', component: CurrentDashboardComponent },
  // { path: 'buildings', component: BuildingsComponent },
  // { path: 'rooms/:buildingId', component: RoomsComponent },
  // { path: 'rooms-map/:buildingId', component: RoomMapComponent },
  // { path: 'sensors/:roomId', component: SensorsComponent },
  { path: 'zamakona-sensors', component: ZamakonaSensorsComponent, canActivate: [AuthGuard] },
  { path: 'zamakona-sensors-real-time-dashboard', component: ZamakonaSensorRealTimeDashboardComponent, canActivate: [AuthGuard] },
  { path: 'zamakona-cameras', component: ZamakonaCamerasComponent, canActivate: [AuthGuard] },
  { path: 'zamakona-cameras/camera/:cameraID', component: ZamakonaCameraDetailComponent, canActivate: [AuthGuard] },
  { path: 'zamakona-plc', component: ZamakonaPlcComponent, canActivate: [AuthGuard] },
  { path: 'zamakona-plc/plc/:plcID', component: ZamakonaPlcDetailComponent, canActivate: [AuthGuard] },
  { path: 'sensors-types', component: SensorsTypesComponent, canActivate: [AuthGuard] },
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
  // { path: 'sensors/:roomId/magnetic/:sensorId', component: MagneticDetailComponent },
  // { path: 'sensors/:roomId/advanced_ambience/:sensorId',
  //   component: AdvancedAmbienceDetailComponent },
  // { path: 'sensors/:roomId/switch/:sensorId', component: SwitchDetailComponent },
  // { path: 'action/:roomId/:roomName', component: ActionsComponent },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
