import { Basic } from "./basic";

export class SimpleAmbience extends Basic {
  constructor(
      id: string,
      type: string,
      roomId: string,
      name: string,
      description: string,
      dateCreated: Date,
      dateModified: Date,
      battery: number,
      signal: string,
    public temperature: number,
    public humidity: number,
  ) {
    super(id, type, roomId, name, description, dateCreated, dateModified, battery, signal);
  }
}
