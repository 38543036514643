<div class="section-header">
  <h1>Rampa {{id==2?"5":"4"}}</h1>
</div>
<div *ngIf="rampData && rampData.dateModified" style="    position: relative;
      display: flex;
      align-items: center;
      justify-content: end;">
  <p style="padding-top: 10px;
  font-size: 20px;
  font-weight: 600;">{{showDate(rampData.dateModified)}}</p>
</div>
<div *ngIf="id==2 && rampData" class="container-ramp5">

  <!--Contenido Cuerpo-->
  <div class="ramp-body">
    <div class="ramp5-header">
      <p class="engine-card-title">Estado</p>
      <div *ngIf="rampData.ControlWord.Bit00.description!='Reserved'" class="ramp-header-data">
        <p>{{rampData.ControlWord.Bit00.description}}</p>
        <span
          [ngClass]="{'green-dot': rampData.ControlWord.Bit01.status, 'red-dot': !rampData.ControlWord.Bit01.status}"></span>

      </div>

      <div *ngIf="rampData.ControlWord.Bit01.description!='Reserved'" class="ramp-header-data">
        <p>{{rampData.ControlWord.Bit01.description}}</p>
        <span
          [ngClass]="{'green-dot': rampData.ControlWord.Bit02.status, 'red-dot': !rampData.ControlWord.Bit02.status}"></span>
      </div>

      <div *ngIf="rampData.ControlWord.Bit02.description!='Reserved'" class="ramp-header-data">
        <p>{{rampData.ControlWord.Bit02.description}}</p>
        <span
          [ngClass]="{'green-dot': rampData.ControlWord.Bit03.status, 'red-dot': !rampData.ControlWord.Bit03.status}"></span>
      </div>

      <div *ngIf="rampData.ControlWord.Bit03.description!='Reserved'" class="ramp-header-data">
        <p>{{rampData.ControlWord.Bit03.description}}</p>
        <span
          [ngClass]="{'green-dot': rampData.ControlWord.Bit04.status, 'red-dot': !rampData.ControlWord.Bit05.status}"></span>
      </div>

      <div *ngIf="rampData.ControlWord.Bit04.description!='Reserved'" class="ramp-header-data">
        <p>{{rampData.ControlWord.Bit04.description}}</p>
        <span
          [ngClass]="{'green-dot': rampData.ControlWord.Bit05.status, 'red-dot': !rampData.ControlWord.Bit05.status}"></span>
      </div>

      <div *ngIf="rampData.ControlWord.Bit05.description!='Reserved'" class="ramp-header-data">
        <p>{{rampData.ControlWord.Bit05.description}}</p>
        <span
          [ngClass]="{'green-dot': rampData.ControlWord.Bit06.status, 'red-dot': !rampData.ControlWord.Bit06.status}"></span>
      </div>

      <div *ngIf="rampData.ControlWord.Bit06.description!='Reserved'" class="ramp-header-data">
        <p>{{rampData.ControlWord.Bit06.description}}</p>
        <span
          [ngClass]="{'green-dot': rampData.ControlWord.Bit07.status, 'red-dot': !rampData.ControlWord.Bit07.status}"></span>
      </div>

      <div *ngIf="rampData.ControlWord.Bit07.description!='Reserved'" class="ramp-header-data">
        <p>{{rampData.ControlWord.Bit07.description}}</p>
        <span
          [ngClass]="{'green-dot': rampData.ControlWord.Bit08.status, 'red-dot': !rampData.ControlWord.Bit08.status}"></span>
      </div>

      <div *ngIf="rampData.ControlWord.Bit08.description!='Reserved'" class="ramp-header-data">
        <p>{{rampData.ControlWord.Bit08.description}}</p>
        <span
          [ngClass]="{'green-dot': rampData.ControlWord.Bit09.status, 'red-dot': !rampData.ControlWord.Bit09.status}"></span>
      </div>

      <div *ngIf="rampData.ControlWord.Bit09.description!='Reserved'" class="ramp-header-data">
        <p>{{rampData.ControlWord.Bit09.description}}</p>
        <span
          [ngClass]="{'green-dot': rampData.ControlWord.Bit10.status, 'red-dot': !rampData.ControlWord.Bit10.status}"></span>
      </div>

      <div *ngIf="rampData.ControlWord.Bit10.description!='Reserved'" class="ramp-header-data">
        <p>{{rampData.ControlWord.Bit10.description}}</p>
        <span
          [ngClass]="{'green-dot': rampData.ControlWord.Bit11.status, 'red-dot': !rampData.ControlWord.Bit11.status}"></span>
      </div>

      <div *ngIf="rampData.ControlWord.Bit11.description!='Reserved'" class="ramp-header-data">
        <p>{{rampData.ControlWord.Bit11.description}}</p>
        <span
          [ngClass]="{'green-dot': rampData.ControlWord.Bit12.status, 'red-dot': !rampData.ControlWord.Bit12.status}"></span>
      </div>

      <div *ngIf="rampData.ControlWord.Bit12.description!='Reserved'" class="ramp-header-data">
        <p>{{rampData.ControlWord.Bit12.description}}</p>
        <span
          [ngClass]="{'green-dot': rampData.ControlWord.Bit13.status, 'red-dot': !rampData.ControlWord.Bit13.status}"></span>
      </div>

      <div *ngIf="rampData.ControlWord.Bit13.description!='Reserved'" class="ramp-header-data">
        <p>{{rampData.ControlWord.Bit13.description}}</p>
        <span
          [ngClass]="{'green-dot': rampData.ControlWord.Bit14.status, 'red-dot': !rampData.ControlWord.Bit14.status}"></span>
      </div>

      <div *ngIf="rampData.ControlWord.Bit14.description!='Reserved'" class="ramp-header-data">
        <p>{{rampData.ControlWord.Bit14.description}}</p>
        <span
          [ngClass]="{'green-dot': rampData.ControlWord.Bit15.status, 'red-dot': !rampData.ControlWord.Bit15.status}"></span>
      </div>
      <div *ngIf="rampData.ControlWord.Bit15.description!='Reserved'" class="ramp-header-data">
        <p>{{rampData.ControlWord.Bit15.description}}</p>
        <span
          [ngClass]="{'green-dot': rampData.ControlWord.Bit15.status, 'red-dot': !rampData.ControlWord.Bit15.status}"></span>
      </div>

    </div>

    <!--WM01-->
    <div class="engine-card">

      <p class="engine-card-title">Winche Principal 01</p>
      <div class="engine-card-body">
        <div>
          <p class="engine-card-body-description">{{rampData.wm01.StatusWord.Bit00.description}}</p>
          <mat-icon *ngIf="rampData.wm01.StatusWord.Bit00.status" style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wm01.StatusWord.Bit00.status" style=" color: red;">highlight_off</mat-icon>
        </div>

        <div>
          <p class="engine-card-body-description">{{rampData.wm01.StatusWord.Bit02.description}}</p>
          <mat-icon *ngIf="rampData.wm01.StatusWord.Bit02.status" style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wm01.StatusWord.Bit02.status" style=" color: red;">highlight_off</mat-icon>
        </div>


        <!-- <div>
          <p class="engine-card-body-description">ErrVariador </p>
          <p>{{rampData.wm01.errVariador.value}}</p>
        </div> -->
        <div>
          <!-- <p class="engine-card-body-description">Intensidad</p> -->
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wm01.intensidad.value?rampData.wm01.intensidad.value:0"
            [unidad]="rampData.wm01.intensidad.dimension"
            [description]="'Intensidad_wm01'"></app-zamakona-sensor-display>
        </div>
        <div>
          <!-- <p class="engine-card-body-description">Par</p> -->
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wm01.par.value?rampData.wm01.par.value:0" [unidad]="rampData.wm01.par.dimension"
            [description]="'Par_wm01'"></app-zamakona-sensor-display>
        </div>
        <div>
          <!-- <p class="engine-card-body-description">Velocidad</p> -->
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wm01.velocidad.value?rampData.wm01.velocidad.value:0"
            [unidad]="rampData.wm01.velocidad.dimension" [description]="'Velocidad_wm01'"></app-zamakona-sensor-display>
        </div>
        <div>
          <!-- <p class="engine-card-body-description">Voltaje</p> -->
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wm01.voltaje.value?rampData.wm01.voltaje.value:0"
            [unidad]="rampData.wm01.voltaje.dimension" [description]="'Voltaje_wm01'"></app-zamakona-sensor-display>

        </div>
        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wm01.potencia.value?rampData.wm01.potencia.value:0"
            [unidad]="rampData.wm01.potencia.dimension" [description]="'Potencia_wm01'"></app-zamakona-sensor-display>
        </div>
      </div>
    </div>
    <!--WM02-->
    <div class="engine-card">
      <p class="engine-card-title">Winche Principal 02</p>
      <div class="engine-card-body">
        <div>
          <p class="engine-card-body-description">{{rampData.wm02.StatusWord.Bit00.description}}</p>

          <mat-icon *ngIf="rampData.wm02.StatusWord.Bit00.status" style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wm02.StatusWord.Bit00.status" style=" color: red;">highlight_off</mat-icon>
        </div>
        <div>
          <p class="engine-card-body-description">{{rampData.wm02.StatusWord.Bit02.description}}</p>
          <mat-icon *ngIf="rampData.wm02.StatusWord.Bit02.status" style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wm02.StatusWord.Bit02.status" style=" color: red;">highlight_off</mat-icon>
        </div>
        <!-- <div>
          <p class="engine-card-body-description">ErrVariador</p>
          <p>{{rampData.wm02.errVariador.value}}</p>
        </div> -->
        <div>
          <!-- <p class="engine-card-body-description">Intensidad</p> -->
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wm02.intensidad.value?rampData.wm02.intensidad.value:0"
            [unidad]="rampData.wm02.intensidad.dimension"
            [description]="'Intensidad_wm02'"></app-zamakona-sensor-display>
        </div>
        <div>
          <!-- <p class="engine-card-body-description">Par</p> -->
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wm02.par.value?rampData.wm02.par.value:0" [unidad]="rampData.wm02.par.dimension"
            [description]="'Par_wm02'"></app-zamakona-sensor-display>
        </div>
        <div>
          <!-- <p class="engine-card-body-description">Velocidad</p> -->
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wm02.velocidad.value?rampData.wm02.velocidad.value:0"
            [unidad]="rampData.wm02.velocidad.dimension" [description]="'Velocidad_wm02'"></app-zamakona-sensor-display>
        </div>
        <div>
          <!-- <p class="engine-card-body-description">Voltaje</p> -->
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wm02.voltaje.value?rampData.wm02.voltaje.value:0"
            [unidad]="rampData.wm02.voltaje.dimension" [description]="'Voltaje_wm02'"></app-zamakona-sensor-display>
        </div>

        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wm01.potencia.value?rampData.wm01.potencia.value:0"
            [unidad]="rampData.wm02.potencia.dimension" [description]="'Potencia_wm02'"></app-zamakona-sensor-display>
        </div>
      </div>
    </div>
    <!--WR01-->

    <div class="engine-card">
      <p class="engine-card-title">Winche de Reenvío 01</p>
      <div class="engine-card-body">
        <div>
          <p class="engine-card-body-description">{{rampData.wr01.StatusWord.Bit00.description}}</p>

          <mat-icon *ngIf="rampData.wr01.StatusWord.Bit00.status" style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wr01.StatusWord.Bit00.status" style=" color: red;">highlight_off</mat-icon>
        </div>
        <div>
          <p class="engine-card-body-description">{{rampData.wr01.StatusWord.Bit02.description}}</p>
          <mat-icon *ngIf="rampData.wr01.StatusWord.Bit02.status" style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wr01.StatusWord.Bit02.status" style=" color: red;">highlight_off</mat-icon>
        </div>
        <!-- <div>
          <p class="engine-card-body-description">ErrVariador</p>
          <p>{{rampData.wr01.errVariador.value}}</p>
        </div> -->
        <div>
          <!-- <p class="engine-card-body-description">Intensidad</p> -->
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wr01.intensidad.value?rampData.wr01.intensidad.value:0"
            [unidad]="rampData.wr01.intensidad.dimension"
            [description]="'Intensidad_wr01'"></app-zamakona-sensor-display>
        </div>
        <div>
          <!-- <p class="engine-card-body-description">Par</p> -->
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wr01.par.value?rampData.wr01.par.value:0" [unidad]="rampData.wr01.par.dimension"
            [description]="'Par_wr01'"></app-zamakona-sensor-display>
        </div>
        <div>
          <!-- <p class="engine-card-body-description">Velocidad</p> -->
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wr01.velocidad.value?rampData.wr01.velocidad.value:0"
            [unidad]="rampData.wr01.velocidad.dimension" [description]="'Velocidad_wr01'"></app-zamakona-sensor-display>
        </div>
        <div>
          <!-- <p class="engine-card-body-description">Voltaje</p> -->
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wr01.voltaje.value?rampData.wr01.voltaje.value:0"
            [unidad]="rampData.wr01.voltaje.dimension" [description]="'Voltaje_wr01'"></app-zamakona-sensor-display>
        </div>
        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wr01.potencia.value?rampData.wr01.potencia.value:0"
            [unidad]="rampData.wr01.potencia.dimension" [description]="'Potencia_wr01'"></app-zamakona-sensor-display>
        </div>
      </div>
    </div>

    <!--WR02-->

    <div class="engine-card">
      <p class="engine-card-title">Winche de Reenvío 02</p>
      <div class="engine-card-body">
        <div>
          <p class="engine-card-body-description">{{rampData.wr02.StatusWord.Bit00.description}}</p>

          <mat-icon *ngIf="rampData.wr02.StatusWord.Bit00.status" style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wr02.StatusWord.Bit00.status" style=" color: red;">highlight_off</mat-icon>
        </div>
        <div>
          <p class="engine-card-body-description">{{rampData.wr02.StatusWord.Bit02.description}}</p>
          <mat-icon *ngIf="rampData.wr02.StatusWord.Bit02.status" style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wr02.StatusWord.Bit02.status" style=" color: red;">highlight_off</mat-icon>
        </div>
        <!-- <div>
          <p class="engine-card-body-description">ErrVariador</p>
          <p>{{rampData.wr02.errVariador.value}}</p>
        </div> -->
        <div>
          <!-- <p class="engine-card-body-description">Intensidad</p> -->
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wr02.intensidad.value?rampData.wr02.intensidad.value:0"
            [unidad]="rampData.wr02.intensidad.dimension"
            [description]="'Intensidad_wr02'"></app-zamakona-sensor-display>
        </div>
        <div>
          <!-- <p class="engine-card-body-description">Par</p> -->
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wr02.par.value?rampData.wr02.par.value:0" [unidad]="rampData.wr02.par.dimension"
            [description]="'Par_wr02'"></app-zamakona-sensor-display>
        </div>
        <div>
          <!-- <p class="engine-card-body-description">Velocidad</p> -->
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wr02.velocidad.value?rampData.wr02.velocidad.value:0"
            [unidad]="rampData.wr02.velocidad.dimension" [description]="'Velocidad_wr02'"></app-zamakona-sensor-display>
        </div>
        <div>
          <!-- <p class="engine-card-body-description">Voltaje</p> -->
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wr02.voltaje.value?rampData.wr02.voltaje.value:0"
            [unidad]="rampData.wr02.voltaje.dimension" [description]="'Voltaje_wr02'"></app-zamakona-sensor-display>
        </div>
        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wr02.potencia.value?rampData.wr02.potencia.value:0"
            [unidad]="rampData.wr02.potencia.dimension" [description]="'Potencia_wr02'"></app-zamakona-sensor-display>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="id==1 && rampData" class="container-ramp5">
  <div class="ramp-body">
    <!--Main data-->
    <div class="ramp4-header">
      <p class="engine-card-title">Estado</p>
      <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
        [displayValue]="rampData.auxMotor01ParControl?rampData.auxMotor01ParControl:0" [unidad]="'%'"
        [description]="'Motor 01 Par_auxmotor01'"></app-zamakona-sensor-display>
      <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
        [displayValue]="rampData.auxMotor02ParControl?rampData.auxMotor02ParControl:0" [unidad]="'%'"
        [description]="'Motor 02 Par_auxmotor02'"></app-zamakona-sensor-display>

      <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
        [displayValue]="rampData.auxMotor01SpeedControl?rampData.auxMotor01SpeedControl:0" [unidad]="'rmp'"
        [description]="'Motor 01 Velocidad_auxmotor01'"></app-zamakona-sensor-display>

      <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
        [displayValue]="rampData.auxMotor02SpeedControl?rampData.auxMotor02SpeedControl:0" [unidad]="'rmp'"
        [description]="'Motor 02 Velocidad_auxmotor02'"></app-zamakona-sensor-display>

      <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
        [displayValue]="rampData.controlSpeedMain?rampData.controlSpeedMain:0" [unidad]="'rmp'"
        [description]="'Velocidad_maindata'"></app-zamakona-sensor-display>

      <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
        [displayValue]="rampData.setPointSpeedMain?rampData.setPointSpeedMain:0" [unidad]="'rmp'"
        [description]="'Velocidad main_maindata'"></app-zamakona-sensor-display>

    </div>
    <div class="engine-card-ramp4 ">
      <p class="engine-card-title">{{rampData.wm01.description}}</p>

      <div class="engine-card-body">
        <div>
          <p class="engine-card-body-description">{{rampData.wm01.StatusWord01.Bit00.description}}</p>
          <mat-icon *ngIf="rampData.wm01.StatusWord01.Bit00.status"
            style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wm01.StatusWord01.Bit00.status" style=" color: red;">highlight_off</mat-icon>
        </div>
        <div>
          <p class="engine-card-body-description">{{rampData.wm01.StatusWord01.Bit01.description}}</p>
          <mat-icon *ngIf="rampData.wm01.StatusWord01.Bit01.status"
            style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wm01.StatusWord01.Bit01.status" style=" color: red;">highlight_off</mat-icon>
        </div>
        <div>
          <p class="engine-card-body-description">{{rampData.wm01.StatusWord01.Bit08.description}}</p>
          <mat-icon *ngIf="rampData.wm01.StatusWord01.Bit08.status"
            style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wm01.StatusWord01.Bit08.status" style=" color: red;">highlight_off</mat-icon>
        </div>
        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wm01.par.value?rampData.wm01.par.value:0" [unidad]="rampData.wm01.par.dimension"
            [description]="'Par_wm01'"></app-zamakona-sensor-display>
        </div>

        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wm01.intensidad.value?rampData.wm01.intensidad.value:0"
            [unidad]="rampData.wm01.intensidad.dimension"
            [description]="'Intensidad_wm01'"></app-zamakona-sensor-display>

        </div>
        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wm01.rpm.value?rampData.wm01.rpm.value:0" [unidad]="rampData.wm01.rpm.dimension"
            [description]="'Rpm_wm01'"></app-zamakona-sensor-display>
        </div>

        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wm01.temperatura.value?rampData.wm01.temperatura.value:0"
            [unidad]="rampData.wm01.temperatura.dimension"
            [description]="'Temperatura_wm01'"></app-zamakona-sensor-display>
        </div>

      </div>

    </div>
    <div class="engine-card-ramp4 ">
      <p class="engine-card-title">{{rampData.wm02.description}}</p>
      <div class="engine-card-body">
        <div>
          <p class="engine-card-body-description">{{rampData.wm02.StatusWord01.Bit00.description}}</p>
          <mat-icon *ngIf="rampData.wm02.StatusWord01.Bit00.status"
            style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wm02.StatusWord01.Bit00.status" style=" color: red;">highlight_off</mat-icon>
        </div>
        <div>
          <p class="engine-card-body-description">{{rampData.wm02.StatusWord01.Bit01.description}}</p>
          <mat-icon *ngIf="rampData.wm02.StatusWord01.Bit01.status"
            style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wm02.StatusWord01.Bit01.status" style=" color: red;">highlight_off</mat-icon>
        </div>
        <div>
          <p class="engine-card-body-description">{{rampData.wm02.StatusWord01.Bit08.description}}</p>
          <mat-icon *ngIf="rampData.wm02.StatusWord01.Bit08.status"
            style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wm02.StatusWord01.Bit08.status" style=" color: red;">highlight_off</mat-icon>
        </div>
        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wm02.par.value?rampData.wm02.par.value:0" [unidad]="rampData.wm02.par.dimension"
            [description]="'Par_wm02'"></app-zamakona-sensor-display>
        </div>

        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wm02.intensidad.value?rampData.wm02.intensidad.value:0"
            [unidad]="rampData.wm02.intensidad.dimension"
            [description]="'Intensidad_wm02'"></app-zamakona-sensor-display>

        </div>
        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wm02.rpm.value?rampData.wm02.rpm.value:0" [unidad]="rampData.wm02.rpm.dimension"
            [description]="'Rpm_wm02'"></app-zamakona-sensor-display>
        </div>

        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wm02.temperatura.value?rampData.wm02.temperatura.value:0"
            [unidad]="rampData.wm02.temperatura.dimension"
            [description]="'Temperatura_wm02'"></app-zamakona-sensor-display>
        </div>

      </div>



    </div>
    <div class="engine-card-ramp4 ">
      <p class="engine-card-title">{{rampData.wr01.description}}</p>
      <div class="engine-card-body">
        <div>
          <p class="engine-card-body-description">{{rampData.wr01.StatusWord01.Bit00.description}}</p>
          <mat-icon *ngIf="rampData.wr01.StatusWord01.Bit00.status"
            style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wr01.StatusWord01.Bit00.status" style=" color: red;">highlight_off</mat-icon>
        </div>
        <div>
          <p class="engine-card-body-description">{{rampData.wr01.StatusWord01.Bit01.description}}</p>
          <mat-icon *ngIf="rampData.wr01.StatusWord01.Bit01.status"
            style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wr01.StatusWord01.Bit01.status" style=" color: red;">highlight_off</mat-icon>
        </div>
        <div>
          <p class="engine-card-body-description">{{rampData.wr01.StatusWord01.Bit08.description}}</p>
          <mat-icon *ngIf="rampData.wr01.StatusWord01.Bit08.status"
            style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wr01.StatusWord01.Bit08.status" style=" color: red;">highlight_off</mat-icon>
        </div>
        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wr01.par.value?rampData.wr01.par.value:0" [unidad]="rampData.wr01.par.dimension"
            [description]="'Par_wr01'"></app-zamakona-sensor-display>
        </div>

        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wr01.intensidad.value?rampData.wr01.intensidad.value:0"
            [unidad]="rampData.wr01.intensidad.dimension"
            [description]="'Intensidad_wr01'"></app-zamakona-sensor-display>

        </div>
        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wr01.rpm.value?rampData.wr01.rpm.value:0" [unidad]="rampData.wr01.rpm.dimension"
            [description]="'Rpm'"></app-zamakona-sensor-display>
        </div>

        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wr01.temperatura.value?rampData.wr01.temperatura.value:0"
            [unidad]="rampData.wr01.temperatura.dimension"
            [description]="'Temperatura_wr01'"></app-zamakona-sensor-display>
        </div>

      </div>



    </div>
    <div class="engine-card-ramp4 ">
      <p class="engine-card-title">{{rampData.wr02.description}}</p>
      <div class="engine-card-body">
        <div>
          <p class="engine-card-body-description">{{rampData.wr02.StatusWord01.Bit00.description}}</p>
          <mat-icon *ngIf="rampData.wr02.StatusWord01.Bit00.status"
            style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wr02.StatusWord01.Bit00.status" style=" color: red;">highlight_off</mat-icon>
        </div>
        <div>
          <p class="engine-card-body-description">{{rampData.wr02.StatusWord01.Bit01.description}}</p>
          <mat-icon *ngIf="rampData.wr02.StatusWord01.Bit01.status"
            style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wr02.StatusWord01.Bit01.status" style=" color: red;">highlight_off</mat-icon>
        </div>
        <div>
          <p class="engine-card-body-description">{{rampData.wr02.StatusWord01.Bit08.description}}</p>
          <mat-icon *ngIf="rampData.wr02.StatusWord01.Bit08.status"
            style="color: green;">check_circle_outline</mat-icon>
          <mat-icon *ngIf="!rampData.wr02.StatusWord01.Bit08.status" style=" color: red;">highlight_off</mat-icon>
        </div>
        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wr02.par.value?rampData.wr02.par.value:0" [unidad]="rampData.wr02.par.dimension"
            [description]="'Par_wr02'"></app-zamakona-sensor-display>
        </div>

        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wr02.intensidad.value?rampData.wr02.intensidad.value:0"
            [unidad]="rampData.wr02.intensidad.dimension"
            [description]="'Intensidad_wr02'"></app-zamakona-sensor-display>

        </div>
        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wr02.rpm.value?rampData.wr02.rpm.value:0" [unidad]="rampData.wr02.rpm.dimension"
            [description]="'Rpm_wr02'"></app-zamakona-sensor-display>
        </div>

        <div>
          <app-zamakona-sensor-display [minValue]="'0'" [maxValue]="'100'"
            [displayValue]="rampData.wr02.temperatura.value?rampData.wr02.temperatura.value:0"
            [unidad]="rampData.wr02.temperatura.dimension"
            [description]="'Temperatura_wr02'"></app-zamakona-sensor-display>
        </div>

      </div>



    </div>
  </div>
</div>