import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdvancedAmbience } from 'src/app/models/advanced-ambience';
import { Building } from 'src/app/models/building';
import { Magnetic } from 'src/app/models/magnetic';
import { Room } from 'src/app/models/room';
import { Switch } from 'src/app/models/switch';
import { BuildingService } from 'src/app/services/building.service';
import { RoomService } from 'src/app/services/room.service';
import { SensorService } from 'src/app/services/sensor.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-current-dashboard',
  templateUrl: './current-dashboard.component.html',
  styleUrls: ['./current-dashboard.component.scss'],
})
export class CurrentDashboardComponent implements OnInit {
  canvasWidth = 170;
  cols = 4;
  dashboardFilter: FormGroup;
  buildingNameField: FormControl;
  roomNameField: FormControl;

  buildingList: Building[] = [];
  roomList: Room[] = [];

  showDashboard: boolean = false;
  selectedRoom: Room = new Room('', 'room', '', '', '', new Date(), new Date(), '');
  sensorList: any[] = [];
  avaliableSensor: number = 0;
  magnetics: number = 0;
  advancedAmbiences: number[] = [0, 0, 0, 0, 0, 0, 0];
  switches: number = 0;
  dateCreated: Date = new Date();
  lastUpdated: Date = new Date(0);
  batteryAlert: number = 0;
  doorsWindowsOpen: number = NaN;
  ambienceData: number[] = [NaN, NaN, NaN, NaN, NaN, NaN, NaN];
  devicesOn: number = NaN;
  totalDevices: number = NaN;

  public optionsTemperature = environment.optionsTemperature;

  public optionsHumidity = environment.optionsHumidity;

  public optionsLight = environment.optionsLight;

  public optionsCO2 = environment.optionsCO2;

  public optionsTVOC = environment.optionsTVOC;

  public optionsBarPress = environment.optionsBarPress;

  constructor(
    private sensorService: SensorService,
    private buildingService: BuildingService,
    private roomService: RoomService,
    private snackBar: MatSnackBar,
  ) {
    this.buildingNameField = new FormControl('', Validators.required);
    this.roomNameField = new FormControl('', Validators.required);
    this.dashboardFilter = new FormGroup({
      buildingName: this.buildingNameField,
      roomName: this.roomNameField,
    });
  }

  ngOnInit(): void {
    this.cols = (window.innerWidth <= 1672) ? (window.innerWidth <= 970) ? 1 : 2 : 4;
    this.buildingService.getAllBuildings().subscribe((result) => {
      this.buildingList = result;
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`);
    });
    this.dashboardFilter.controls['buildingName'].valueChanges.subscribe((buildingName) => {
      this.roomNameField.setValue('');
      const building =
      <Building> this.buildingList.find((building) => building.name === buildingName);
      this.roomService.getRoomsByBuilding(building.id).subscribe((result) => {
        this.roomList = result;
      });
    });
    // Quedamos a la escucha de que entren nuevos datos
    this.loadLiveData();
  }

  onResize(event: any) {
    this.cols = (event.target.innerWidth <= 1672) ? (event.target.innerWidth <= 970) ? 1 : 2 : 4;
  }

  onSubmit(): void {
    // Evitamos volver a cargar los datos si hacemos submit con la misma habitación
    if (this.selectedRoom ===
      <Room> this.roomList.find((room) => room.name === this.roomNameField.value)) {
      return;
    }

    this.sensorList = [];
    this.avaliableSensor = 0;
    this.showDashboard = true;
    this.selectedRoom =
    <Room> this.roomList.find((room) => room.name === this.roomNameField.value);
    this.dateCreated = new Date(this.selectedRoom.dateCreated);
    this.lastUpdated = new Date(0);
    this.batteryAlert = 0;

    this.getSensorsByRoomId().then(() => {
      this.sensorList.forEach((sensor) => {
        // Sensores conectados
        if (sensor.signal == 'connected') {
          this.avaliableSensor++;

          // Fecha de última actualización de un sensor
          this.lastUpdated =
            (new Date(sensor.dateModified).getTime() > this.lastUpdated.getTime()) ?
              new Date(sensor.dateModified) : this.lastUpdated;
        }
      });

      this.lastUpdated = (this.lastUpdated.getTime() == new Date(0).getTime()) ?
        new Date(this.selectedRoom.dateModified) : this.lastUpdated;

      // Alerta de batería baja
      this.sensorList.forEach((sensor) => {
        if (sensor.battery <= 15 && sensor.signal === 'connected') this.batteryAlert++;
      });
    }).catch((error) => {
      this.snackBar.open($localize`An error has occurred`);
    });
  }

  private loadLiveData(): void {
    this.sensorService.newData().subscribe((sensorData) => {
      this.lastUpdated = new Date();
      if (sensorData.roomId === this.selectedRoom.id) {
        this.sensorList.forEach((sensor) => {
          if (sensor.id === sensorData.id) {
            switch (sensorData.type) {
              case 'magnetic':
                if (sensor.status !== sensorData.status) {
                  sensor.status = sensorData.status;
                  const magneticList = this.sensorList.filter((s) => {
                    return (s.type === 'magnetic') ? true : false;
                  }).map((s) => {
                    return s;
                  });
                  this.loadMagneticData(magneticList);
                }
                break;
              case 'advanced_ambience':
                sensor.temperature = sensorData.temperature;
                sensor.humidity = sensorData.humidity;
                sensor.pir = sensorData.pir;
                sensor.light = sensorData.light;
                sensor.co2 = sensorData.co2;
                sensor.tvoc = sensorData.tvoc;
                sensor.barometricPressure = sensorData.barometricPressure;
                const ambienceList = this.sensorList.filter((s) => {
                  return (s.type === 'advanced_ambience') ? true : false;
                }).map((s) => {
                  return s;
                });
                this.loadAmbienceData(ambienceList);
                break;
              case 'switch':
                if (sensor.status1 !== sensorData.status1 ||
                  sensor.status2 !== sensorData.status2) {
                  sensor.status1 = sensorData.status1;
                  sensor.status2 = sensorData.status2;
                  const switchList = this.sensorList.filter((s) => {
                    return (s.type === 'switch') ? true : false;
                  }).map((s) => {
                    return s;
                  });
                  this.loadSwitchData(switchList);
                }
                break;
            }
          }
        });
      }
    });
  }

  private getSensorsByRoomId(): Promise<void> {
    const promise = new Promise<void>(async (resolve, reject) => {
      await this.getMagneticSensors().catch((error) => reject(error));
      await this.getAdvancedAmbienceSensors().catch((error) => reject(error));
      await this.getSwitchSensors().catch((error) => reject(error));
      resolve();
    });

    return promise;
  }

  private getSwitchSensors(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.sensorService.getSwitchByRoomId(this.selectedRoom.id).subscribe((result) => {
        this.sensorList = this.sensorList.concat(result);
        this.loadSwitchData(result);
        resolve();
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`);
        reject(error);
      });
    });
    return promise;
  }

  private getAdvancedAmbienceSensors(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.sensorService.getAdvancedAmbienceByRoomId(this.selectedRoom.id)
          .subscribe((result) => {
            this.sensorList = this.sensorList.concat(result);

            this.loadAmbienceData(result);
            resolve();
          }, (error) => {
            this.snackBar.open($localize`An error has occurred`);
            reject(error);
          });
    });
    return promise;
  }

  private getMagneticSensors(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.sensorService.getMagneticByRoomId(this.selectedRoom.id)
          .subscribe((result) => {
            this.sensorList = this.sensorList.concat(result);

            this.loadMagneticData(result);
            resolve();
          }, (error) => {
            this.snackBar.open($localize`An error has occurred`);
            reject(error);
          });
    });
    return promise;
  }

  private loadMagneticData(result: Magnetic[]) {
    // Reiniciamos las variables a sus valores originales
    this.magnetics = 0;
    this.doorsWindowsOpen = 0;

    result.forEach((sensor) => {
      if (sensor.signal === 'connected') this.magnetics++;

      if (sensor.signal === 'connected' && sensor.status === 'open') this.doorsWindowsOpen++;
    });
  }

  private loadAmbienceData(result: AdvancedAmbience[]): void {
    // Reiniciamos las variables a sus valores originales
    this.advancedAmbiences = [0, 0, 0, 0, 0, 0, 0];
    this.ambienceData = [NaN, NaN, 0, NaN, NaN, NaN, NaN];

    // Temperatura
    this.ambienceData[0] = result.reduce((a, b) => {
      if (b.signal !== 'connected' || isNaN(b.temperature)) {
        return a;
      } else {
        this.advancedAmbiences[0]++;
        return a + b.temperature;
      }
    }, 0);
    this.ambienceData[0] /= this.advancedAmbiences[0];
    this.ambienceData[0] = Math.round(this.ambienceData[0] * 10) / 10;
    this.optionsTemperature.needleStartValue = (this.ambienceData[0] === NaN) ?
      0 : ((this.ambienceData[0] + 20) * 100 / 80);

    // Humedad
    this.ambienceData[1] = result.reduce((a, b) => {
      if (b.signal !== 'connected' || isNaN(b.humidity)) {
        return a;
      } else {
        this.advancedAmbiences[1]++;
        return a + b.humidity;
      }
    }, 0);
    this.ambienceData[1] /= this.advancedAmbiences[1];
    this.ambienceData[1] = Math.round(this.ambienceData[1] * 10) / 10;
    this.optionsHumidity.needleStartValue = (this.ambienceData[1] === NaN) ?
      0 : this.ambienceData[1];

    // PIR
    result.forEach((sensor) => {
      if (sensor.signal === 'connected' && sensor.pir === 'yes') {
        this.advancedAmbiences[2]++;
        this.ambienceData[2]++;
      } else if (sensor.signal === 'connected' && sensor.pir === 'no') {
        this.advancedAmbiences[2]++;
      }
    });

    // Light
    this.ambienceData[3] = result.reduce((a, b) => {
      if (b.signal !== 'connected' || isNaN(b.light)) {
        return a;
      } else {
        this.advancedAmbiences[3]++;
        return a + b.light;
      }
    }, 0);
    this.ambienceData[3] /= this.advancedAmbiences[3];
    this.ambienceData[3] = Math.round(this.ambienceData[3] * 10) / 10;
    this.optionsLight.needleStartValue = (this.ambienceData[3] === NaN) ?
      0 : (this.ambienceData[3] * 100 / 1500);

    // CO2
    this.ambienceData[4] = result.reduce((a, b) => {
      if (b.signal !== 'connected' || isNaN(b.co2)) {
        return a;
      } else {
        this.advancedAmbiences[4]++;
        return a + b.co2;
      }
    }, 0);
    this.ambienceData[4] /= this.advancedAmbiences[4];
    this.ambienceData[4] = Math.round(this.ambienceData[4] * 10) / 10;
    this.optionsCO2.needleStartValue = (this.ambienceData[4] === NaN) ?
      0 : ((this.ambienceData[4] - 400) * 100 / 2600);

    // TVOC
    this.ambienceData[5] = result.reduce((a, b) => {
      if (b.signal !== 'connected' || isNaN(b.tvoc)) {
        return a;
      } else {
        this.advancedAmbiences[5]++;
        return a + b.tvoc;
      }
    }, 0);
    this.ambienceData[5] /= this.advancedAmbiences[5];
    this.ambienceData[5] = Math.round(this.ambienceData[5] * 10) / 10;
    this.optionsTVOC.needleStartValue = (this.ambienceData[5] === NaN) ?
      0 : (this.ambienceData[5] * 100 / 1500);

    // Presión barométrica
    this.ambienceData[6] = result.reduce((a, b) => {
      if (b.signal !== 'connected' || isNaN(b.barometricPressure)) {
        return a;
      } else {
        this.advancedAmbiences[6]++;
        return a + b.barometricPressure;
      }
    }, 0);
    this.ambienceData[6] /= this.advancedAmbiences[6];
    this.ambienceData[6] = Math.round(this.ambienceData[6] * 10) / 10;
    this.optionsBarPress.needleStartValue = (this.ambienceData[6] === NaN) ?
      0 : ((this.ambienceData[6] - 900) * 100 / 200);
  }

  private loadSwitchData(result: Switch[]) {
    // Reiniciamos las variables a sus valores originales
    this.switches = 0;
    this.devicesOn = 0;
    this.totalDevices = 0;

    result.forEach((sensor) => {
      if (sensor.signal === 'connected') this.switches++;

      if (sensor.signal === 'connected' && sensor.device1 != '') {
        this.totalDevices++;
        if (sensor.status1 === 'on') this.devicesOn++;
      }

      if (sensor.signal === 'connected' && sensor.device2 != '') {
        this.totalDevices++;
        if (sensor.status2 === 'on') this.devicesOn++;
      }
    });
  }

  public isNumber(num: number): boolean {
    return !isNaN(num);
  }
}
