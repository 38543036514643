import { Component, Inject } from '@angular/core';
import { WaterQuality } from 'src/app/models/zamakona/waterQuality';
import { WaterQualityService } from 'src/app/services/zamakona/waterQuality.service';
import { ZamakonaSensorDetailDialogComponent } from '../zamakona-sensor-detail-dialog.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HistoricData } from 'src/app/models/zamakona/historicData';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  templateUrl: './../zamakona-sensor-detail-dialog.component.html',
  styleUrls: ['./../zamakona-sensor-detail-dialog.component.scss']
})
export class ZamakonaWaterQualityDetailComponent extends ZamakonaSensorDetailDialogComponent<WaterQualityService> {

  sensor!: WaterQuality;
  grafanaUrlSafe: SafeResourceUrl;
  
  constructor(
    protected snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) protected matDialogData: HistoricData<WaterQuality>,
    private sanitizer: DomSanitizer) {
    super(snackBar, matDialogData);

    this.sensorType = 'Water Quality';

    this.dataView = [
      {label: 'Type', value: this.sensor.type},
      {label: 'Battery', value: this.sensor.battery},
      {label: 'Conductivity9487', value: this.sensor.conductivity9487},
      {label: 'ConductivityNotCompensated9487', value: this.sensor.conductivityNotCompensated9487},
      {label: 'Date Created', value: this.sensor.dateCreated},
      {label: 'Date Modified', value: this.sensor.dateModified},
      {label: 'Description', value: this.sensor.description},
      {label: 'Name', value: this.sensor.name},
      {label: 'pOxygenMgl9488', value: this.sensor.pOxygenMgl9488},
      {label: 'pOxygenPpm9488', value: this.sensor.pOxygenPpm9488},
      {label: 'pOxygenSat9488', value: this.sensor.pOxygenSat9488},
      {label: 'pPh9485', value: this.sensor.pPh9485},
      {label: 'pPhehtHMV9485', value: this.sensor.pPhehtHMV9485},
      {label: 'pRedox9485', value: this.sensor.pRedox9485},
      {label: 'pTemperature9485', value: this.sensor.pTemperature9485},
      {label: 'pTemperature9488', value: this.sensor.pTemperature9488},
      {label: 'pxTemperature9353', value: this.sensor.pxTemperature9353},
      {label: 'pxTurbidityMgl9353', value: this.sensor.pxTurbidityMgl9353},
      {label: 'pxTurbidityNtu9353', value: this.sensor.pxTurbidityNtu9353},
      {label: 'Salinity9487', value: this.sensor.salinity9487},
      {label: 'Signal', value: this.sensor.signal},
      {label: 'Status', value: this.sensor.status},
      {label: 'Temperature9487', value: this.sensor.temperature9487},
    ];

    this.grafanaUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.matDialogData.grafanaUrlSafe);

  }

}
