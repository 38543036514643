import { AdvancedAmbience } from '../models/advanced-ambience';
import { Basic } from '../models/basic';
import { ChangeSignalBody } from '../interfaces/changeSignalBody';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Magnetic } from '../models/magnetic';
import { Observable } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { Switch } from '../models/switch';
import { SwitchOnOffBody } from '../interfaces/switchOnOffBody';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SensorService {
  private baseUrl: string = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private socket: Socket,
  ) { }

  newData(): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on('new-data', (dato: any) => {
        subscriber.next(dato);
      });
    });
  }

  getAllMagnetics(): Observable<Magnetic[]> {
    const url: string = "magnetic/getAll";
    return this.http.get<Magnetic[]>(this.baseUrl + url);
  }

  getMagneticById(id: string): Observable<Magnetic> {
    const url: string = "magnetic/getById";
    return this.http.get<Magnetic>(this.baseUrl + url + '?id=' + id);
  }

  getMagneticByName(name: string, roomId: string): Observable<Magnetic[]> {
    const url: string = "magnetic/getByName";
    return this.http.get<Magnetic[]>(this.baseUrl + url + '?name=' + name + '&roomId=' + roomId);
  }

  getMagneticByRoomId(roomId: string): Observable<Magnetic[]> {
    const url: string = "magnetic/getByRoomId";
    return this.http.get<Magnetic[]>(this.baseUrl + url + '?roomId=' + roomId);
  }

  createMagnetic(basic: Basic): Observable<Magnetic> {
    const url: string = "magnetic/create";
    return this.http
      .post<Magnetic>(this.baseUrl + url, basic);
  }

  updateMagnetic(basic: Basic): Observable<Magnetic> {
    const url: string = "magnetic/update";
    return this.http
      .post<Magnetic>(this.baseUrl + url, basic);
  }

  deleteMagnetic(id: string): Observable<any> {
    const url: string = "magnetic/delete";
    return this.http.delete<any>(this.baseUrl + url + '?id=' + id);
  }

  changeSignalMagnetic(body: ChangeSignalBody): Observable<any> {
    const url: string = "magnetic/changeSignal";
    return this.http.post<any>(this.baseUrl + url, body);
  }

  getAllAdvancedAmbiences(): Observable<AdvancedAmbience[]> {
    const url: string = "advancedAmbience/getAll";
    return this.http.get<AdvancedAmbience[]>(this.baseUrl + url);
  }

  getAdvancedAmbienceById(id: string): Observable<AdvancedAmbience> {
    const url: string = "advancedAmbience/getById";
    return this.http.get<AdvancedAmbience>(this.baseUrl + url + '?id=' + id);
  }

  getAdvancedAmbienceByName(name: string, roomId: string): Observable<AdvancedAmbience[]> {
    const url: string = "advancedAmbience/getByName";
    return this.http.get<AdvancedAmbience[]>(this.baseUrl + url +
      '?name=' + name + '&roomId=' + roomId);
  }

  getAdvancedAmbienceByRoomId(roomId: string): Observable<AdvancedAmbience[]> {
    const url: string = "advancedAmbience/getByRoomId";
    return this.http.get<AdvancedAmbience[]>(this.baseUrl + url + '?roomId=' + roomId);
  }

  createAdvancedAmbience(basic: Basic): Observable<AdvancedAmbience> {
    const url: string = "advancedAmbience/create";
    return this.http
      .post<AdvancedAmbience>(this.baseUrl + url, basic);
  }

  updateAdvancedAmbience(basic: Basic): Observable<AdvancedAmbience> {
    const url: string = "advancedAmbience/update";
    return this.http
      .post<AdvancedAmbience>(this.baseUrl + url, basic);
  }

  deleteAdvancedAmbience(id: string): Observable<any> {
    const url: string = "advancedAmbience/delete";
    return this.http.delete<any>(this.baseUrl + url + '?id=' + id);
  }

  changeSignalAdvancedAmbience(body: ChangeSignalBody): Observable<any> {
    const url: string = "advancedAmbience/changeSignal";
    return this.http.post<any>(this.baseUrl + url, body);
  }

  getAllSwitches(): Observable<Switch[]> {
    const url: string = "switch/getAll";
    return this.http.get<Switch[]>(this.baseUrl + url);
  }

  getSwitchById(id: string): Observable<Switch> {
    const url: string = "switch/getById";
    return this.http.get<Switch>(this.baseUrl + url + '?id=' + id);
  }

  getSwitchByName(name: string, roomId: string): Observable<Switch[]> {
    const url: string = "switch/getByName";
    return this.http.get<Switch[]>(this.baseUrl + url + '?name=' + name + '&roomId=' + roomId);
  }

  getSwitchByRoomId(roomId: string): Observable<Switch[]> {
    const url: string = "switch/getByRoomId";
    return this.http.get<Switch[]>(this.baseUrl + url + '?roomId=' + roomId);
  }

  createSwitch(switchSensor: Switch): Observable<Switch> {
    const url: string = "switch/create";
    return this.http
      .post<Switch>(this.baseUrl + url, switchSensor);
  }

  updateSwitch(switchSensor: Switch): Observable<Switch> {
    const url: string = "switch/update";
    return this.http
      .post<Switch>(this.baseUrl + url, switchSensor);
  }

  deleteSwitch(id: string): Observable<any> {
    const url: string = "switch/delete";
    return this.http.delete<any>(this.baseUrl + url + '?id=' + id);
  }

  switchOnOff(body: SwitchOnOffBody): Observable<any> {
    const url: string = "switch/switchOnOff";
    return this.http.post<any>(this.baseUrl + url, body);
  }

  changeSignalSwitch(body: ChangeSignalBody): Observable<any> {
    const url: string = "switch/changeSignal";
    return this.http.post<any>(this.baseUrl + url, body);
  }
}
