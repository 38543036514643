import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { ResponseRamp4 } from 'src/app/interfaces/ResponseRamp4';
import { ResponseRamp5 } from 'src/app/interfaces/ResponseRamp5';
import { RampService } from 'src/app/services/zamakona/ramp.service';

interface RampData {
  id: number
  name: string,
  data: Object,
  isActive: boolean
}


@Component({
  selector: 'app-zamakona-plc',
  templateUrl: './zamakona-plc.component.html',
  styleUrls: ['./zamakona-plc.component.css']
})
export class ZamakonaPlcComponent implements OnInit {


  constructor(private rampService: RampService,
    public router: Router,

  ) { }



  listRamp: RampData[] = [];


  // columnas: string[] = ['ID', 'Nombre', 'Estado', 'Opciones'];

  columnas: string[] = ['ID', 'Nombre', 'Opciones'];

  ngOnInit() {
    // this.getDataRamp5();
    // this.getDataRamp4()

    //Las rampas pueden estas desactivadas, no tiene sentido traerlas para mostrar cuales estan activas
    //las cargamos todas para que puedan acceder a ellas funcionen o no., ya que tienen valores cuando no.
    this.listRamp.push({
      data: {},
      id: 1,
      name: 'Rampa 4',
      isActive: true
    });
    this.listRamp.push({
      data: {},
      id: 2,
      name: 'Rampa 5',
      isActive: true
    });

  }

  getDataRamp5() {

    this.rampService.getRamp5("Ramp_2").subscribe((response: ResponseRamp5) => {
      if (this.listRamp.length <= 0) {
        this.listRamp = []
      }

      this.listRamp.push({
        data: response,
        id: response.deviceId,
        name: 'Rampa 5',
        isActive: true
      });



    }, (error) => {
      console.log(error)

    });
  }

  getDataRamp4() {
    this.rampService.getRamp4("Ramp_1").subscribe((response: ResponseRamp4) => {

      if (this.listRamp.length <= 0) {
        this.listRamp = []
      }
      this.listRamp.push({
        data: response,
        id: response.deviceId,
        name: 'Rampa 4',
        isActive: true
      });



    }, (error) => {
      console.log(error)

    });
  }


  seeDetails(ramp: RampData) {
    this.router.navigateByUrl('/', { skipLocationChange: false }).then((url) => {
      this.router.navigate(["/zamakona-plc/plc/" + ramp.id]);
    });
  }

}
