
import { IZamakonaCameraService } from './parent/zamakonaCameraService';
import { Observable } from 'rxjs';
import { CameraData } from '../../models/zamakona/zamakonaCamera';
import { map } from 'rxjs/operators';
import { Socket } from 'ngx-socket-io';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { ResponseCamera } from 'src/app/interfaces/ResponseCamera';


@Injectable({
  providedIn: 'root',
})
export class CameraService {

  private baseUrl: string = environment.backendUrl;
  private proxyImageUrl: string = environment.proxyimageURL;
  constructor(private http: HttpClient, private socket: Socket) { }


  getCamera(): Observable<ResponseCamera[]> {
    const url: string = 'camera/getAll';
    return this.http.get<ResponseCamera[]>(this.baseUrl + url)
      .pipe(map((result: ResponseCamera[]) => result))
  }
  getCameraByID(id: number): Observable<ResponseCamera> {
    const url: string = 'camera/id/' + id;
    return this.http.get<ResponseCamera>(this.baseUrl + url)
      .pipe(map((result: ResponseCamera) => result))
  }

  getImageFromProxy(url: string): Observable<Blob> {
   
    return this.http.get(this.proxyImageUrl + 'image/?url=' + encodeURIComponent(url), { responseType: 'blob' });
  }


}
