import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectForm } from 'src/app/interfaces/selectForm';
import { Building } from 'src/app/models/building';
import { Room } from 'src/app/models/room';
import { BuildingService } from 'src/app/services/building.service';
import { RoomService } from 'src/app/services/room.service';
import { Types } from 'src/app/shared/type-constants';

@Component({
  selector: 'app-room-dialog',
  templateUrl: './room-dialog.component.html',
  styleUrls: ['./room-dialog.component.scss'],
})
export class RoomDialogComponent implements OnInit {
  newRoom: boolean = true;
  room!: Room;
  buildingList: Building[] = [];
  roomTypes: SelectForm[] = Types.ROOM_TYPES;
  roomForm: FormGroup;
  idField: FormControl;
  buildingIdField: FormControl;
  nameField: FormControl;
  descriptionField: FormControl;
  roomTypeField: FormControl;
  isNew = false;

  constructor(
    private dialogRef: MatDialogRef<RoomDialogComponent>,
    private roomService: RoomService,
    private buildingService: BuildingService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any) {
    this.isNew = dataDialog.new;
    this.idField = new FormControl('newRoom', []);
    this.buildingIdField = new FormControl('', [Validators.required]);
    this.nameField = new FormControl('', [Validators.required]);
    this.descriptionField = new FormControl('', [Validators.required]);
    this.roomTypeField = new FormControl('', [Validators.required]);
    this.roomForm = new FormGroup({
      id: this.idField,
      buildingId: this.buildingIdField,
      name: this.nameField,
      description: this.descriptionField,
      roomType: this.roomTypeField,
    });
  }

  ngOnInit(): void {
    this.buildingService.getAllBuildings().subscribe((result) => {
      this.buildingList = result;

      if (!this.newRoom) {
        this.idField.setValue(this.room.id);
        this.buildingIdField.setValue(this.room.buildingId);
        this.nameField.setValue(this.room.name);
        this.descriptionField.setValue(this.room.description);
        this.roomTypeField.setValue(this.room.roomType);
      }
      this.idField.disable();
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });
  }

  onSubmit(): void {
    this.newRoom ? this.createRoom() : this.updateRoom();
  }

  createRoom(): void {
    const newRoom = new Room('newRoom',
        'room', this.buildingIdField.value, this.nameField.value, this.descriptionField.value,
        new Date(), new Date(), this.roomTypeField.value);
    this.roomService.createRoom(newRoom).subscribe(() => {
      this.closeDialog();
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
      this.closeDialog();
    });
  }

  updateRoom(): void {
    const modifiedRoom = new Room(this.room.id,
        'room', this.buildingIdField.value, this.nameField.value, this.descriptionField.value,
        this.room.dateCreated, new Date(), this.roomTypeField.value);
    this.roomService.updateRoom(modifiedRoom).subscribe(() => {
      this.closeDialog();
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
      this.closeDialog();
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
