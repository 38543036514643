/* eslint-disable max-len */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification } from '../models/notification';
import { Observable } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private baseUrl: string = environment.backendUrl;

  public notificationsFiltered: Notification[] = [];
  public notificationsClasified: Notification[] = [];

  public notificationsNotRead: number = 0;

  building: string = '';
  room: string = '';
  level: string = 'all';
  time: Date = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
  read: string = 'all';

  constructor(
    private http: HttpClient,
    private socket: Socket,
  ) { }

  newNotification(): Observable<Notification> {
    return new Observable((subscriber) => {
      this.socket.on('new-notification', (notification: Notification) => {
        subscriber.next(notification);
      });
    });
  }

  modifyNotification(modifiedNotification: Notification): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const index = this.notificationsFiltered.findIndex((notification) =>
        notification.id === modifiedNotification.id);

      if (index === -1) {
        resolve(false);
      } else {
        this.countNotificationsNoRead().subscribe((num) => {
          this.notificationsNotRead = num;
        });
        this.getNotificationsLevelReadBeforeDate().subscribe((notifications) => {
          this.notificationsFiltered = notifications;
          this.clasifyNotifications(modifiedNotification.buildingName,
              modifiedNotification.roomName);
          resolve(true);
        });
      }
    });
  }

  getAllNotifications(): Observable<Notification[]> {
    const url: string = "notification/getAll";
    return this.http.get<Notification[]>(this.baseUrl + url);
  }


  getNotificationsBeforeDate(): Observable<Notification[]> {
    const url: string = "notification/getBeforeDate";
    return this.http.get<Notification[]>(this.baseUrl + url + '?date=' + this.time);
  }

  getNotificationsLevelBeforeDate(): Observable<Notification[]> {
    const url: string = "notification/getLevelBeforeDate";
    return this.http.get<Notification[]>(this.baseUrl + url + '?level=' + this.level + '&date=' + this.time);
  }

  getNotificationsReadBeforeDate(): Observable<Notification[]> {
    const url: string = "notification/getReadBeforeDate";
    return this.http.get<Notification[]>(this.baseUrl + url + '?read=' + this.read + '&date=' + this.time);
  }

  getNotificationsLevelReadBeforeDate(): Observable<Notification[]> {
    const url: string = "notification/getLevelReadBeforeDate";
    return this.http.get<Notification[]>(this.baseUrl + url + '?level=' + this.level + '&read=' + this.read + '&date=' + this.time);
  }

  countNotificationsNoRead(): Observable<number> {
    const url: string = "notification/countNoRead";
    return this.http.get<number>(this.baseUrl + url);
  }

  readNotification(id: string, read: boolean): Observable<Notification> {
    const url: string = "notification/read";
    return this.http.get<Notification>(this.baseUrl + url + '?id=' + id + '&read=' + read);
  }

  readAllNotification(): Observable<any> {
    const url: string = "notification/readAll";
    return this.http.get<any>(this.baseUrl + url);
  }

  clasifyNotifications(building: string, room: string) {
    this.notificationsClasified = [];
    this.notificationsClasified = this.notificationsFiltered.filter((not) => {
      return (not.buildingName === building && not.roomName === room);
    });
  }
}
