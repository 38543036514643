/* eslint-disable max-len */
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const totalIntervalTimeNoActValidator: ValidatorFn = (control: AbstractControl):
    ValidationErrors | null => {
  const noActTotalTime = control.get('noActTotalTime');
  const noActInterval = control.get('noActInterval');

  return noActTotalTime && noActInterval && ((noActTotalTime.value > noActInterval.value) ||
    (noActTotalTime.value <= noActInterval.value && noActTotalTime.value <= 0) ||
    (noActTotalTime.value === 0 && noActInterval.value === 0)) ?
    null : { invalidNoAct: true };
};

export const noActDisabledValidator: ValidatorFn = (control: AbstractControl):
    ValidationErrors | null => {
  const noActTotalTime = control.get('noActTotalTime');
  const noActInterval = control.get('noActInterval');

  return noActTotalTime && noActInterval && ((noActTotalTime.value === 0 && noActInterval.value > 0) ||
      (noActTotalTime.value > 0 && noActInterval.value === 0)) ?
    { invalidDisabledNoAct: true } : null;
};

export const totalIntervalTimeDWOpenValidator: ValidatorFn = (control: AbstractControl):
    ValidationErrors | null => {
  const dwOpenTotalTime = control.get('dwOpenTotalTime');
  const dwOpenInterval = control.get('dwOpenInterval');

  return dwOpenTotalTime && dwOpenInterval && ((dwOpenTotalTime.value > dwOpenInterval.value) ||
    (dwOpenTotalTime.value <= dwOpenInterval.value && dwOpenTotalTime.value <= 0) ||
    (dwOpenTotalTime.value === 0 && dwOpenInterval.value === 0)) ?
    null : { invalidDWOpen: true };
};

export const dwOpenDisabledValidator: ValidatorFn = (control: AbstractControl):
    ValidationErrors | null => {
  const dwOpenTotalTime = control.get('dwOpenTotalTime');
  const dwOpenInterval = control.get('dwOpenInterval');

  return dwOpenTotalTime && dwOpenInterval && ((dwOpenTotalTime.value === 0 && dwOpenInterval.value > 0) ||
      (dwOpenTotalTime.value > 0 && dwOpenInterval.value === 0)) ?
    { invalidDisabledDWOpen: true } : null;
};
