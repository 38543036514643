/* eslint-disable camelcase */

import { WeatherStation } from "../weatherStation";

export const mockWeatherStation = WeatherStation.createByJson({
  "id": "00-04-a3-0b-01-04-3f-e6",
  "type": "weather_station",
  "absoluteHumidity": 16.420000076,
  "airDensity": 1.200000048,
  "avgWindDirection": 153,
  "avgWindGustDirection": 343,
  "avgWindGustSpeed": 2.349999905,
  "avgWindSpeed": 0.829999983,
  "battery": 100,
  "charging_state": 1,
  "compass": 313,
  "corectedWindDirection": 145,
  "current": 2,
  "dateCreated": "13/9/2023 12:19:53",
  "dateModified": "13/9/2023 13:05:13",
  "description": "",
  "dewPoint": 19.600000381,
  "heatIndex": 30,
  "max": 2,
  "name": "Estación Meteorológica ",
  "precipIntensity": "",
  "precipStatus": "",
  "precipTotal": "",
  "pressure": 1015.700012207,
  "pressureSeaLevel": 1015.700012207,
  "pressureStation": 1015.700012207,
  "relativeHumidity": 57,
  "secuence": 1860,
  "signal": "connected",
  "solarRadiation": 889,
  "status": "0000",
  "sunNoonTime": "11:59",
  "sunPosition": "172:+52",
  "sunRiseTime": "05:39",
  "sunsetTime": "18:19",
  "sunshineHours": 4.029999733,
  "supplyVoltage": 11.800000191,
  "temperature": 28.700000763,
  "timestamp": "2023-09-13T11:40:31.3",
  "twilightAstonom": "19:54",
  "twilightCivil": "18:47",
  "twilightNautica": "19:20",
  "wetBulbTemperature": 22.5,
  "windChill": "",
  "windDirection": 192,
  "windSensorStatus": "0000",
  "windSpeed": 1.189999938,
  "xTilt": 2,
  "yTilt": -2,
  "zOrient": 1,
  "lat": 28.15973186350595, 
  "lng": -15.407801734084606,
  "location": {
		"coordinates": [-15.40806667, 28.15975],
    "type": "Point"
	}
});
