/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
// import { MatSnackBar } from '@angular/material/snack-bar';
// import { Router } from '@angular/router';
// import { NotificationService } from './services/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ui-smartbuilding';

  constructor(
    // private notificationService: NotificationService,
    // private snackbar: MatSnackBar,
    // private router: Router,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    const user_data = this.authService.getCookie("user_data")
    const refresh_token = this.authService.getCookie("user_data")
    
    if (user_data && refresh_token) {
      let user = JSON.parse(user_data)

      if (user && user.id && refresh_token!="" && refresh_token.length>0) {
        this.authService.setUser(user)
      }
    }

    // if (this.authService.isAuthenticatedUser()
    // ) {
    //   if (user_data) {
    //     const user = JSON.parse(user_data)
    //     this.authService.setUser(user)
    //   }
    // }

    // this.notificationService.countNotificationsNoRead().subscribe((num) => {
    //   this.notificationService.notificationsNotRead = num;
    // });
    // this.notificationService.newNotification().subscribe((not) => {
    //   this.notificationService.notificationsNotRead++;
    //   this.snackbar.open('New Notification!', '', { duration: 5000 });
    //   if (this.router.url.includes('notifications') && not.buildingName === this.notificationService.building && not.roomName === this.notificationService.room &&
    //     (not.level === this.notificationService.level || this.notificationService.level === 'all') &&
    //     (this.notificationService.read === 'false' || this.notificationService.read === 'all')) {
    //     this.notificationService.getNotificationsLevelReadBeforeDate().subscribe((notifications) => {
    //       this.notificationService.notificationsFiltered = notifications;
    //       this.notificationService.clasifyNotifications(this.notificationService.building,
    //           this.notificationService.room);
    //     });
    //   }
    // });
  }
}
