import { totalIntervalTimeDWOpenValidator, dwOpenDisabledValidator }
  from './../../../shared/total-interval-time-validator';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Action } from 'src/app/models/action';
import { ActionService } from 'src/app/services/action.service';
import { minMaxTemperatureValidator, minMaxTemperatureDisabledValidator }
  from 'src/app/shared/min-max-temperature-validator';
import { noActDisabledValidator, totalIntervalTimeNoActValidator }
  from 'src/app/shared/total-interval-time-validator';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class ActionsComponent implements OnInit {
  LIGHT = 0;
  AIRCON = 1;
  HEATING = 2;
  CAMERA = 3;

  roomId: string = '';
  roomName: string = '';
  action!: Action;

  days = [{ value: 1, name: $localize`Monday`}, { value: 2, name: $localize`Tuesday`},
    { value: 3, name: $localize`Wednesday`}, { value: 4, name: $localize`Thursday`},
    { value: 5, name: $localize`Friday`}, { value: 6, name: $localize`Saturday`},
    { value: 0, name: $localize`Sunday`}]

  actionForm: FormGroup;
  tempMinControl: FormControl;
  tempMaxControl: FormControl;
  co2MaxWarning: FormControl;
  tvocMaxWarning: FormControl;
  batteryMinWarning: FormControl;
  activeDaysLight: FormControl;
  activeDaysAircon: FormControl;
  activeDaysHeating: FormControl;
  activeDaysCamera: FormControl;
  actScheduleLightStart: FormControl;
  actScheduleLightFinish: FormControl;
  allDayScheduleLight: FormControl;
  actScheduleAirconStart: FormControl;
  actScheduleAirconFinish: FormControl;
  allDayScheduleAircon: FormControl;
  actScheduleHeatingStart: FormControl;
  actScheduleHeatingFinish: FormControl;
  allDayScheduleHeating: FormControl;
  actScheduleCameraStart: FormControl;
  actScheduleCameraFinish: FormControl;
  allDayScheduleCamera: FormControl;
  noActTotalTimeCheck: FormControl;
  noActIntervalCheck: FormControl;
  dwOpenTotalTimeCheck: FormControl;
  dwOpenIntervalCheck: FormControl;
  batteryCooldown: FormControl;
  co2Cooldown: FormControl;
  tvocCooldown: FormControl;
  enableTemperatureLight: FormControl;
  enableActivityLight: FormControl;
  enableDoorWindowLight: FormControl;
  enableTemperatureAircon: FormControl;
  enableActivityAircon: FormControl;
  enableDoorWindowAircon: FormControl;
  enableTemperatureHeating: FormControl;
  enableActivityHeating: FormControl;
  enableDoorWindowHeating: FormControl;
  enableTemperatureCamera: FormControl;
  enableActivityCamera: FormControl;
  enableDoorWindowCamera: FormControl;
  constructor(
    private actionService: ActionService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {
    this.roomId = this.route.snapshot.params['roomId'];
    this.roomName = this.route.snapshot.params['roomName'];
    this.tempMinControl = new FormControl(0, [Validators.min(0), Validators.max(40)]);
    this.tempMaxControl = new FormControl(0, [Validators.min(0), Validators.max(40)]);
    this.co2MaxWarning = new FormControl(0, [Validators.min(0), Validators.max(3000)]);
    this.tvocMaxWarning = new FormControl(0, [Validators.min(0), Validators.max(1500)]);
    this.batteryMinWarning = new FormControl(0, [Validators.min(0), Validators.max(50)]);
    this.activeDaysLight = new FormControl();
    this.activeDaysAircon = new FormControl();
    this.activeDaysHeating = new FormControl();
    this.activeDaysCamera = new FormControl();
    this.actScheduleLightStart = new FormControl('06:00');
    this.actScheduleLightFinish = new FormControl('20:00');
    this.allDayScheduleLight = new FormControl(true);
    this.actScheduleAirconStart = new FormControl('06:00');
    this.actScheduleAirconFinish = new FormControl('20:00');
    this.allDayScheduleAircon = new FormControl(true);
    this.actScheduleHeatingStart = new FormControl('06:00');
    this.actScheduleHeatingFinish = new FormControl('20:00');
    this.allDayScheduleHeating = new FormControl(true);
    this.actScheduleCameraStart = new FormControl('06:00');
    this.actScheduleCameraFinish = new FormControl('20:00');
    this.allDayScheduleCamera = new FormControl(true);
    this.noActTotalTimeCheck = new FormControl(0, [Validators.min(0), Validators.max(1440)]);
    this.noActIntervalCheck = new FormControl(0, [Validators.min(0), Validators.max(360)]);
    this.dwOpenTotalTimeCheck = new FormControl(0, [Validators.min(0), Validators.max(1440)]);
    this.dwOpenIntervalCheck = new FormControl(0, [Validators.min(0), Validators.max(360)]);
    this.batteryCooldown = new FormControl(0, [Validators.min(0), Validators.max(168)]);
    this.co2Cooldown = new FormControl(0, [Validators.min(0), Validators.max(168)]);
    this.tvocCooldown = new FormControl(0, [Validators.min(0), Validators.max(168)]);
    this.enableTemperatureLight = new FormControl(false);
    this.enableActivityLight = new FormControl(false);
    this.enableDoorWindowLight = new FormControl(false);
    this.enableTemperatureAircon = new FormControl(false);
    this.enableActivityAircon = new FormControl(false);
    this.enableDoorWindowAircon = new FormControl(false);
    this.enableTemperatureHeating = new FormControl(false);
    this.enableActivityHeating = new FormControl(false);
    this.enableDoorWindowHeating = new FormControl(false);
    this.enableTemperatureCamera = new FormControl(false);
    this.enableActivityCamera = new FormControl(false);
    this.enableDoorWindowCamera = new FormControl(false);
    this.actionForm = new FormGroup({
      tempMin: this.tempMinControl,
      tempMax: this.tempMaxControl,
      co2Max: this.co2MaxWarning,
      tvocMax: this.tvocMaxWarning,
      batteryMin: this.batteryMinWarning,
      activeDaysLight: this.activeDaysLight,
      activeDaysAircon: this.activeDaysAircon,
      activeDaysHeating: this.activeDaysHeating,
      activeDaysCamera: this.activeDaysCamera,
      actSchLightStart: this.actScheduleLightStart,
      actSchLightFinish: this.actScheduleLightFinish,
      allDaySchLight: this.allDayScheduleLight,
      actSchAirconStart: this.actScheduleAirconStart,
      actSchAirconFinish: this.actScheduleAirconFinish,
      allDaySchAircon: this.allDayScheduleAircon,
      actSchHeatingStart: this.actScheduleHeatingStart,
      actSchHeatingFinish: this.actScheduleHeatingFinish,
      allDaySchHeating: this.allDayScheduleHeating,
      actSchCameraStart: this.actScheduleCameraStart,
      actSchCameraFinish: this.actScheduleCameraFinish,
      allDaySchCamera: this.allDayScheduleCamera,
      noActTotalTime: this.noActTotalTimeCheck,
      noActInterval: this.noActIntervalCheck,
      dwOpenTotalTime: this.dwOpenTotalTimeCheck,
      dwOpenInterval: this.dwOpenIntervalCheck,
      batteryCooldown: this.batteryCooldown,
      co2Cooldown: this.co2Cooldown,
      tvocCooldown: this.tvocCooldown,
      enableTemperatureLight: this.enableTemperatureLight,
      enableActivityLight: this.enableActivityLight,
      enableDoorWindowLight: this.enableDoorWindowLight,
      enableTemperatureAircon: this.enableTemperatureAircon,
      enableActivityAircon: this.enableActivityAircon,
      enableDoorWindowAircon: this.enableDoorWindowAircon,
      enableTemperatureHeating: this.enableTemperatureHeating,
      enableActivityHeating: this.enableActivityHeating,
      enableDoorWindowHeating: this.enableDoorWindowHeating,
      enableTemperatureCamera: this.enableTemperatureCamera,
      enableActivityCamera: this.enableActivityCamera,
      enableDoorWindowCamera: this.enableDoorWindowCamera,
    }, { validators: [minMaxTemperatureValidator, minMaxTemperatureDisabledValidator,
      totalIntervalTimeNoActValidator, noActDisabledValidator, totalIntervalTimeDWOpenValidator,
      dwOpenDisabledValidator] });
  }

  ngOnInit(): void {
    this.actionService.getActionByRoomId(this.roomId).subscribe((result) => {
      this.action = result;
      this.loadActionFormData();
      this.restrictedSchedulesDisabledChanges();
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });
  }

  private loadActionFormData() {
    this.tempMinControl.setValue(this.action.minValues.minTemp);
    this.tempMaxControl.setValue(this.action.maxValues.maxTemp);
    this.co2MaxWarning.setValue(this.action.maxValues.maxCo2);
    this.tvocMaxWarning.setValue(this.action.maxValues.maxTvoc);
    this.batteryMinWarning.setValue(this.action.minValues.minBattery);
    this.activeDaysLight.setValue(this.days.map((d) => d.value).filter((day) => {
      return !this.action.dayRestrictions.light.includes(day);
    }));
    this.activeDaysAircon.setValue(this.days.map((d) => d.value).filter((day) => {
      return !this.action.dayRestrictions.airConditioning.includes(day);
    }));
    this.activeDaysHeating.setValue(this.days.map((d) => d.value).filter((day) => {
      return !this.action.dayRestrictions.heating.includes(day);
    }));
    this.activeDaysCamera.setValue(this.days.map((d) => d.value).filter((day) => {
      return !this.action.dayRestrictions.camera.includes(day);
    }));
    if (this.action.scheduleRestrictions.light.length === 0) {
      this.allDayScheduleLight.setValue(true);
      this.actScheduleLightStart.disable();
      this.actScheduleLightFinish.disable();
    } else {
      this.allDayScheduleLight.setValue(false);
      this.actScheduleLightStart.enable();
      this.actScheduleLightFinish.enable();
      this.actScheduleLightStart.setValue(new Date(new Date().setHours(
          this.action.scheduleRestrictions.light[2], this.action.scheduleRestrictions.light[3]))
          .toTimeString().substring(0, 5));
      this.actScheduleLightFinish.setValue(new Date(new Date().setHours(
          this.action.scheduleRestrictions.light[0], this.action.scheduleRestrictions.light[1]))
          .toTimeString().substring(0, 5));
    }
    if (this.action.scheduleRestrictions.airConditioning.length === 0) {
      this.allDayScheduleAircon.setValue(true);
      this.actScheduleAirconStart.disable();
      this.actScheduleAirconFinish.disable();
    } else {
      this.allDayScheduleAircon.setValue(false);
      this.actScheduleAirconStart.enable();
      this.actScheduleAirconFinish.enable();
      this.actScheduleAirconStart.setValue(new Date(new Date().setHours(
          this.action.scheduleRestrictions.airConditioning[2],
          this.action.scheduleRestrictions.airConditioning[3]))
          .toTimeString().substring(0, 5));
      this.actScheduleAirconFinish.setValue(new Date(new Date().setHours(
          this.action.scheduleRestrictions.airConditioning[0],
          this.action.scheduleRestrictions.airConditioning[1]))
          .toTimeString().substring(0, 5));
    }
    if (this.action.scheduleRestrictions.heating.length === 0) {
      this.allDayScheduleHeating.setValue(true);
      this.actScheduleHeatingStart.disable();
      this.actScheduleHeatingFinish.disable();
    } else {
      this.allDayScheduleHeating.setValue(false);
      this.actScheduleHeatingStart.enable();
      this.actScheduleHeatingFinish.enable();
      this.actScheduleLightStart.setValue(new Date(new Date().setHours(
          this.action.scheduleRestrictions.heating[2], this.action.scheduleRestrictions.heating[3]))
          .toTimeString().substring(0, 5));
      this.actScheduleLightFinish.setValue(new Date(new Date().setHours(
          this.action.scheduleRestrictions.heating[0], this.action.scheduleRestrictions.heating[1]))
          .toTimeString().substring(0, 5));
    }
    if (this.action.scheduleRestrictions.camera.length === 0) {
      this.allDayScheduleCamera.setValue(true);
      this.actScheduleCameraStart.disable();
      this.actScheduleCameraFinish.disable();
    } else {
      this.allDayScheduleCamera.setValue(false);
      this.actScheduleCameraStart.enable();
      this.actScheduleCameraFinish.enable();
      this.actScheduleLightStart.setValue(new Date(new Date().setHours(
          this.action.scheduleRestrictions.camera[2], this.action.scheduleRestrictions.camera[3]))
          .toTimeString().substring(0, 5));
      this.actScheduleLightFinish.setValue(new Date(new Date().setHours(
          this.action.scheduleRestrictions.camera[0], this.action.scheduleRestrictions.camera[1]))
          .toTimeString().substring(0, 5));
    }
    this.noActTotalTimeCheck.setValue(this.action.checkIntervals.noActivity.totalTime / 60 / 1000);
    this.noActIntervalCheck.setValue(this.action.checkIntervals.noActivity.interval / 60 / 1000);
    this.dwOpenTotalTimeCheck.setValue(
        this.action.checkIntervals.doorWindowOpen.totalTime / 60 / 1000);
    this.dwOpenIntervalCheck.setValue(
        this.action.checkIntervals.doorWindowOpen.interval / 60 / 1000);
    this.batteryCooldown.setValue(this.action.cooldowns.battery / 60 / 60 / 1000);
    this.co2Cooldown.setValue(this.action.cooldowns.co2 / 60 / 60 / 1000);
    this.tvocCooldown.setValue(this.action.cooldowns.tvoc / 60 / 60 / 1000);
    this.enableTemperatureLight.setValue(this.action.eventsActivated.temperature.light);
    this.enableActivityLight.setValue(this.action.eventsActivated.activity.light);
    this.enableDoorWindowLight.setValue(this.action.eventsActivated.doorWindow.light);
    this.enableTemperatureAircon.setValue(this.action.eventsActivated.temperature.airConditioning);
    this.enableActivityAircon.setValue(this.action.eventsActivated.activity.airConditioning);
    this.enableDoorWindowAircon.setValue(this.action.eventsActivated.doorWindow.airConditioning);
    this.enableTemperatureHeating.setValue(this.action.eventsActivated.temperature.heating);
    this.enableActivityHeating.setValue(this.action.eventsActivated.activity.heating);
    this.enableDoorWindowHeating.setValue(this.action.eventsActivated.doorWindow.heating);
    this.enableTemperatureCamera.setValue(this.action.eventsActivated.temperature.camera);
    this.enableActivityCamera.setValue(this.action.eventsActivated.activity.camera);
    this.enableDoorWindowCamera.setValue(this.action.eventsActivated.doorWindow.camera);
  }

  private restrictedSchedulesDisabledChanges() {
    this.allDayScheduleLight.valueChanges.subscribe((value) => {
      if (value) {
        this.actScheduleLightStart.disable();
        this.actScheduleLightFinish.disable();
      } else {
        this.actScheduleLightStart.enable();
        this.actScheduleLightFinish.enable();
      }
    });
    this.allDayScheduleAircon.valueChanges.subscribe((value) => {
      if (value) {
        this.actScheduleAirconStart.disable();
        this.actScheduleAirconFinish.disable();
      } else {
        this.actScheduleAirconStart.enable();
        this.actScheduleAirconFinish.enable();
      }
    });
    this.allDayScheduleHeating.valueChanges.subscribe((value) => {
      if (value) {
        this.actScheduleHeatingStart.disable();
        this.actScheduleHeatingFinish.disable();
      } else {
        this.actScheduleHeatingStart.enable();
        this.actScheduleHeatingFinish.enable();
      }
    });
    this.allDayScheduleCamera.valueChanges.subscribe((value) => {
      if (value) {
        this.actScheduleCameraStart.disable();
        this.actScheduleCameraFinish.disable();
      } else {
        this.actScheduleCameraStart.enable();
        this.actScheduleCameraFinish.enable();
      }
    });
  }

  displayDayInSelect(formControl: FormControl): string {
    const daysToDisplay = formControl.value;
    let stringToDisplay = '';

    for (let i = 1; i <= 6; i++) {
      if (daysToDisplay.includes(i)) {
        stringToDisplay += this.days.find((d) => d.value === i)?.name.substring(0, 2) + ', ';
      }
    }
    if (daysToDisplay.includes(0)) {
      stringToDisplay += this.days.find((d) => d.value === 0)?.name.substring(0, 2) + ', ';
    }

    return stringToDisplay.substring(0, stringToDisplay.length - 2);
  }

  onSubmit(): void {
    if (this.actionForm.valid) {
      const actionUpdated = this.buildAction();
      console.log(actionUpdated);
      this.actionService.updateAction(actionUpdated).subscribe((result) => {
        this.action = result;
        this.snackBar.open($localize`Changes saved correctly`, '', { duration: 5000 });
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
      });
    } else {
      this.snackBar.open($localize`There are incorrect values`, '', { duration: 5000 });
    }
  }

  private buildAction(): Action {
    const maxValues = {
      maxTemp: this.tempMaxControl.value,
      maxCo2: this.co2MaxWarning.value,
      maxTvoc: this.tvocMaxWarning.value,
    };
    const minValues = {
      minBattery: this.batteryMinWarning.value,
      minTemp: this.tempMinControl.value,
    };
    const scheduleRestrictions = {
      light: this.allDayScheduleLight.value ? [] :
        [parseInt(this.actScheduleLightFinish.value.substring(0, 2)),
          parseInt(this.actScheduleLightFinish.value.substring(3, 5)),
          parseInt(this.actScheduleLightStart.value.substring(0, 2)),
          parseInt(this.actScheduleLightStart.value.substring(3, 5))],
      airConditioning: this.allDayScheduleAircon.value ? [] :
        [parseInt(this.actScheduleAirconFinish.value.substring(0, 2)),
          parseInt(this.actScheduleAirconFinish.value.substring(3, 5)),
          parseInt(this.actScheduleAirconStart.value.substring(0, 2)),
          parseInt(this.actScheduleAirconStart.value.substring(3, 5))],
      heating: this.allDayScheduleHeating.value ? [] :
        [parseInt(this.actScheduleHeatingFinish.value.substring(0, 2)),
          parseInt(this.actScheduleHeatingFinish.value.substring(3, 5)),
          parseInt(this.actScheduleHeatingStart.value.substring(0, 2)),
          parseInt(this.actScheduleHeatingStart.value.substring(3, 5))],
      camera: this.allDayScheduleCamera.value ? [] :
        [parseInt(this.actScheduleCameraFinish.value.substring(0, 2)),
          parseInt(this.actScheduleCameraFinish.value.substring(3, 5)),
          parseInt(this.actScheduleCameraStart.value.substring(0, 2)),
          parseInt(this.actScheduleCameraStart.value.substring(3, 5))],
    };
    const dayRestrictions = {
      light: this.days.map((d) => d.value).filter((day) => {
        return !this.activeDaysLight.value.includes(day);
      }),
      airConditioning: this.days.map((d) => d.value).filter((day) => {
        return !this.activeDaysAircon.value.includes(day);
      }),
      heating: this.days.map((d) => d.value).filter((day) => {
        return !this.activeDaysHeating.value.includes(day);
      }),
      camera: this.days.map((d) => d.value).filter((day) => {
        return !this.activeDaysCamera.value.includes(day);
      }),
    };
    console.log(scheduleRestrictions);
    const checkIntervals = {
      noActivity: {
        totalTime: this.noActTotalTimeCheck.value * 60 * 1000,
        interval: this.noActIntervalCheck.value * 60 * 1000,
      },
      doorWindowOpen: {
        totalTime: this.dwOpenTotalTimeCheck.value * 60 * 1000,
        interval: this.dwOpenIntervalCheck.value * 60 * 1000,
      },
    };
    const cooldowns = {
      battery: this.batteryCooldown.value * 60 * 60 * 1000,
      co2: this.co2Cooldown.value * 60 * 60 * 1000,
      tvoc: this.tvocCooldown.value * 60 * 60 * 1000,
    };
    const eventsActivated = {
      activity: {
        light: this.enableActivityLight.value,
        airConditioning: this.enableActivityAircon.value,
        heating: this.enableActivityHeating.value,
        camera: this.enableActivityCamera.value,
      },
      temperature: {
        light: this.enableTemperatureLight.value,
        airConditioning: this.enableTemperatureAircon.value,
        heating: this.enableTemperatureHeating.value,
        camera: this.enableTemperatureCamera.value,
      },
      doorWindow: {
        light: this.enableDoorWindowLight.value,
        airConditioning: this.enableDoorWindowAircon.value,
        heating: this.enableDoorWindowHeating.value,
        camera: this.enableDoorWindowCamera.value,
      },
    };
    return new Action(this.action.id, this.action.type, this.action.roomId, maxValues, minValues,
        scheduleRestrictions, dayRestrictions, checkIntervals, cooldowns, eventsActivated);
  }

  cancelChanges(): void {
    this.router.navigate(['/rooms/no-building']);
  }
}
