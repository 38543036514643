import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-zamakona-sensor-display',
  templateUrl: './zamakona-sensor-display.component.html',
  styleUrls: ['./zamakona-sensor-display.component.css']
})
export class ZamakonaSensorDisplayComponent implements OnInit {

  @Input() minValue: string = "";
  @Input() maxValue: string = "";
  @Input() displayValue: string = "";
  @Input() description: string = "description";
  @Input() unidad:String="";
  @ViewChild('canvas', {static: true}) canvasRef!: ElementRef<HTMLCanvasElement>;

  constructor( private cdr: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.drawdisplayValue();
    this.cdr.detectChanges();
  }

  ngOnInit() { 
    
  }

  ngOnChanges(changes: SimpleChanges) {
  
    if (changes.displayValue && !changes.displayValue.firstChange) {
      this.drawdisplayValue();
    }
  }

  
  drawdisplayValue() {
   
    const canvas = this.canvasRef.nativeElement;
    const ctx = canvas.getContext('2d');

    if (ctx) {
        const radius = ctx.canvas.width / 2 - 40;
        const centerX = ctx.canvas.width / 2;
        const centerY = ctx.canvas.height - 10;
        let min = parseFloat(this.minValue);
        let max = parseFloat(this.maxValue);
        let currentV = parseFloat(this.displayValue);
        if(currentV>=max){
          max=currentV
        }

        // Limpiar el canvas
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        // Dibujar el arco del medidor
        ctx.beginPath();
        ctx.strokeStyle = 'rgb(170, 200, 240)';
        ctx.lineWidth = 30;
        ctx.arc(centerX, centerY, radius, Math.PI, 2 * Math.PI);
        ctx.stroke();

        // Dibujar el marcador 
        ctx.beginPath();
        ctx.strokeStyle = 'rgb(35, 102, 190)';
        ctx.lineWidth = 29;
        const voltageAngle = Math.PI + (currentV / max) * Math.PI;
        ctx.arc(centerX, centerY, radius, Math.PI, voltageAngle);
        ctx.stroke();

        // Dibujar el indicador 
        ctx.beginPath();
        ctx.fillStyle = 'black';
        ctx.font = '28px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        const textY = centerY - radius * 0.3;
        ctx.fillText(`${currentV} `+this.unidad, centerX, textY);

        // Dibujar líneas y valores alrededor del arco
        const lineLength = 15;
        const valueRadius = radius + 25;
        const valueIncrement = (max - min) / 10;
        for (let i = 0; i <= 10; i++) {
            const angle = Math.PI + (i / 10) * Math.PI;
            const startX = centerX + Math.cos(angle) * radius;
            const startY = centerY + Math.sin(angle) * radius;
            const endX = centerX + Math.cos(angle) * (radius - lineLength);
            const endY = centerY + Math.sin(angle) * (radius - lineLength);
            ctx.beginPath();
            ctx.moveTo(startX, startY);
            ctx.lineTo(endX, endY);
            ctx.strokeStyle = 'black';
            ctx.lineWidth = 1;
            ctx.stroke();

            // Dibujar valores
            const value = min + i * valueIncrement;
            const valueX = centerX + Math.cos(angle) * valueRadius;
            const valueY = centerY + Math.sin(angle) * valueRadius;
            ctx.beginPath();
            ctx.fillStyle = 'black';
            ctx.font = '9px Arial';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(`${value.toFixed(0)}`, valueX, valueY);
        }

        // Dibujar la aguja
        ctx.beginPath();
        ctx.strokeStyle = 'red';
        ctx.lineWidth = 4;
        const needleAngle = Math.PI + (currentV / max) * Math.PI;
        const needleX = centerX + Math.cos(needleAngle) * (radius - 10);
        const needleY = centerY + Math.sin(needleAngle) * (radius - 10);
        ctx.moveTo(centerX, centerY);
        ctx.lineTo(needleX, needleY);
        ctx.stroke();

        // Dibujar el centro de la aguja
        ctx.beginPath();
        ctx.fillStyle = 'red';
        ctx.arc(centerX, centerY, 5, 0, 2 * Math.PI);
        ctx.fill();

        const description = this.description.split("_")[0];
        ctx.beginPath();
        ctx.fillStyle = 'black';
        ctx.font = '14px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'top';
        ctx.fillText(description, centerX, centerY-20);

        ctx?.closePath()
    }
  
  }
  


}
