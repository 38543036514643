import { CriteriaNotificationsFilter } from 'src/app/interfaces/criteria-notifications-filter';
import { CriteriaLevelRead } from './../models/criteria-level-read';
import { CriteriaNoLevelRead } from './../models/criteria-no-level-read';
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { CriteriaNoLevelNoRead } from '../models/criteria-no-level-no-read';
import { CriteriaLevelNoRead } from '../models/criteria-level-no-read';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class CriteriaService {
  criteriaList: { readCondition: boolean; levelCondition: boolean; value: CriteriaNoLevelNoRead | CriteriaLevelNoRead | CriteriaNoLevelRead | CriteriaLevelRead; }[] = [];
  constructor(
    private notificationService: NotificationService,
  ) {
    this.criteriaList.push({readCondition: true, levelCondition: true, value: new CriteriaNoLevelNoRead(this.notificationService)});
    this.criteriaList.push({readCondition: true, levelCondition: false, value: new CriteriaLevelNoRead(this.notificationService)});
    this.criteriaList.push({readCondition: false, levelCondition: true, value: new CriteriaNoLevelRead(this.notificationService)});
    this.criteriaList.push({readCondition: false, levelCondition: false, value: new CriteriaLevelRead(this.notificationService)});
  }
  assignCriteria(readCondition: boolean, levelCondition: boolean): CriteriaNotificationsFilter {
    const criteria = this.criteriaList.find((x) => x.readCondition === readCondition && x.levelCondition === levelCondition);
    return criteria ? criteria.value : new CriteriaNoLevelNoRead(this.notificationService);
  }
}
