import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Room } from '../models/room';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RoomService {
  private baseUrl: string = environment.backendUrl;

  constructor(
    private http: HttpClient,
  ) { }

  getAllRooms(): Observable<Room[]> {
    const url: string = "room/getAll";
    return this.http.get<Room[]>(this.baseUrl + url);
  }

  getRoomById(id: string): Observable<Room> {
    const url: string = "room/getById";
    return this.http.get<Room>(this.baseUrl + url + "?id=" + id);
  }

  getRoomsByBuilding(buildingId: string): Observable<Room[]> {
    const url: string = "room/getByBuildingId";
    return this.http.get<Room[]>(this.baseUrl + url + '?buildingId=' + buildingId);
  }

  createRoom(room: Room): Observable<Room> {
    const url: string = "room/create";
    return this.http
      .post<Room>(this.baseUrl + url, room);
  }

  updateRoom(room: Room): Observable<Room> {
    const url: string = "room/update";
    return this.http
      .post<Room>(this.baseUrl + url, room);
  }

  deleteRoom(id: string): Observable<any> {
    const url: string = "room/delete";
    return this.http.delete<any>(this.baseUrl + url + '?id=' + id);
  }
}
