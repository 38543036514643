export class Basic {
  constructor(
    public id: string,
    public type: string,
    public roomId: string,
    public name: string,
    public description: string,
    public dateCreated: Date,
    public dateModified: Date,
    public battery: number,
    public signal: string,
  ) {}
}
