/* eslint-disable max-len */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification } from '../models/notification';
import { Observable } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { environment } from './../../environments/environment';
import { DatosSensores } from '../models/zamakona/paneles/datosSensores';
import { Subsensor } from '../models/zamakona/paneles/subSensor';

@Injectable({
  providedIn: 'root',
})
export class PanelsService {
  private baseUrl: string = environment.backendUrl;


  constructor(
    private http: HttpClient,
    private socket: Socket,
  ) { }

  getAll(sensorType: string): Observable<Subsensor[]> {
    const url: string = "paneles/getAll?sensorType=" + sensorType;
    return this.http.get<Subsensor[]>(this.baseUrl + url);
  }

}
