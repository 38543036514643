import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Building } from 'src/app/models/building';
import { BuildingService } from 'src/app/services/building.service';
import { BuildingDialogComponent } from
  './building-dialog/building-dialog.component';

@Component({
  selector: 'app-buildings',
  templateUrl: './buildings.component.html',
  styleUrls: ['./buildings.component.scss'],
})
export class BuildingsComponent implements OnInit {
  displayedColumns: string[] = ['name', 'buildingType', 'options'];
  buildingList: Building[] = [];

  constructor(
    private buildingService: BuildingService,
    private buildingDialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.buildingService.getAllBuildings().subscribe((result) => {
      this.buildingList = result;
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });
  }

  createBuilding(): void {
    const dialogRef = this.buildingDialog.open(BuildingDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      minWidth: '500px',
      data: { new: true },
    });

    dialogRef.componentInstance.newBuilding = true;

    dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }

  seeRooms(buildingId: string): void {
    this.router.navigate(['/rooms/' + buildingId]);
  }

  updateBuilding(building: Building): void {
    const dialogRef = this.buildingDialog.open(BuildingDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      minWidth: '500px',
      data: { new: false },
    });

    dialogRef.componentInstance.building = building;
    dialogRef.componentInstance.newBuilding = false;

    dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }

  deleteBuilding(id: string): void {
    this.buildingService.deleteBuilding(id).subscribe(() => {
      this.ngOnInit();
    }, (error) => {
      if (error.status == 409) {
        this.snackBar.open('ERROR: ' + error.error.description, '', { duration: 10000 });
      } else {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
      }
    });
  }
}
