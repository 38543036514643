import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ChangeSignalBody } from 'src/app/interfaces/changeSignalBody';
import { HistoricData } from 'src/app/models/zamakona/historicData';
import { IZamakonaSensorService } from 'src/app/services/zamakona/parent/zamakonaSensorService';

@Component({
  selector: 'app-zamakona-sensor-detail-dialog',
  templateUrl: './zamakona-sensor-detail-dialog.component.html',
  styleUrls: ['./zamakona-sensor-detail-dialog.component.scss'],
})

export class ZamakonaSensorDetailDialogComponent<ZamakonaSensorService extends IZamakonaSensorService> {
  sensorType: string = 'zamakona';
  sensor!: {id: string, signal: string};
  dataView: {label: string, value: unknown}[] = [];
  private sensorService!: ZamakonaSensorService;
  grafanaUrlSafe: SafeResourceUrl;
  constructor(
    protected snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) protected matDialogData: HistoricData<{id: string, signal: string}>
  ) {
    this.sensor = this.matDialogData.sensor;
    this.grafanaUrlSafe = this.matDialogData.grafanaUrlSafe;
  }

  changeSignal(): void {
    const body: ChangeSignalBody = {
      id: this.sensor.id,
      signal: (this.sensor.signal === 'connected') ? 'disconnected' : 'connected',
    };
    this.sensorService.changeSignalSwitch(body).subscribe(() => {
    }, (error: unknown) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });
  }
}
