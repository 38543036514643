<div class="sensor-title">
  <h1>Gráficas</h1>
</div>

<div style="
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  gap: 40px;
  background-color: #2366be;
  padding: 15px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
">
  <!-- Texto de observación -->
  <div style="color: white; font-weight: 500;">
    <p>Últimas {{selectedTime}}h desde la observación.</p>
  </div>

  <div style="display: flex; flex-direction: row; gap: 50px;">
    <!-- Selección de sensor -->
    <div style="
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    ">
      <p style="color: white; font-size: 16px;">Sensor</p>
      <mat-button-toggle-group name="Sensor" aria-label="Sensor" value="1" #group="matButtonToggleGroup">
        <mat-button-toggle value="3" (change)="onSensorChange($event.value)">
          A. Ambiental
        </mat-button-toggle>
        <mat-button-toggle value="2" (change)="onSensorChange($event.value)">
          A. Operacional
        </mat-button-toggle>
        <mat-button-toggle value="1" (change)="onSensorChange($event.value)">
          Acuático
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <!-- Selección de rango de tiempo -->
    <div style="
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    ">
      <p style="color: white; font-size: 16px;">Rango de tiempo</p>
      <mat-button-toggle-group name="Tiempo" aria-label="Tiempo" value="12" #group="matButtonToggleGroup">
        <mat-button-toggle value="6" (change)="onTimeChange($event.value)">
          6h
        </mat-button-toggle>
        <mat-button-toggle value="12" (change)="onTimeChange($event.value)">
          12h
        </mat-button-toggle>
        <mat-button-toggle value="24" (change)="onTimeChange($event.value)">
          24h
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <!-- Selector de rango de fechas -->
    <mat-form-field style="margin-left: 10px; background-color: white; padding-left: 5px; padding-right: 5px; border-radius: 7px; padding-top: 7px; border: 1px solid black;">
      <mat-label>Selecciona un rango de fechas</mat-label>
      <mat-date-range-input [rangePicker]="dateRangePicker">
        <input matStartDate
          placeholder="Comienza"
          #dateRangeStart>
        <input matEndDate
          placeholder="Acaba"
          #dateRangeEnd
          (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
      </mat-date-range-input>
      <mat-datepicker-toggle matPrefix
        [for]="dateRangePicker">
      </mat-datepicker-toggle>
      <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
    </mat-form-field>
  </div>
</div>

<!-- Contenedores de las gráficas -->
<div class="dashboard-container" *ngIf="selectedSensor == 3" style="margin-top: 20px;">
  <iframe [src]="WeatherGrafanaURL" width="100%" height="620px" frameborder="0"></iframe>
</div>

<div class="dashboard-container" *ngIf="selectedSensor == 2" style="margin-top: 20px;">
  <iframe [src]="AirGrafanaURL" width="100%" height="620px" frameborder="0"></iframe>
</div>

<div class="dashboard-container" *ngIf="selectedSensor == 1" style="margin-top: 20px;">
  <iframe [src]="WaterGrafanaURL" width="100%" height="620px" frameborder="0"></iframe>
</div>
