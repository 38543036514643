import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Room } from 'src/app/models/room';
import { BuildingService } from 'src/app/services/building.service';
import { RoomService } from 'src/app/services/room.service';
import { RoomDialogComponent } from './room-dialog/room-dialog.component';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss'],
})
export class RoomsComponent implements OnInit {
  buildingId: string = "no-building";
  buildingName: string = "";

  displayedColumns: string[] = ['buildingId', 'name', 'roomType', 'options'];
  roomList: Room[] = [];

  constructor(
    private roomService: RoomService,
    private buildingService: BuildingService,
    private roomDialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
  ) {
    this.buildingId = this.route.snapshot.params.buildingId;
  }

  ngOnInit(): void {
    this.buildingId = this.route.snapshot.params.buildingId;

    if (this.buildingId !== "no-building") {
      this.buildingService.getBuildingById(this.buildingId).subscribe((result) => {
        this.buildingName = result.name;
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
      });
    }

    this.roomList = [];
    (this.buildingId === 'no-building') ? this.loadAllRooms() : this.loadRoomsByBuilding();
  }

  private loadAllRooms(): void {
    this.roomService.getAllRooms().subscribe((result) => {
      this.roomList = result;
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });
  }

  private loadRoomsByBuilding(): void {
    this.roomService.getRoomsByBuilding(this.buildingId).subscribe((result) => {
      this.roomList = result;
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });
  }

  createRoom(): void {
    const dialogRef = this.roomDialog.open(RoomDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      minWidth: '500px',
      data: {new: true},
    });

    dialogRef.componentInstance.newRoom = true;

    dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }

  seeAction(roomId: string, roomName: string): void {
    this.router.navigate(['/action/' + roomId + '/' + roomName]);
  }

  seeSensors(roomId: string): void {
    this.router.navigate(['/sensors/' + roomId]);
  }

  updateRoom(room: Room): void {
    const dialogRef = this.roomDialog.open(RoomDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      minWidth: '500px',
      data: {new: false},
    });

    dialogRef.componentInstance.room = room;
    dialogRef.componentInstance.newRoom = false;

    dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }

  deleteRoom(id: string): void {
    this.roomService.deleteRoom(id).subscribe(() => {
      this.ngOnInit();
    }, (error) => {
      if (error.status == 409) {
        this.snackBar.open('ERROR: ' + error.error.description, '', { duration: 10000 });
      } else {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
      }
    });
  }
}
