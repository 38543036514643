import { Basic } from "./basic";

export class Switch extends Basic {
  constructor(
      id: string,
      type: string,
      roomId: string,
      name: string,
      description: string,
      dateCreated: Date,
      dateModified: Date,
      battery: number,
      signal: string,
    public device1: string,
    public status1: string,
    public device2: string,
    public status2: string,
  ) {
    super(id, type, roomId, name, description, dateCreated, dateModified, battery, signal);
  }
}
