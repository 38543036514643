import { SelectForm } from "../interfaces/selectForm";

export class Types {
  public static readonly ROOM_TYPES: SelectForm[] = [
    { value: 'office', name: $localize`Office` },
    { value: 'bedroom', name: $localize`Bedroom` },
    { value: 'kitchen', name: $localize`Kitchen` },
    { value: 'living_room', name: $localize`Living room` },
    { value: 'bathroom', name: $localize`Bathroom` },
    { value: 'study_room', name: $localize`Study room` },
    { value: 'meeting_room', name: $localize`Meeting room` },
    { value: 'hospital_room', name: $localize`Hospital room` },
    { value: 'lobby', name: $localize`Lobby` },
    { value: 'other', name: $localize`Other` },
  ];

  public static readonly BUILDING_TYPES: SelectForm[] = [
    { value: 'hospital', name: $localize`Hospital` },
    { value: 'education', name: $localize`Education` },
    { value: 'government', name: $localize`Gobernment` },
    { value: 'store', name: $localize`Store` },
    { value: 'other', name: $localize`Other` },
  ];
};
