import { Types } from './../shared/type-constants';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roomType',
})
export class RoomTypePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    const roomType = Types.ROOM_TYPES.find((x) => x.value === value);
    return roomType ? roomType.name : value.charAt(0).toUpperCase() + value.substring(1);
  }
}
