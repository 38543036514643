import { SensorService } from './../../services/sensor.service';
import { AfterViewInit, Component, ViewChild, ElementRef } from '@angular/core';
import { AirQuality } from 'src/app/models/zamakona/airQuality';
import { AirQualityService } from 'src/app/services/zamakona/airQuality.service';
import { Building } from 'src/app/models/building';
import { BuildingService } from 'src/app/services/building.service';
import { RoomService } from 'src/app/services/room.service';
import { Room } from 'src/app/models/room';
import { Basic } from 'src/app/models/basic';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WaterQuality } from 'src/app/models/zamakona/waterQuality';
import { WaterQualityService } from 'src/app/services/zamakona/waterQuality.service';
import { WeatherStation } from 'src/app/models/zamakona/weatherStation';
import { WeatherStationService } from 'src/app/services/zamakona/weatherStation.service';
import { CameraService } from 'src/app/services/zamakona/camera.service';
import { ResponseCamera } from 'src/app/interfaces/ResponseCamera';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})

export class MapComponent implements AfterViewInit {
  map!: google.maps.Map;
  center: google.maps.LatLngLiteral = { lat: 28.15973186350595, lng: -15.407801734084606 };
  zoom: number = 16;
  @ViewChild('map')
  mapElement!: ElementRef;
  markerBounds = new google.maps.LatLngBounds();

  myStyles: google.maps.MapTypeStyle[] = [{
    featureType: "poi",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  }];

  buildingList: Building[] = [];
  roomList: Room[] = [];
  //sensorList: Basic[] = [];
  sensorList: any[] = [];
  content: string[] = [];
  cameraList: any[] = [];

  constructor(
    private buildingService: BuildingService,
    private roomService: RoomService,
    private sensorService: SensorService,
    private snackBar: MatSnackBar,
    private airQuality: AirQualityService,
    private waterQuality: WaterQualityService,
    private weatherStationService: WeatherStationService,
    private cameraService: CameraService,
    private authService:AuthService
  ) {
  }

  ngAfterViewInit() {
    if(this.authService.isAuthenticatedUser()){
      this.map = new google.maps.Map(this.mapElement.nativeElement, {
        center: this.center,
        zoom: this.zoom,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: this.myStyles,
      });
  
      this.loadData();
    }
  }

  private async loadData(): Promise<void> {
    await this.loadRooms();
    this.sensorList = [];
    await this.loadSensors();



    this.sensorList.forEach((zamakonaSensor) => {
      const markPos = new google.maps.LatLng(zamakonaSensor.lat, zamakonaSensor.lng);
      const markLabel = this.selectZamakonaMarker(zamakonaSensor);
      const marker = new google.maps.Marker({
        position: markPos,
        map: this.map,
        label: {
          text: markLabel,
          fontFamily: 'Material Icons',
          color: '#ffffff',
          fontSize: '16px',
        },
        title: zamakonaSensor.name,
      });
      const contentString = this.createZamakonaContent(zamakonaSensor);
      const infoWindow = new google.maps.InfoWindow({
        content: contentString,
      });
      this.markerBounds.extend(markPos);
      marker.addListener('click', () => {
        infoWindow.open(this.map, marker);
      });
    });



    this.buildingService.getAllBuildings().subscribe((result) => {
      this.buildingList = result;
      this.buildingList = this.buildingList.filter((building) => {
        return building.lat && building.lng;
      });
      this.buildingList.forEach((building) => {
        const markPos = new google.maps.LatLng(building.lat, building.lng);
        const markLabel = this.selectMarker(building);
        const marker = new google.maps.Marker({
          position: markPos,
          map: this.map,
          label: {
            text: markLabel,
            fontFamily: 'Material Icons',
            color: '#ffffff',
            fontSize: '16px',
          },
          title: building.name,
        });
        const contentString = this.createContent(building);
        const infoWindow = new google.maps.InfoWindow({
          content: contentString,
        });
        this.markerBounds.extend(markPos);
        marker.addListener('click', () => {
          infoWindow.open(this.map, marker);
        });
      });
      this.map.setCenter(this.markerBounds.getCenter());
      //this.map.fitBounds(this.markerBounds);
    });
  }

  private loadRooms(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.roomService.getAllRooms().subscribe((result) => {
        this.roomList = result;
        resolve();
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`);
        reject(error);
      });
    });
    return promise;
  }

  private loadSensors(): Promise<void> {
    const promise = new Promise<void>(async (resolve, reject) => {
      //await this.loadMagnetics();
      //await this.loadAdvancedAmbiences();
      //await this.loadSwitches();
      await this.loadAllAirQuialitySensors();
      await this.loadAllWaterQuialitySensors();
      await this.loadAllWeatherQuialitySensors();
      this.getCamera()
      resolve();
    });
    return promise;
  }



  private getCamera() {
    this.cameraService.getCamera().subscribe((response: ResponseCamera[]) => {
      if (response && response.length > 0) {
    
        this.cameraList = response
        this.cameraList.forEach((camera:ResponseCamera) => {
          const markPos = new google.maps.LatLng(camera.latitud, camera.longitud);
          const markLabel = "\ue3af";
          const marker = new google.maps.Marker({
            position: markPos,
            map: this.map,
            label: {
              text: markLabel,
              fontFamily: 'Material Icons',
              color: '#ffffff',
              fontSize: '16px',
            },
            title: "Camara "+camera.id.split("_")[1],
          });
          const contentString = '<h2>' +  "Camara "+camera.id.split("_")[1]+ '</h2>';
          const infoWindow = new google.maps.InfoWindow({
            content: contentString,
          });
          this.markerBounds.extend(markPos);
          marker.addListener('click', () => {
            infoWindow.open(this.map, marker);
          });
        })
      }

     
    }, (error) => {
      console.log(error)
    })
  }

  private loadAllAirQuialitySensors(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.airQuality.getAll().subscribe((result) => {
        this.sensorList = this.sensorList.concat(result);
        const nameList: string[] = [];
        result.forEach((sensor) => nameList.push(sensor.name));
        this.content = this.content.concat(nameList);
        resolve();
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        reject(error);
      });
    });
    return promise;
  }

  private loadAllWaterQuialitySensors(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.waterQuality.getAll().subscribe((result) => {
        this.sensorList = this.sensorList.concat(result);
        const nameList: string[] = [];
        result.forEach((sensor) => nameList.push(sensor.name));
        this.content = this.content.concat(nameList);
        resolve();
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        reject(error);
      });
    });
    return promise;
  }

  private loadAllWeatherQuialitySensors(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.weatherStationService.getAll().subscribe((result) => {
        this.sensorList = this.sensorList.concat(result);
        const nameList: string[] = [];
        result.forEach((sensor) => nameList.push(sensor.name));
        this.content = this.content.concat(nameList);
        resolve();
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        reject(error);
      });
    });
    return promise;
  }

  private loadMagnetics(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.sensorService.getAllMagnetics().subscribe((result) => {
        this.sensorList = this.sensorList.concat(result);
        resolve();
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`);
        reject(error);
      });
    });
    return promise;
  }

  private loadAdvancedAmbiences(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.sensorService.getAllAdvancedAmbiences().subscribe((result) => {
        this.sensorList = this.sensorList.concat(result);
        resolve();
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`);
        reject(error);
      });
    });
    return promise;
  }

  private loadSwitches(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.sensorService.getAllSwitches().subscribe((result) => {
        this.sensorList = this.sensorList.concat(result);
        resolve();
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`);
        reject(error);
      });
    });
    return promise;
  }

  private selectMarker(building: Building): string {
    switch (building.buildingType) {
      case 'hospital':
        return '\ue548';
      case 'education':
        return '\ue80c';
      case 'government':
        return '\ue90e';
      case 'store':
        return '\uf1cc';
      default:
        return '\uea40';
    }
  }

  private selectZamakonaMarker(sensor: any): string {
    switch (sensor.type) {
      case 'hospital':
        return '\ue548';
      case 'education':
        return '\ue80c';
      case 'government':
        return '\ue90e';
      case 'store':
        return '\uf1cc';
      default:
        return '\uea40';
    }
  }


  private createContent(building: Building): string {
    const rooms = this.roomList.filter((room) => {
      return (room.buildingId === building.id);
    }).map((room) => {
      return room;
    });

    let magnetics = 0;
    let advancedAmbiences = 0;
    let switches = 0;
    rooms.forEach((room) => {
      this.sensorList.forEach((sensor) => {
        if (sensor.roomId === room.id) {
          switch (sensor.type) {
            case 'magnetic':
              magnetics++;
              break;
            case 'advanced_ambience':
              advancedAmbiences++;
              break;
            case 'switch':
              switches++;
              break;
          }
        }
      });
    });



    const content = '<h2>' + building.name + '</h2>' +
      '<p style="margin-bottom: 0.5rem; font-size: 14px;"><b>' + $localize`Registered Rooms` +
      ':</b> ' + rooms.length + ' </p>' +
      '<p style="margin-bottom: 0px; font-size: 14px;"><b>' + $localize`Total sensors` +
      ':</b> ' + (magnetics + advancedAmbiences + switches) + ' </p>' +
      '<ul style="margin: 0.5rem 0px 1rem 0px; font-size: 14px;"><li><b>' + $localize`Magnetics` +
      ':</b> ' + magnetics + ' </li>' +
      '<li><b>' + $localize`Advanced Ambiences` + ':</b> ' + advancedAmbiences + ' </li>' +
      '<li><b>' + $localize`Switches` + ':</b> ' + switches + ' </li></ul>' +
      '<a style="font-size: 14px;" href="' + window.location.origin +
      '/rooms-map/' + building.id + '">' + $localize`See Rooms` + '</a>';

    // Utilizar el onClick si queremos que se abra en una pestaña nueva:
    // '<a onClick="window.open(\''+ window.location.origin +
    // '/rooms/no-building' + '\')">See Rooms</a>';  (añadir Styles al enlace)

    return content;
  }


  private createZamakonaContent(zamakona: any): string {
    const content = '<h2>' + zamakona.name + '</h2>' + '<p style="margin-bottom: 0.5rem; font-size: 14px;"><b>Bat:' + zamakona.battery + ' %</b></p>';
    return content;
  }
}
