<div class="card-container">
  <div class="title">{{sensorData.name}}. {{showDate(sensorData.date)}} </div>
  <div style="position:absolute; right:0px;top:0px">
    <div
      style="display: flex; flex-direction: row; align-items: baseline; justify-content: end; gap: 10px;margin-right: 10px; ">
      <mat-button-toggle-group class="toogle-button" name="Grupo" aria-label="Tiempo" value="Observaciones"
        #group="matButtonToggleGroup">
        <mat-button-toggle value="Observaciones" (change)="onGroupChange($event.value)">Observaciones
          sinópticas</mat-button-toggle>
        <mat-button-toggle value="Control" (change)="onGroupChange($event.value)">Control</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="card" *ngFor="let sensor of sensorData.values">
    <div *ngIf="isSpeedMeter(sensor.description)==false && isCompass(sensor.description)==false" class="card-header">
      <div>
        <p>{{sensor.description}}</p>
        <button mat-icon-button *ngIf="sensor.legend" style="color: 
        white; margin-right: 15px; cursor: pointer;
        " matTooltip="Ver leyenda del sensor" matTooltipPosition="above" onclick="this.blur()"
          (click)="openDialog(sensor.description,sensor.legend)"><mat-icon>info</mat-icon></button>
      </div>
      <div class="card-body">
        <p style="font-size: 28px;">{{sensor.value}}</p>
        <p style="font-size: 22px;">{{sensor.unit}}</p>
      </div>
    </div>

    <div *ngIf="isSpeedMeter(sensor.description)==true && isCompass(sensor.description)==false" class="card-header">
      <div>
        <p>{{sensor.description}}</p>
        <button mat-icon-button *ngIf="sensor.legend" style="color: 
        white; margin-right: 15px; cursor: pointer;" matTooltip="Ver leyenda del sensor" matTooltipPosition="above"
          onclick="this.blur()"
          (click)="openDialog(sensor.description,sensor.legend)"><mat-icon>info</mat-icon></button>
      </div>
      <div class="card-body">
        <app-zamakona-sensor-speedmeter [minSpeed]="'0.01'" [maxSpeed]="'60'" [currentSpeed]="sensor.value"
          [description]="sensor.description" [unit]="sensor.unit"></app-zamakona-sensor-speedmeter>
      </div>
    </div>

    <div *ngIf="isCompass(sensor.description)==true && isSpeedMeter(sensor.description)==false " class="card-header">
      <div>
        <p>{{sensor.description}}</p>
        <button mat-icon-button *ngIf="sensor.legend" style="color: 
        white; margin-right: 15px; cursor: pointer;" matTooltip="Ver leyenda del sensor" matTooltipPosition="above"
          onclick="this.blur()"
          (click)="openDialog(sensor.description,sensor.legend)"><mat-icon>info</mat-icon></button>
      </div>
      <div class="card-body">
        <app-zamakona-sensor-compass [minRange]="'0'" [maxRange]="'360'" [currentRange]="sensor.value"
          [description]="sensor.description"></app-zamakona-sensor-compass>
      </div>
    </div>

  </div>


  <ng-template #dialogTemplate>
    <h2 mat-dialog-title>{{selectedSensorTitle}}</h2>
    <mat-dialog-content>
      <p style="white-space: pre-line; word-wrap: break-word;overflow-wrap: break-word;    text-align: justify;">{{selectedSensorLegend}}</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button style="background-color: lightgray;" mat-raised-button onclick="this.blur()"
        (click)="closeDialog()">Cerrar</button>
    </mat-dialog-actions>
  </ng-template>