import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
@Component({
  selector: 'app-Login',
  templateUrl: './Login.component.html',
  styleUrls: ['./Login.component.css'],

})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  constructor(private fb: FormBuilder, private snackBar: MatSnackBar, private authService: AuthService, public router: Router,) {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8), // Longitud mínima de 8 caracteres
        ]
      ]
    });
  }

  ngOnInit() {
    if(this.authService.isAuthenticatedUser()){
      this.router.navigate([""]);
    }
  }
  onSubmit() {
   
    if (this.loginForm.valid) {

      this.authService.login(this.loginForm.value).subscribe((response) => {
        if(response && response.error){
          this.openSnackBar(response.error);
        }else if (response && !response.error) {
          this.authService.setUser(response.user)
          this.authService.setCookie('access_token', response.access_token,60)// 1 hora en minutos
          this.authService.setCookie('refresh_token', response.refresh_token,60*24) // 1 día en minutos
          this.authService.setCookie('user_data',JSON.stringify(response.user),60*24) // dia en minutos
          this.router.navigate([""]);
        }
      }, (responseError) => {
        this.openSnackBar(responseError.error.error);
      })
    } else {
    
      this.openSnackBar('El formulario es inválido. Por favor, revisa los campos.');
    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'Cerrar', {
      duration: 3000, // 3 segundos de duración
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['blue-snackbar']
    });
  }
}
