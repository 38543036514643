export class Room {
  constructor(
    public id: string,
    public type: string,
    public buildingId: string,
    public name: string,
    public description: string,
    public dateCreated: Date,
    public dateModified: Date,
    public roomType: string,
  ) {}
}
