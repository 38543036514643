export class User {
  private _id: string;

  private _username: string;
  private _email: string;

  constructor(id?: string, username?: string, email?: string) {
    this._id = id ?? ''; // Si no se proporciona 'id', se asigna 0 por defecto
    this._username = username ?? ''; // Si no se proporciona 'username', se asigna una cadena vacía
    this._email = email ?? ''; // Si no se proporciona 'email', se asigna una cadena vacía
  }

  // Getter y Setter para id
  public getid(): string {
    return this._id;
  }

  public setid(value: string) {
    this._id = value;
  }

  // Getter y Setter para username
  public getusername(): string {
    return this._username;
  }

  public setusername(value: string) {
    this._username = value;
  }

  // Getter y Setter para email
  public getemail(): string {
    return this._email;
  }

  public setemail(value: string) {
    this._email = value;
  }
}
