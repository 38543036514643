<div class="notifications-container">
  <mat-card class="filter">

    <mat-grid-list cols="6" rowHeight="100px">
      <mat-grid-tile class="notifications-header" colspan="6">
        <h1 i18n>Notification Filter</h1>
        <div mat-icon-button class="menu" [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </div>
        <mat-menu #menu="matMenu">
          <button mat-menu-item id="mark-all-read" (click)="readAll()">
            <mat-icon>mark_email_read</mat-icon>
            <span i18n>Mark all as read</span>
          </button>
        </mat-menu>
      </mat-grid-tile>

      <form class="building-room-selector" [formGroup]="buildingRoomSelector">
        <mat-grid-tile colspan="3">
          <mat-form-field class="building-selector">
            <mat-label i18n>Building:</mat-label>
            <mat-select formControlName="buildingName">
              <mat-option *ngFor="let building of buildingList" [value]="building.name">{{ building.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="3">
          <mat-form-field class="room-selector">
            <mat-label i18n>Room:</mat-label>
            <mat-select formControlName="roomName">
              <mat-option *ngFor="let room of roomList" [value]="room.name">{{ room.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
      </form>

      <form class="notifications-filter" [formGroup]="notificationsFilter"
        *ngIf="buildingNameField.valid && roomNameField.valid">
        <mat-grid-tile colspan="2">
          <mat-form-field class="level-notifications-filter">
            <mat-label i18n>Level:</mat-label>
            <mat-select formControlName="level">
              <mat-option *ngFor="let level of levelList" [value]="level.value"> {{ level.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <mat-form-field class="time-notifications-filter">
            <mat-label i18n>Time:</mat-label>
            <mat-select formControlName="time">
              <mat-option *ngFor="let time of timeList" [value]="time.value">{{ time.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <mat-form-field class="read-notifications-filter">
            <mat-label i18n>Read:</mat-label>
            <mat-select formControlName="read">
              <mat-option *ngFor="let read of readList" [value]="read.value">{{ read.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
      </form>

      <mat-grid-tile colspan="6" *ngIf="buildingNameField.valid && roomNameField.valid">
        <form class="text-search">
          <mat-form-field class="text-search-input" appearance="fill">
            <mat-label i18n>Search...</mat-label>
            <input type="text" matInput [formControl]="textSearchControl" >
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </form>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card>

  <mat-card class="no-notifications"
    *ngIf="(buildingNameField.valid && roomNameField.valid) &&
      (notificationsPaged.length === 0)">
    <h2 i18n>There isn't any notification with these parameters</h2>
  </mat-card>

  <mat-card class="notifications" *ngIf="(buildingNameField.valid && roomNameField.valid) &&
  (notificationsSearched.length > 0)">
    <div class="notification" *ngFor="let notification of notificationsPaged; first as isFirst">
      <div [style.margin-top.px]="isFirst ? 5 : 10" class="alert"
            [ngClass]="{'alert-info': notification.level === 'info', 'alert-warning': notification.level === 'warning',
            'alert-danger': notification.level === 'error', 'mark-read': notification.read}">
        <span class="level" *ngIf="notification.level === 'info'" i18n>INFO</span>
        <span class="level" *ngIf="notification.level === 'warning'" i18n>WARNING</span>
        <span class="level" *ngIf="notification.level === 'error'" i18n>ERROR</span>
        <span class="message">{{ notification.message[language] }}</span>
        <span class="date">{{ notification.date | date:'dd/MM/yyyy HH:mm:ss' }}</span>
        <button mat-button type="button" class="markAsRead" data-dismiss="alert" aria-label="Close"
          (click)="markAsRead(notification.id, notification.read)">
          <i class="material-icons" *ngIf="notification.read"> drafts </i>
          <i class="material-icons" *ngIf="!notification.read"> markunread </i>
        </button>
      </div>
    </div>
    <mat-paginator [length]="notificationsSearched.length" [pageSizeOptions]="[10, 50, 100]" [pageSize]="pageEvent.pageSize" [pageIndex]="pageEvent.pageIndex" aria-label="Select page" (page)="changePage($event)"></mat-paginator>
  </mat-card>

</div>