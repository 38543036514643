import { map } from 'rxjs/operators';
import { Socket } from 'ngx-socket-io';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { ResponseRamp5 } from 'src/app/interfaces/ResponseRamp5';
import { ResponseRamp4 } from 'src/app/interfaces/ResponseRamp4';


@Injectable({
  providedIn: 'root'
})
export class RampService {

  private baseUrl: string = environment.backendUrl;

  constructor(private http: HttpClient, private socket: Socket,

  ) { }



  getRamp5(id: string) {
    const url: string = 'ramp/id/' + id;
    return this.http.get<ResponseRamp5>(this.baseUrl + url).pipe(map((result: ResponseRamp5) => result))

  }
  getRamp4(id: string) {
    const url: string = 'ramp/id/' + id;
    return this.http.get<ResponseRamp4>(this.baseUrl + url).pipe(map((result: ResponseRamp4) => result))

  }




}
