export class ZamakonaRealTimeValue {

    description: string;
    value: string;
    unit: string;
    legend?:string;
    initialThreshold:string;
    observation:boolean
    control:boolean
    order:number

    constructor(description: string,value: string, unit: string,initialThreshold:string,observation:boolean,control:boolean,order:number,legend?:string) {
        this.description = description;
        this.value = value;
        this.unit = unit;
        this.legend = legend;
        this.initialThreshold = initialThreshold;
        this.observation=observation;
        this.control=control;
        this.order=order
    }

}