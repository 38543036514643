import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeSignalBody } from 'src/app/interfaces/changeSignalBody';
import { SwitchOnOffBody } from 'src/app/interfaces/switchOnOffBody';
import { Switch } from 'src/app/models/switch';
import { SensorService } from 'src/app/services/sensor.service';

@Component({
  selector: 'app-switch-detail',
  templateUrl: './switch-detail.component.html',
  styleUrls: ['./switch-detail.component.scss'],
})
export class SwitchDetailComponent implements OnInit {
  sensorId!: string;
  roomId: string = 'no-room';
  sensor: Switch = new Switch('', '', '', '', '', new Date(), new Date(), 0, '', '', '', '', '');

  constructor(
    private sensorService: SensorService,
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
  ) {
    // Get the group id from the route
    this.sensorId = this.route.snapshot.params.sensorId;
    this.roomId = this.route.snapshot.params.roomId;
  }

  ngOnInit(): void {
    this.sensorService.getSwitchById(this.sensorId).subscribe((result) => {
      this.sensor = result;
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });
  }

  back(): void {
    this.router.navigate(['/sensors/' + this.roomId]);
  }

  changeStatus(num: number): void {
    const body: SwitchOnOffBody = {
      id: this.sensor.id,
      device: num,
      status: '',
    };

    if (num === 1) {
      body.status = (this.sensor.status1 === 'on') ? 'off' : 'on';
    } else {
      body.status = (this.sensor.status2 === 'on') ? 'off' : 'on';
    }

    this.sensorService.switchOnOff(body).subscribe(() => {
      this.ngOnInit();
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });
  }

  changeSignal(): void {
    const body: ChangeSignalBody = {
      id: this.sensor.id,
      signal: (this.sensor.signal === 'connected') ? 'disconnected' : 'connected',
    };
    this.sensorService.changeSignalSwitch(body).subscribe(() => {
      this.ngOnInit();
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });
  }
}
