import { SimpleAmbience } from "./simple-ambience";

export class AdvancedAmbience extends SimpleAmbience {
  constructor(
      id: string,
      type: string,
      roomId: string,
      name: string,
      description: string,
      dateCreated: Date,
      dateModified: Date,
      battery: number,
      signal: string,
      temperature: number,
      humidity: number,
    public pir: string,
    public light: number,
    public co2: number,
    public tvoc: number,
    public barometricPressure: number,
  ) {
    super(id, type, roomId, name, description, dateCreated, dateModified,
        battery, signal, temperature, humidity);
  }
}
