<mat-card>
  <div class="section-header">
    <h1 i18n>Events for {{ roomName }}</h1>
  </div>

  <mat-grid-list cols="6" rowHeight="40px">
    <form autocomplete="off" class="action-form" (ngSubmit)="onSubmit()" [formGroup]="actionForm">
      <mat-grid-tile colspan="6" rowspan="2">
        <h2 i18n>Limit Value Control</h2>
      </mat-grid-tile>

      <mat-grid-tile class="left" rowspan="4">
        <h3 i18n>Temperature control:</h3>
        <div class="error-message" *ngIf="actionForm.getError('invalidTemp') || actionForm.getError('invalidDisabledTemp')">
          <mat-error *ngIf="actionForm.getError('invalidTemp')" i18n>The minimum temperature must be greater than the maximum temperature</mat-error>
          <mat-error *ngIf="actionForm.getError('invalidDisabledTemp')" i18n>To disable the temperature control both values must be 0ºC</mat-error>
        </div>
      </mat-grid-tile>
      <mat-grid-tile class="right" colspan="2" rowspan="4">
        <mat-form-field class="temp-min-container" appearance="fill">
          <mat-label i18n>Minimum</mat-label>
          <input type="number" matInput formControlName="tempMin">
          <span matSuffix>ºC</span>
          <mat-error i18n>Must be between 0ºC and 40ºC</mat-error>
        </mat-form-field>
        <mat-form-field class="temp-max-container" appearance="fill">
          <mat-label i18n>Maximum</mat-label>
          <input type="number" matInput formControlName="tempMax">
          <span matSuffix>ºC</span>
          <mat-error i18n>Must be between 0ºC and 40ºC</mat-error>
        </mat-form-field>
        <span class="info-message msg-temp" i18n>0ºC = disabled</span>
      </mat-grid-tile>
      <mat-grid-tile class="left" rowspan="4">
        <h3 i18n>CO2 warning:</h3>
      </mat-grid-tile>
      <mat-grid-tile class="right" colspan="2" rowspan="4">
        <mat-form-field class="co2-max-container" appearance="fill">
          <mat-label i18n>Maximum</mat-label>
          <input type="number" matInput formControlName="co2Max">
          <span matSuffix>ppm</span>
          <mat-error i18n>Must be between 0ppm and 3000ppm</mat-error>
        </mat-form-field>
        <span class="info-message" i18n>0ppm = disabled</span>
        <mat-form-field class="co2-cooldown-container" appearance="fill">
          <mat-label i18n>Cooldown time:</mat-label>
          <input type="number" matInput formControlName="co2Cooldown">
          <span matSuffix>h.</span>
          <mat-error i18n>Must be between 0 h. and 168 h.</mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile class="left" rowspan="4">
        <h3 i18n>TVOC warning:</h3>
      </mat-grid-tile>
      <mat-grid-tile class="right" colspan="2" rowspan="4">
        <mat-form-field class="tvoc-max-container" appearance="fill">
          <mat-label i18n>Maximum</mat-label>
          <input type="number" matInput formControlName="tvocMax">
          <span matSuffix>ppb</span>
          <mat-error i18n>Must be between 0ppb and 1500ppb</mat-error>
        </mat-form-field>
        <span class="info-message" i18n>0ppb = disabled</span>
        <mat-form-field class="tvoc-cooldown-container" appearance="fill">
          <mat-label i18n>Cooldown time:</mat-label>
          <input type="number" matInput formControlName="tvocCooldown">
          <span matSuffix>h.</span>
          <mat-error i18n>Must be between 0 h. and 168 h.</mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile class="left" rowspan="4">
        <h3 i18n>Battery warning:</h3>
      </mat-grid-tile>
      <mat-grid-tile class="right" colspan="2" rowspan="4">
        <mat-form-field class="battery-min-container" appearance="fill">
          <mat-label i18n>Minimum</mat-label>
          <input type="number" matInput formControlName="batteryMin">
          <span matSuffix>%</span>
          <mat-error i18n>Must be between 0% and 50%</mat-error>
        </mat-form-field>
        <span class="info-message" i18n>0% = disabled</span>
        <mat-form-field class="battery-cooldown-container" appearance="fill">
          <mat-label i18n>Cooldown time:</mat-label>
          <input type="number" matInput formControlName="batteryCooldown">
          <span matSuffix>h.</span>
          <mat-error i18n>Must be between 0 h. and 168 h.</mat-error>
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile colspan="6" rowspan="2">
        <h2 i18n>Check Intervals</h2>
      </mat-grid-tile>

      <mat-grid-tile class="left" rowspan="4">
        <h3 i18n>No Activity:</h3>
        <div class="error-message" *ngIf="actionForm.getError('invalidNoAct') || actionForm.getError('invalidDisabledNoAct')">
          <mat-error *ngIf="actionForm.getError('invalidNoAct')" i18n>The total time must be greater than interval time</mat-error>
          <mat-error *ngIf="actionForm.getError('invalidDisabledNoAct')" i18n>To disable the no activity check time, both values must be 0 min.</mat-error>
        </div>
      </mat-grid-tile>
      <mat-grid-tile class="right" colspan="2" rowspan="4">
        <mat-form-field class="no-act-total-container" appearance="fill">
          <mat-label i18n>Total time:</mat-label>
          <input type="number" matInput formControlName="noActTotalTime">
          <span matSuffix>min.</span>
          <mat-error i18n>Must be between 0 min. and 1440 min.</mat-error>
        </mat-form-field>
        <mat-form-field class="no-act-interval-container" appearance="fill">
          <mat-label i18n>Interval time:</mat-label>
          <input type="number" matInput formControlName="noActInterval">
          <span matSuffix>min.</span>
          <mat-error i18n>Must be between 0 min. and 360 min.</mat-error>
        </mat-form-field>
        <span class="info-message" i18n>0 min. = disabled</span>
      </mat-grid-tile>
      <mat-grid-tile class="left" rowspan="4">
        <h3 i18n>Door / Window Open:</h3>
        <div class="error-message" *ngIf="actionForm.getError('invalidDWOpen') || actionForm.getError('invalidDisabledDWOpen')">
          <mat-error *ngIf="actionForm.getError('invalidDWOpen')" i18n>The total time must be greater than interval time</mat-error>
          <mat-error *ngIf="actionForm.getError('invalidDisabledDWOpen')" i18n>To disable the doow / window open check time, both values must be 0 min.</mat-error>
        </div>
      </mat-grid-tile>
      <mat-grid-tile class="right" colspan="2" rowspan="4">
        <mat-form-field class="dw-open-total-container" appearance="fill">
          <mat-label i18n>Total time:</mat-label>
          <input type="number" matInput formControlName="dwOpenTotalTime">
          <span matSuffix>min.</span>
          <mat-error i18n>Must be between 0 min. and 1440 min.</mat-error>
        </mat-form-field>
        <mat-form-field class="dw-open-interval-container" appearance="fill">
          <mat-label i18n>Interval time:</mat-label>
          <input type="number" matInput formControlName="dwOpenInterval">
          <span matSuffix>min.</span>
          <mat-error i18n>Must be between 0 min. and 360 min.</mat-error>
        </mat-form-field>
        <span class="info-message" i18n>0 min. = disabled</span>
      </mat-grid-tile>

      <mat-grid-tile colspan="6" rowspan="2">
        <h2 i18n>Device Control</h2>
      </mat-grid-tile>

      <mat-grid-tile class="left" rowspan="5">
        <h3 i18n>Light:</h3>
      </mat-grid-tile>
      <mat-grid-tile class="right-long" colspan="5" rowspan="5">
        <div class="time-container">
          <h4 i18n>Active Schedule</h4>
          <div class="time-input-container">
            <mat-form-field class="act-sch-container" appearance="fill">
              <mat-label i18n>Start:</mat-label>
              <input type="time" matInput formControlName="actSchLightStart">
            </mat-form-field>
            <mat-form-field class="act-sch-container" appearance="fill">
              <mat-label i18n>Finish:</mat-label>
              <input type="time" matInput formControlName="actSchLightFinish">
            </mat-form-field>
          </div>
          <mat-checkbox class="all-day-sch-checkbox" formControlName="allDaySchLight" i18n>Active all day</mat-checkbox>
        </div>
        <div class="day-container">
          <h4 i18n>Active Days</h4>
          <mat-form-field class="act-day-container" appearance="fill">
            <mat-label i18n>Choose:</mat-label>
            <mat-select formControlName="activeDaysLight" multiple>
              <mat-select-trigger *ngIf="activeDaysLight.value">
                {{ displayDayInSelect(activeDaysLight) }}
              </mat-select-trigger>
              <mat-option *ngFor="let day of days" [value]="day.value">
                {{ day.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="action-container">
          <h4 i18n>Controlled By</h4>
          <mat-grid-list class="action-container-grid" cols="2" rowHeight="50px" style="width: 350px">
            <!-- <mat-grid-tile class="slide-container">
              <mat-slide-toggle formControlName="enableTemperatureLight" i18n>Temperature</mat-slide-toggle>
            </mat-grid-tile> -->
            <mat-grid-tile class="slide-container">
              <mat-slide-toggle formControlName="enableActivityLight" i18n>Motion detection</mat-slide-toggle>
            </mat-grid-tile>
            <!-- <mat-grid-tile class="slide-container">
              <mat-slide-toggle formControlName="enableDoorWindowLight" i18n>Door / Window</mat-slide-toggle>
            </mat-grid-tile> -->
          </mat-grid-list>
        </div>
      </mat-grid-tile>
      
      <mat-grid-tile class="left" rowspan="5">
        <h3 i18n>Air-conditioning:</h3>
      </mat-grid-tile>
      <mat-grid-tile class="right-long" colspan="5" rowspan="5">
        <div class="time-container">
          <h4 i18n>Active Schedule</h4>
          <div class="time-input-container">
            <mat-form-field class="act-sch-container" appearance="fill">
              <mat-label i18n>Start:</mat-label>
              <input type="time" matInput formControlName="actSchAirconStart">
            </mat-form-field>
            <mat-form-field class="act-sch-container" appearance="fill">
              <mat-label i18n>Finish:</mat-label>
              <input type="time" matInput formControlName="actSchAirconFinish">
            </mat-form-field>
          </div>
          <mat-checkbox class="all-day-sch-checkbox" formControlName="allDaySchAircon" i18n>Active all day</mat-checkbox>
        </div>
        <div class="day-container">
          <h4 i18n>Active Days</h4>
          <mat-form-field class="act-day-container" appearance="fill">
            <mat-label i18n>Choose:</mat-label>
            <mat-select formControlName="activeDaysAircon" multiple>
              <mat-select-trigger *ngIf="activeDaysAircon.value">
                {{ displayDayInSelect(activeDaysAircon) }}
              </mat-select-trigger>
              <mat-option *ngFor="let day of days" [value]="day.value">
                {{ day.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="action-container">
          <h4 i18n>Controlled By</h4>
          <mat-grid-list class="action-container-grid" cols="2" rowHeight="50px" style="width: 350px">
            <mat-grid-tile class="slide-container">
              <mat-slide-toggle formControlName="enableTemperatureAircon" i18n>Temperature</mat-slide-toggle>
            </mat-grid-tile>
            <mat-grid-tile class="slide-container">
              <mat-slide-toggle formControlName="enableActivityAircon" i18n>Motion detection</mat-slide-toggle>
            </mat-grid-tile>
            <mat-grid-tile class="slide-container">
              <mat-slide-toggle formControlName="enableDoorWindowAircon" i18n>Door / Window</mat-slide-toggle>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </mat-grid-tile>

      <mat-grid-tile class="left" rowspan="5">
        <h3 i18n>Heating:</h3>
      </mat-grid-tile>
      <mat-grid-tile class="right-long" colspan="5" rowspan="5">
        <div class="time-container">
          <h4 i18n>Active Schedule</h4>
          <div class="time-input-container">
            <mat-form-field class="act-sch-container" appearance="fill">
              <mat-label i18n>Start:</mat-label>
              <input type="time" matInput formControlName="actSchHeatingStart">
            </mat-form-field>
            <mat-form-field class="act-sch-container" appearance="fill">
              <mat-label i18n>Finish:</mat-label>
              <input type="time" matInput formControlName="actSchHeatingFinish">
            </mat-form-field>
          </div>
          <mat-checkbox class="all-day-sch-checkbox" formControlName="allDaySchHeating" i18n>Active all day</mat-checkbox>
        </div>
        <div class="day-container">
          <h4 i18n>Active Days</h4>
          <mat-form-field class="act-day-container" appearance="fill">
            <mat-label i18n>Choose:</mat-label>
            <mat-select formControlName="activeDaysHeating" multiple>
              <mat-select-trigger *ngIf="activeDaysHeating.value">
                {{ displayDayInSelect(activeDaysHeating) }}
              </mat-select-trigger>
              <mat-option *ngFor="let day of days" [value]="day.value">
                {{ day.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="action-container">
          <h4 i18n>Controlled By</h4>
          <mat-grid-list class="action-container-grid" cols="2" rowHeight="50px" style="width: 350px">
            <mat-grid-tile class="slide-container">
              <mat-slide-toggle formControlName="enableTemperatureHeating" i18n>Temperature</mat-slide-toggle>
            </mat-grid-tile>
            <mat-grid-tile class="slide-container">
              <mat-slide-toggle formControlName="enableActivityHeating" i18n>Motion detection</mat-slide-toggle>
            </mat-grid-tile>
            <mat-grid-tile class="slide-container">
              <mat-slide-toggle formControlName="enableDoorWindowHeating" i18n>Door / Window</mat-slide-toggle>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </mat-grid-tile>
      
      <mat-grid-tile class="left" rowspan="5">
        <h3 i18n>Camera:</h3>
      </mat-grid-tile>
      <mat-grid-tile class="right-long" colspan="5" rowspan="5">
        <div class="time-container">
          <h4 i18n>Active Schedule</h4>
          <div class="time-input-container">
            <mat-form-field class="act-sch-container" appearance="fill">
              <mat-label i18n>Start:</mat-label>
              <input type="time" matInput formControlName="actSchCameraStart">
            </mat-form-field>
            <mat-form-field class="act-sch-container" appearance="fill">
              <mat-label i18n>Finish:</mat-label>
              <input type="time" matInput formControlName="actSchCameraFinish">
            </mat-form-field>
          </div>
          <mat-checkbox class="all-day-sch-checkbox" formControlName="allDaySchCamera" i18n>Active all day</mat-checkbox>
        </div>
        <div class="day-container">
          <h4 i18n>Active Days</h4>
          <mat-form-field class="act-day-container" appearance="fill">
            <mat-label>Choose:</mat-label>
            <mat-select formControlName="activeDaysCamera" multiple>
              <mat-select-trigger *ngIf="activeDaysCamera.value">
                {{ displayDayInSelect(activeDaysCamera) }}
              </mat-select-trigger>
              <mat-option *ngFor="let day of days" [value]="day.value">
                {{ day.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="action-container">
          <h4 i18n>Controlled By</h4>
          <mat-grid-list class="action-container-grid" cols="2" rowHeight="50px" style="width: 350px">
            <mat-grid-tile class="slide-container">
              <mat-slide-toggle formControlName="enableTemperatureCamera" i18n>Temperature</mat-slide-toggle>
            </mat-grid-tile>
            <mat-grid-tile class="slide-container">
              <mat-slide-toggle formControlName="enableActivityCamera" i18n>Motion detection</mat-slide-toggle>
            </mat-grid-tile>
            <mat-grid-tile class="slide-container">
              <mat-slide-toggle formControlName="enableDoorWindowCamera" i18n>Door / Window</mat-slide-toggle>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </mat-grid-tile>

      <mat-grid-tile colspan="6" rowspan="3" class="form-footer">
        <div class="save-cancel">
          <button mat-raised-button class="submit" color="primary" type="submit" [disabled]="actionForm.invalid" i18n>Save changes</button>
          <button mat-raised-button class="cancel" color="secondary" (click)="cancelChanges()" i18n>Cancel</button>
        </div>
      </mat-grid-tile>
    </form>
  </mat-grid-list>
</mat-card>