import { Building } from '../models/building';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BuildingService {
  private baseUrl: string = environment.backendUrl;

  constructor(
    private http: HttpClient,
  ) { }

  getAllBuildings(): Observable<Building[]> {
    const url: string = "building/getAll";
    return this.http.get<Building[]>(this.baseUrl + url);
  }

  getBuildingById(id: string): Observable<Building> {
    const url: string = "building/get";
    return this.http.get<Building>(this.baseUrl + url + "?id=" + id);
  }

  createBuilding(building: Building): Observable<Building> {
    const url: string = "building/create";
    return this.http
        .post<Building>(this.baseUrl + url, building);
  }

  updateBuilding(building: Building): Observable<Building> {
    const url: string = "building/update";
    return this.http
        .post<Building>(this.baseUrl + url, building);
  }

  deleteBuilding(id: string): Observable<any> {
    const url: string = "building/delete";
    return this.http.delete<any>(this.baseUrl + url + '?id=' + id);
  }
}
