import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WeatherStation } from 'src/app/models/zamakona/weatherStation';
import { WeatherStationService } from 'src/app/services/zamakona/weatherStation.service';
import { ZamakonaSensorDetailDialogComponent } from '../zamakona-sensor-detail-dialog.component';
import { HistoricData } from 'src/app/models/zamakona/historicData';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  templateUrl: './../zamakona-sensor-detail-dialog.component.html',
  styleUrls: ['./../zamakona-sensor-detail-dialog.component.scss']
})
export class ZamakonaWeatherStationDetailComponent extends ZamakonaSensorDetailDialogComponent<WeatherStationService> {

  sensor!: WeatherStation;
  grafanaUrlSafe: SafeResourceUrl;

  constructor(
    protected snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) protected matDialogData: HistoricData<WeatherStation>,
    private sanitizer: DomSanitizer) {
      super(snackBar, matDialogData);

    this.sensorType = 'Weather Station';
    this.dataView = [
      {label: 'Type', value: this.sensor.type},
      {label: 'Absolute Humidity', value: this.sensor.absoluteHumidity},
      {label: 'Air Density', value: this.sensor.airDensity},
      {label: 'Avg Wind Direction', value: this.sensor.avgWindDirection},
      {label: 'Avg Wind Gust Direction', value: this.sensor.avgWindGustDirection},
      {label: 'Avg Wind Gust Speed', value: this.sensor.avgWindGustSpeed},
      {label: 'Avg Wind Speed', value: this.sensor.avgWindSpeed},
      {label: 'Battery', value: this.sensor.battery},
      {label: 'Charging State', value: this.sensor.charging_state},
      {label: 'Compass', value: this.sensor.compass},
      {label: 'Corrected Wind Direction', value: this.sensor.corectedWindDirection},
      {label: 'Current', value: this.sensor.current},
      {label: 'Date Created', value: this.sensor.dateCreated},
      {label: 'Date Modified', value: this.sensor.dateModified},
      {label: 'Description', value: this.sensor.description},
      {label: 'Dew Point', value: this.sensor.dewPoint},
      {label: 'Heat Index', value: this.sensor.heatIndex},
      {label: 'Max', value: this.sensor.max},
      {label: 'Name', value: this.sensor.name},
      {label: 'Precip Intensity', value: this.sensor.precipIntensity},
      {label: 'Precip Status', value: this.sensor.precipStatus},
      {label: 'Precip Total', value: this.sensor.precipTotal},
      {label: 'Pressure', value: this.sensor.pressure},
      {label: 'Pressure Sea Level', value: this.sensor.pressureSeaLevel},
      {label: 'Pressure Station', value: this.sensor.pressureStation},
      {label: 'Relative Humidity', value: this.sensor.relativeHumidity},
      {label: 'Secuence', value: this.sensor.secuence},
      {label: 'Signal', value: this.sensor.signal},
      {label: 'Solar Radiation', value: this.sensor.solarRadiation},
      {label: 'Status', value: this.sensor.status},
      {label: 'Sun Noon Time', value: this.sensor.sunNoonTime},
      {label: 'Sun Position', value: this.sensor.sunPosition},
      {label: 'Sun Rise Time', value: this.sensor.sunRiseTime},
      {label: 'Sunset Time', value: this.sensor.sunsetTime},
      {label: 'Sunshine Hours', value: this.sensor.sunshineHours},
      {label: 'Supply Voltage', value: this.sensor.supplyVoltage},
      {label: 'Temperature', value: this.sensor.temperature},
      {label: 'Timestamp', value: this.sensor.timestamp},
      {label: 'Twilight Astonom', value: this.sensor.twilightAstonom},
      {label: 'Twilight Civil', value: this.sensor.twilightCivil},
      {label: 'Twilight Nautica', value: this.sensor.twilightNautica},
      {label: 'Wet Bulb Temperature', value: this.sensor.wetBulbTemperature},
      {label: 'Wind Chill', value: this.sensor.windChill},
      {label: 'Wind Direction', value: this.sensor.windDirection},
      {label: 'Wind Sensor Status', value: this.sensor.windSensorStatus},
      {label: 'Wind Speed', value: this.sensor.windSpeed},
      {label: 'xTilt', value: this.sensor.xTilt},
      {label: 'yTilt', value: this.sensor.yTilt},
      {label: 'zOrient', value: this.sensor.zOrient},
    ];

    this.grafanaUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.matDialogData.grafanaUrlSafe);
  }
}
