<div class="container">
  <mat-card class="content">
    <mat-grid-list cols="3" rowHeight="120px">
      <mat-grid-tile class="header" colspan="3">
        <h1 i18n>Sensor Types</h1>
      </mat-grid-tile>
    </mat-grid-list>
    <!--<mat-grid-list style="margin: 0 1rem" cols="3" rowHeight="120px">

      <mat-grid-tile rowspan="5">
        <mat-card class="card">
          <div class="title">
            <h2 i18n>Magnetic</h2>
          </div>
          <div class="image-container">
            <img mat-card-image src="assets/milesight/em300-mcs-product-1.png"
              alt="Photo of a MileSight sensor model EM300 MCS">
          </div>
          <mat-card-content class="content">
            <p i18n>This sensor has a magnetic contact to detect if the two parts of the contact are closed
              each other or not. It's used to know if a door or a window is closed or open.</p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>

      <mat-grid-tile rowspan="5">
        <mat-card class="card">
          <div class="title">
            <h2 i18n>Switch</h2>
          </div>
          <div class="image-container">
            <img mat-card-image src="assets/milesight/compact-controller.jpg"
              alt="Photo of a Shiba Inu">
          </div>
          <mat-card-content class="content">
            <p i18n> It's essentially a switch controller for an electric device because it can control a light
              or a plug. It report about its state, on or off, and we can switch on or off remotely. </p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>

      <mat-grid-tile rowspan="5">
        <mat-card class="card">
          <div class="title">
            <h2 i18n>Advanced Ambience</h2>
          </div>
          <div class="image-container">
            <img mat-card-image src="assets/milesight/am100.png"
              alt="Photo of a MileSight sensor model AM100">
          </div>
          <mat-card-content class="content">
            <p i18n> This is a complex sensor which monitors a large list of environmental variables. It can
              control temperature, humidity, passive infrared (PIR), light, CO2, volatile organic compounds
              (TVOC) and barometric pressure.  </p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>-->

    <mat-grid-list style="margin: 0 1rem" cols="3" rowHeight="120px">

      <mat-grid-tile rowspan="5">
        <mat-card class="card">
          <div class="title">
            <h2>Smart Agriculture Xtreme</h2>
          </div>
          <div class="image-container">
            <img mat-card-image src="assets/libelium/smart_agriculture_xtreme_representativa.png"
              alt="Photo of a MileSight sensor model Smart Agriculture Xtreme">
          </div>
          <mat-card-content class="content">
            <p>Smart Agriculture Xtreme is an improvement of our Agriculture line with a new selection of high-end professional sensors. 
              Monitoring of multiple environmental parameters in a wide range of applications.</p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>

      <mat-grid-tile rowspan="5">
        <mat-card class="card">
          <div class="title">
            <h2>Smart Water Xtreme</h2>
          </div>
          <div class="image-container">
            <img mat-card-image src="assets/libelium/smart_water_xtreme_portada.jpg"
              alt="Photo of a Smart Water Xtreme">
          </div>
          <mat-card-content class="content">
            <p>Smart Water Xtreme is equipped with multiple sensors to improve and simplify
               remote water quality monitoring (pH, Dissolved Oxygen, Conductivity, Salinity, Suspended Solid and Turbidity). </p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>

      <mat-grid-tile rowspan="5">
        <mat-card class="card">
          <div class="title">
            <h2>Smart Cities PRO</h2>
          </div>
          <div class="image-container">
            <img mat-card-image src="assets/libelium/Smart-Cities-PRO.jpg"
              alt="Photo of a Smart cities PRO sensors">
          </div>
          <mat-card-content class="content">
            <p>Me falta el texto de este sensor  </p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>

    </mat-grid-list>
  </mat-card>
</div>