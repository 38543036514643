<h1 class="dialog-title">{{title}}</h1>

<mat-grid-list cols="2" rowHeight="40px">
  <ng-container *ngFor="let sensor of sensorList">
    <mat-grid-tile>
      <p>{{sensor.name}}</p>
    </mat-grid-tile>
    <mat-grid-tile>
      <span [class]="['material-icons', sensor.signal]"> sensors </span>
    </mat-grid-tile>
  </ng-container>
</mat-grid-list><br>