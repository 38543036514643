
import { Component, OnDestroy, OnInit, Renderer2, Sanitizer, SecurityContext, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Equipment, ResponseCamera, Task } from 'src/app/interfaces/ResponseCamera';

import { CameraService } from 'src/app/services/zamakona/camera.service';
import { MatDialog, } from '@angular/material/dialog';

import { environment } from 'src/environments/environment';


interface Detection {
  className: string;
  confidence: string;
  topLeft: { x: number, y: number };
  bottomRight: { x: number, y: number };
  equipment?: Equipment[]
}

interface EquipmentData {
  name: string;
  confidence: number;
}

interface DetectionData {
  id: number;
  name: string;
  confidence: string;
  equipment?: EquipmentData[];
}

@Component({
  selector: 'app-zamakona-camera-detail',
  templateUrl: './zamakona-camera-detail.component.html',
  styleUrls: ['./zamakona-camera-detail.component.css']
})
export class ZamakonaCameraDetailComponent implements OnInit, OnDestroy {


  constructor(
    private cameraService: CameraService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private renderer: Renderer2,
    public dialog: MatDialog,
  ) {
  }

  selectedTask: any;
  selectedTaskData: any
  detectionData: any[] = []
  routeSub: Subscription = new Subscription();
  noimage: string = "/assets/logo/1280px-Zamakona_Yards_vectorial_SVG.svg.png"
  proxyImageUrl = environment.proxyimageURL;
  @ViewChild('dialogTemplate') dialogTemplate!: TemplateRef<any>;


  ngOnDestroy(): void {
    clearInterval(this.interval);
  }


  interval: any;

  ngOnInit() {

    this.routeSub = this.route.params.subscribe(params => {
      this.getCamera(params['cameraID'])
      this.interval = setInterval(() => {
        this.getCamera(params['cameraID'])
      }, 30000)
    });

  }


  getCamera(id: number) {
    this.cameraService.getCameraByID(id).subscribe((response: ResponseCamera) => {
      response.tasks.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      this.drawImages(response)
    }, (error) => {
      console.log(error)
    })

  }



  openDialog(task: Task) {
    let widthScreen = window.outerWidth;
    let widthDialog = "";
    if (widthScreen <= 1920) {
      widthDialog = '80%'
    } else {
      widthDialog = '60%'
    }
    this.dialog.open(this.dialogTemplate, {
      width: widthDialog
    });
    this.selectedTask = task
    this.DrawDetailImage(task)

  }
  closeDialog() {
    this.dialog.closeAll();
  }

  DrawDetailImage(task: Task) {

    let dataForShow: any[] = [];

    if (task.images) {


      const canvasContainer = document.getElementById('canvas-dialog-container');


      const canvasCreated = document.getElementById('div-detail-' + task.name)
      if (canvasContainer && canvasCreated) {
        // elimino el canvas si existe para añadir datos nuevos
        canvasContainer.removeChild(canvasCreated)
      }

      const div = this.renderer.createElement('div');
      this.renderer.setAttribute(div, 'id', 'div-detail-' + task.name)
      this.renderer.setAttribute(div, 'class', "canvas-detail-container-data")
      this.renderer.appendChild(canvasContainer, div);



      const canvas = this.renderer.createElement('canvas');
      this.renderer.setAttribute(canvas, "id", task.name)
      this.renderer.setAttribute(canvas, "Title", task.name)
      this.renderer.setAttribute(canvas, "class", "canvas-image-grid")
      this.renderer.setAttribute(canvas, 'id', task.name);
      this.renderer.appendChild(div, canvas);
      const ctx = canvas.getContext('2d')

      if (ctx) {
        const newWidth = 960; // Nuevo ancho del canvas
        const newHeight = 540; // Nuevo alto del canvas

        // Ajustar el ancho y el alto del canvas
        canvas.width = newWidth;
        canvas.height = newHeight;

        //imagen seleccionada
        let imageSelected = task.images.filter(img => img.name.toLowerCase().includes(".jpeg"))[0]
        var img = new Image();
        img.crossOrigin = "anonymous";
        if (imageSelected && imageSelected.url && imageSelected.proxy) {
          img.src = imageSelected.proxy
        } else {
          img.src = this.noimage
        }
        // if (imageSelected && imageSelected.url) {
        //   img.src = imageSelected.url
        // } else {
        //   img.src = this.noimage
        // }

        let id = 1;
        img.onload = function () {
          //se adapta la imagen al canvas
          var scale = Math.min(canvas.width / img.width, canvas.height / img.height);
          var newWidth = img.width * scale;
          var newHeight = img.height * scale;
          var x = (canvas.width - newWidth) / 2;
          var y = (canvas.height - newHeight) / 2;

          ctx.drawImage(img, x, y, newWidth, newHeight);

          if (task.metadatas && task.metadatas.length > 0) {

            //ordeno los metadatas de cada task para coger el ultimo
            task.metadatas.sort((a, b) => (a.metatada_id < b.metatada_id) ? 1 : ((b.metatada_id < a.metatada_id) ? -1 : 0));


            task.metadatas.forEach((metadata) => {

              metadata.data_data.forEach(data => {

                // Escalar los puntos a las dimensiones del canvas
                // this.areaSelected = data.name;
                const scaledPoints = data.points.map(point => ({
                  x: point.x * canvas.width,
                  y: point.y * canvas.height
                }));

                // Dibujar el polígono
                ctx.beginPath();
                ctx.moveTo(scaledPoints[0].x, scaledPoints[0].y);
                for (let i = 1; i < scaledPoints.length; i++) {
                  ctx.lineTo(scaledPoints[i].x, scaledPoints[i].y);
                }
                ctx.closePath();

                // Establecer los estilos y dibujar el polígono
                const styles = data.styles;
                ctx.strokeStyle = styles.color;
                ctx.lineWidth = styles.border;
                ctx.stroke();
              });


              const detections: Detection[] = metadata.data_detections.map(detection => ({
                className: detection.class,
                confidence: detection.confidence.toFixed(2).toString(),
                topLeft: { x: detection.top_left_x, y: detection.top_left_y },
                bottomRight: { x: detection.bottom_right_x, y: detection.bottom_right_y },
                equipment: detection.equipment
              }));


              detections.forEach(detection => {

                // Calcular las coordenadas del rectángulo en función de las coordenadas normalizadas
                const topLeftX = detection.topLeft.x * canvas.width;
                const topLeftY = detection.topLeft.y * canvas.height;
                const bottomRightX = detection.bottomRight.x * canvas.width;
                const bottomRightY = detection.bottomRight.y * canvas.height;


                ctx.beginPath();
                ctx.rect(topLeftX, topLeftY, bottomRightX - topLeftX, bottomRightY - topLeftY);
                ctx.strokeStyle = '#ff0000'; // Color rojo para las detecciones
                ctx.lineWidth = 1;
                ctx.stroke();

                //Evito crear etiquetas para el equipo de los trabajadores
                if (detection.className == "Worker" || detection.className == "PEM" || detection.className == "Crane") {
                  // Definir el estilo del cuadro de etiqueta
                  ctx.fillStyle = "rgba(255, 0, 0, 0.5)";; // Color de fondo rojo para el cuadro de etiqueta
                  ctx.font = '12px Arial';
                  // Medir el ancho del texto para centrarlo
                  // const labelWidth = ctx.measureText(`Id: ${id} - ${detection.className} ${detection.confidence}%`).width;
                  // const labelWidth = ctx.measureText(`Id: ${id} - ${detection.className} `).width;
                  const labelWidth = ctx.measureText(`${id}`).width;
                  // Dibujar el cuadro de etiqueta
                  ctx.fillRect(topLeftX - 1, topLeftY - 20, labelWidth + 10, 20);
                  // Establecer el color del texto
                  ctx.fillStyle = '#ffffff'; // Color blanco para el texto
                  // ctx.fillText(`Id: ${id} -  ${detection.className} ${detection.confidence}%`, topLeftX + 5, topLeftY - 5);
                  // ctx.fillText(`Id: ${id} -  ${detection.className}`, topLeftX + 5, topLeftY - 5);
                  ctx.fillText(`${id}`, topLeftX + 5, topLeftY - 5);
                  ctx.fill
                  //Meto las detecciones para mostrarlas
                  dataForShow.push({
                    id: id,
                    name: detection.className,
                    confidence: detection.confidence,
                    equipment: detection.equipment ? detection.equipment.map(i => ({ name: i.class, confidence: i.confidence })) : []
                  })

                  id = id + 1;
                }



              })



            })


          }
        }
        ctx?.closePath()
      }


    }

    this.detectionData = dataForShow;
  }

  drawImages(data: ResponseCamera) {
    data.tasks.forEach((task) => {

      if (task.images) {


        const canvasContainer = document.getElementById('canvas-container');


        const canvasCreated = document.getElementById('div-' + task.name)
        if (canvasContainer && canvasCreated) {
          // elimino el canvas si existe para añadir datos nuevos
          canvasContainer.removeChild(canvasCreated)
        }


        const div = this.renderer.createElement('div');
        this.renderer.setAttribute(div, 'id', 'div-' + task.name)
        this.renderer.setAttribute(div, 'class', "canvas-container-data")
        this.renderer.appendChild(canvasContainer, div);

        let p = this.renderer.createElement('p');
        this.renderer.setAttribute(p, "id", 'p-' + task.name)

        this.renderer.appendChild(div, p);
        //No carga el texto, de esta manera sí
        let pm = document.getElementById('p-' + task.name)
        if (pm) {
          if (task.metadatas[0]) {
            pm.innerHTML = task.name + "  -  " + new Date(task.metadatas[0].created_at).toLocaleString("en-GB", {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })
          } else {
            pm.innerHTML = task.name
          }

        }

        let button = this.renderer.createElement('button');
        this.renderer.setAttribute(button, "class", "detailButton");
        button.addEventListener('click', () => this.openDialog(task));
        button.innerHTML = "Detalle"
        this.renderer.appendChild(div, button);

        const canvas = this.renderer.createElement('canvas');

        this.renderer.setAttribute(canvas, "id", task.name)
        this.renderer.setAttribute(canvas, "Title", task.name)
        this.renderer.setAttribute(canvas, "class", "canvas-image-grid")
        this.renderer.setAttribute(canvas, 'id', task.name);
        this.renderer.appendChild(div, canvas);




        const ctx = canvas.getContext('2d')

        if (ctx) {
          const newWidth = 640; // Nuevo ancho del canvas
          const newHeight = 360; // Nuevo alto del canvas

          // Ajustar el ancho y el alto del canvas
          canvas.width = newWidth;
          canvas.height = newHeight;

          //imagen seleccionada
          let imageSelected = task.images.filter(img => img.name.toLowerCase().includes(".jpeg") && img.name.toLowerCase().startsWith("area"))[0]
          var img = new Image();
          img.crossOrigin = "anonymous";
          if (imageSelected && imageSelected.url && imageSelected.name.includes(".jpeg")) {

            //  img.src = this.proxyImageUrl+'image/?url='+encodeURIComponent(imageSelected.url);
            //  task.images.filter(img => img.name.toLowerCase().includes(".jpeg"))[0].proxy=img.src
            this.cameraService.getImageFromProxy(imageSelected.url).subscribe((res) => {

              if (res['type'] != 'text/plain') {
                img.src = URL.createObjectURL(res)
                task.images.filter(img => img.name.toLowerCase().includes(".jpeg"))[0].proxy = img.src
              } else {
                img.src = this.noimage
                task.images.filter(img => img.name.toLowerCase().includes(".jpeg"))[0].proxy = img.src
              }

            }, (error) => {
              console.log(error)
              img.src = this.noimage
              task.images.filter(img => img.name.toLowerCase().includes(".jpeg"))[0].proxy = img.src
            })


            img.onload = function () {

              var scale = Math.min(canvas.width / img.width, canvas.height / img.height);
              var newWidth = img.width * scale;
              var newHeight = img.height * scale;
              var x = (canvas.width - newWidth) / 2;
              var y = (canvas.height - newHeight) / 2;

              ctx.drawImage(img, x, y, newWidth, newHeight);
              ctx?.closePath()
            }

          } else {
            img.src = this.noimage
            img.onload = function () {
              //se adapta la imagen al canvas
              var scale = Math.min(canvas.width / img.width, canvas.height / img.height);
              var newWidth = img.width * scale;
              var newHeight = img.height * scale;
              var x = (canvas.width - newWidth) / 2;
              var y = (canvas.height - newHeight) / 2;

              ctx.drawImage(img, x, y, newWidth, newHeight);
              ctx?.closePath()
            }
          }

        }
      }
    })
  }
  _arrayBufferToBase64(buffer: any) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);

  }
}




