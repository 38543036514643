<mat-card>
  <div class="sensor-title">
    <button mat-raised-button class="back" color="primary" [mat-dialog-close]="true">
      <span class="material-icons"> arrow_back </span>
    </button>
    <h1>{{sensorType}} Info</h1>


  </div>
  <div>
    <div class="content">
      <div class="card panel" style="width: 800px;">
        <iframe width="100%" height="200px" frameBorder="0" [src]="grafanaUrlSafe"></iframe>
      </div>
    </div>
  </div>
  <!--<div class="sensor-detail-container">

    <div class="content">
      <div class="card">
        <div i18n class="title"> Id: </div>
        <div class="value"> {{ sensor.id }} </div>
      </div>

      <div class="card">
        <div i18n class="title"> Signal: </div>
        <div class="value">
          <span class="material-icons connected" *ngIf="sensor.signal === 'connected'"> sensors </span>
          <span class="material-icons disconnected" *ngIf="sensor.signal === 'disconnected'"> sensors </span>
          <span class="material-icons signal_lost" *ngIf="sensor.signal === 'signal_lost'"> sensors </span>
        </div>
      </div>

      <div class="card" *ngFor="let sensorView of dataView">
        <div i18n class="title"> {{sensorView.label}} </div>
        <div class="value"> {{sensorView.value}} </div>
      </div>
    </div>
  </div>-->
  <!--<div>
    <button i18n class="disconnect-button" mat-flat-button color="primary" (click)="changeSignal()"
      *ngIf="sensor.signal !== 'signal_lost' && sensor.signal === 'connected'">
      Disconnect
    </button>
    <button i18n class="disconnect-button" mat-flat-button color="primary" (click)="changeSignal()"
      *ngIf="sensor.signal !== 'signal_lost' && sensor.signal === 'disconnected'">
      Connect
    </button>
  </div>-->
</mat-card>
