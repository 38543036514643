import { CriteriaNotificationsFilter } from "../interfaces/criteria-notifications-filter";
import { CriteriaAbs } from "./criteria-abs";
import { Notification } from './notification';

export class CriteriaLevelNoRead extends CriteriaAbs implements CriteriaNotificationsFilter {
  getNotificationsWithFilter(): Promise<Notification[]> {
    return new Promise<Notification[]>((resolve, reject) => {
      this.notificationService.getNotificationsLevelBeforeDate().subscribe((result) => {
        resolve(result);
      });
    });
  }
}
