import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { User } from '../models/user';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl: string = environment.backendUrl;
  private user: User = new User()
  constructor(private http: HttpClient,
    public router: Router
  ) { }
  /**
  Está authenticado cuando haya un refresh_token en las cookies, si llegase a salar un 401, intentará el refresh 
  **/
  isAuthenticatedUser(): boolean {
    // const user_data = this.getCookie("user_data")
    // const refresh_token = this.getCookie("refresh_token")
    // if (user_data && user_data != "" && user_data.length > 0
    //   && refresh_token && refresh_token != "" && refresh_token.length > 0
    // ) {
    //   return true
    // } else {
    //   return false
    // }
    if(this.user && this.user.getid()!=""){
      return true
    }else{
      return false;
    }

  }
  setUser(user: any) {
    let newUser = new User(user['id'], user['username'], user['email'])
    this.user = newUser
  }
  public getUser(): User {
    return this.user;
  }
  /**
   * Refresca el token de acceso llamando a la API de refresco de tokens.
   * @returns Un observable con la respuesta de la API.
   */
  refreshAccessToken(user_id: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}auth/refresh`, {
      id: user_id,
      refresh_token: this.getCookie("refresh_token")
    });
  }

  /**
   * Realiza el inicio de sesión del usuario y almacena el token de acceso en una cookie.
   * @param credentials Las credenciales del usuario para iniciar sesión.
   * @returns Un observable con la respuesta de la API.
   */
  login(credentials: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}auth/login`, credentials)
  }

  /**
   * Realiza el cierre de sesión del usuario y limpia lso token en cookies y en ddbb.
   * @returns Un observable con la respuesta de la API.
   */
  logout(): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}auth/logout`, { id: this.user.getid() })
  }

  /**
   * Establece una cookie con el nombre, valor y número de días de expiración especificados.
   * @param name El nombre de la cookie.
   * @param value El valor de la cookie.
   * @param minutes El número de minutos hasta que la cookie expire.
   */
  public setCookie(name: string, value: string, minutes: number): void {
    //Sumo 60 más los minutos ya que utcstring crea la fecha con una hora menos
    const expires = new Date(Date.now() + (minutes) * 60 * 1000).toUTCString();
    const cookieOptions = {
      path: '/',
      sameSite: 'Strict',
    };
  
    const cookieString = [
      `${name}=${value}`,
      `expires=${expires}`,
      ...Object.entries(cookieOptions)
        .filter(([, value]) => Boolean(value))
        .map(([key, value]) => `${key}=${value}`),
    ].join('; ');


    document.cookie = cookieString;
  }

  getCookie(name: string): string | undefined {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop()?.split(';').shift();
    }
    return undefined; // Asegura que siempre se retorne un valor
  }

  /**
 * Elimina una cookie estableciendo su valor a una cadena vacía y su fecha de expiración en el pasado.
 * @param name El nombre de la cookie a eliminar.
 */
  deleteCookie(name: string): void {
    document.cookie = name + '=; Max-Age=0'
  }

  public removeTokensAnddata() {

    //Si se llama a este metodo hay que borrar si se cumple alguno
    // solo llama en el interceptor y en lugares donde sabes que algo ha salido mal y hay que forzar el cierre

    this.deleteCookie("user_data")
    this.deleteCookie("refresh_token")
    this.deleteCookie("access_token")
    let newUser = new User()
    this.setUser(newUser)


  }

}
