import { Component, Inject, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AirQuality } from 'src/app/models/zamakona/airQuality';
import { Subsensor } from 'src/app/models/zamakona/paneles/subSensor';
import { WaterQuality } from 'src/app/models/zamakona/waterQuality';
import { WeatherStation } from 'src/app/models/zamakona/weatherStation';
import { ZamakonaRealTimeData } from 'src/app/models/zamakona/zamakonaRealTime';
import { ZamakonaRealTimeValue } from 'src/app/models/zamakona/zamakonaRealTimeValues';
import { AirQualityService } from 'src/app/services/zamakona/airQuality.service';
import { WaterQualityService } from 'src/app/services/zamakona/waterQuality.service';
import { WeatherStationService } from 'src/app/services/zamakona/weatherStation.service';

export interface DialogData {
  title: string;
  legend: string;
}

@Component({
  selector: 'app-zamakona-sensor-real-time-dashboard',
  templateUrl: './zamakona-sensor-real-time-dashboard.component.html',
  styleUrls: ['./zamakona-sensor-real-time-dashboard.component.scss'],
})

export class ZamakonaSensorRealTimeDashboardComponent implements OnDestroy {

  sensor!: { id: string, name: string };
  sensorData!: ZamakonaRealTimeData;
  backupSensorData!: ZamakonaRealTimeData;
  selectedSensorTitle: string = "";
  selectedSensorLegend: string = "";
  interval: any;
  isObservations = true;
  @ViewChild('dialogTemplate') dialogTemplate!: TemplateRef<any>;
  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private airQualityService: AirQualityService,
    private waterQualityService: WaterQualityService,
    private weatherStationService: WeatherStationService,
    public dialog: MatDialog) {
    let urlString = "";

    const state = this.router.getCurrentNavigation()?.extras.state;

    if (state && state.historicData) {

      this.sensor = state.historicData.sensor;

      if (this.sensor instanceof AirQuality) {
        this.sensorData = ZamakonaRealTimeData.proccessDataAirQuality(this.sensor)
        this.backupSensorData = ZamakonaRealTimeData.proccessDataAirQuality(this.sensor)

      } else if (this.sensor instanceof WaterQuality) {
        this.sensorData = ZamakonaRealTimeData.proccessDataWaterQuality(this.sensor);
        this.backupSensorData = ZamakonaRealTimeData.proccessDataWaterQuality(this.sensor);

      } else if (this.sensor instanceof WeatherStation) {
        this.sensorData = ZamakonaRealTimeData.proccessDataWeatherStation(this.sensor);
        this.backupSensorData = ZamakonaRealTimeData.proccessDataWeatherStation(this.sensor);

      }

      if (this.isObservations) {
        this.sensorData.values = this.filterObservationValues(this.backupSensorData.values);
      } else {
        this.sensorData.values = this.filterControlValues(this.backupSensorData.values);
      }

      this.sensorData.values.sort((a, b) => a.order - b.order)

    } else {
      this.router.navigate(['/zamakona-sensors']);
    }
    
  }
  ngOnDestroy(): void {
    clearInterval(this.interval)
  }


  ngOnInit(): void {
    this.interval = setInterval(() => {
      if (this.sensor instanceof AirQuality) {
        this.loadAllAirQuialitySensors();
      } else if (this.sensor instanceof WaterQuality) {
        this.loadAllWaterQuialitySensors();
      } else if (this.sensor instanceof WeatherStation) {
        this.loadAllWeatherQuialitySensors();
      }
    
    }, 60000);
  }

  private loadAllAirQuialitySensors(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.airQualityService.getAll().subscribe((result) => {

        this.sensorData = ZamakonaRealTimeData.proccessDataAirQuality(result[0]);
        this.sensorData.values.sort((a, b) => a.order - b.order)
        this.backupSensorData = ZamakonaRealTimeData.proccessDataAirQuality(result[0]);
        this.backupSensorData.values.sort((a, b) => a.order - b.order)
        // Filtrar los valores según sea necesario
        if (this.isObservations) {
          this.sensorData.values = this.filterObservationValues(this.sensorData.values);
        } else {
          this.sensorData.values = this.filterControlValues(this.sensorData.values);
        }
        resolve();
      }, (error) => {
        reject(error);
      });
    });
    return promise;
  }

  private loadAllWaterQuialitySensors(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.waterQualityService.getAll().subscribe((result) => {
        this.sensorData = ZamakonaRealTimeData.proccessDataWaterQuality(result[0]);
        this.sensorData.values.sort((a, b) => a.order - b.order)
        this.backupSensorData = ZamakonaRealTimeData.proccessDataWaterQuality(result[0]);
        this.backupSensorData.values.sort((a, b) => a.order - b.order)
        // Filtrar los valores según sea necesario
        if (this.isObservations) {
          this.sensorData.values = this.filterObservationValues(this.sensorData.values);
        } else {
          this.sensorData.values = this.filterControlValues(this.sensorData.values);
        }
        resolve();
      }, (error) => {
        reject(error);
      });
    });
    return promise;
  }

  private loadAllWeatherQuialitySensors(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.weatherStationService.getAll().subscribe((result) => {
        this.sensorData = ZamakonaRealTimeData.proccessDataWeatherStation(result[0]);
        this.sensorData.values.sort((a, b) => a.order - b.order)
        this.backupSensorData = ZamakonaRealTimeData.proccessDataWeatherStation(result[0]);
        this.backupSensorData.values.sort((a, b) => a.order - b.order)
        // Filtrar los valores según sea necesario
        if (this.isObservations) {
          this.sensorData.values = this.filterObservationValues(this.sensorData.values);
        } else {
          this.sensorData.values = this.filterControlValues(this.sensorData.values);
        }
        resolve();
      }, (error) => {
        reject(error);
      });
    });
    return promise;
  }



  openDialog(title: string, legend: string) {
    this.selectedSensorTitle = title;
    this.selectedSensorLegend = legend;
    this.dialog.open(this.dialogTemplate, {
      width: '500px'
    });
  }

  closeDialog() {
    this.selectedSensorTitle = "";
    this.selectedSensorLegend = "";
    this.dialog.closeAll();
  }

  isSpeedMeter(description: string): boolean {
    return description.toLowerCase().includes("velocidad") ? true : false;
  }
  isCompass(description: string): boolean {
    return (description.toLowerCase().includes("dirección") || description.toLowerCase().includes("compás")) ? true : false;
  }

  onGroupChange(event: any) {
    this.isObservations = event == "Observaciones" ? true : false;

    if (this.isObservations) {
      this.sensorData.values = this.filterObservationValues(this.backupSensorData.values);
    } else {
      this.sensorData.values = this.filterControlValues(this.backupSensorData.values);
    }

    this.sensorData.values.sort((a, b) => a.order - b.order)

  }

  private filterObservationValues(values: ZamakonaRealTimeValue[]): ZamakonaRealTimeValue[] {
    return values.filter(value => value.observation && !value.control);
  }

  private filterControlValues(values: ZamakonaRealTimeValue[]): ZamakonaRealTimeValue[] {
    return values.filter(value => value.control && !value.observation);
  }

  showDate(date:string):string{
   return date
  }
}
