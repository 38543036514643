import { AirQuality } from "./airQuality";
import { ZamakonaSensor } from "./parent/zamakonaSensor";
import { WaterQuality } from "./waterQuality";
import { WeatherStation } from "./weatherStation";
import { ZamakonaRealTimeValue } from "./zamakonaRealTimeValues";
const descipcionElementosNaturales = `Los siguientes elementos se miden de acuerdo al límite inferior de explosividad (LeL), que son diferentes para cada gas o vapor. Esta es la concentración mínima de un gas o vapor en el aire por debajo de la cual no se propaga la llama en presencia de una fuente de ignición, como una chispa o una llama. Estos son algunos ejemplos de valores LEL para gases comunes (expresados como porcentaje del gas en el aire por volumen):

• Metano (CH4): 5%
• Propano (C3H8): 2,1%
• Hidrógeno (H2): 4%
• Etileno (C2H4): 2,7%
• Acetileno (C2H2): 2,5%
• Gasolina: 1,4%
• Monóxido de Carbono (CO): 12,5%
`
export class ZamakonaRealTimeData {

    type: string;
    name: string;
    date: string;
    values: ZamakonaRealTimeValue[];

    private constructor(type: string, name: string, date: string, values: ZamakonaRealTimeValue[]) {
        this.type = type;
        this.name = name;
        this.date = date;
        this.values = values;
    }

    static proccessDataAirQuality(sensor: AirQuality): ZamakonaRealTimeData {

        let realTimeValues: ZamakonaRealTimeValue[] = [];
        realTimeValues.push(new ZamakonaRealTimeValue("Batería", sensor.battery.toFixed(0).toString(), "%", "0", false, true, 39, "Porcentaje de batería que dispone la estación de calidad del aire y ruido."));
        realTimeValues.push(new ZamakonaRealTimeValue("Humedad", sensor.humidity.toFixed(0).toString(), "%", "0", true, false, 40, "La humedad es un componente natural de la atmósfera y procede de la cantidad devapor de agua existente en el aire."));
        realTimeValues.push(new ZamakonaRealTimeValue("Hidrógeno", sensor.hydrogen.toFixed(1).toString(), "LEL/PPM", "0", true, false, 41, `Indica la cantidad de gas de hidrógeno por unidad de volumen. `+descipcionElementosNaturales));
        realTimeValues.push(new ZamakonaRealTimeValue("Metano", sensor.methane.toPrecision(4).toString(), "LEL/PPM", "0", true, false, 42, `Indica la cantidad de gas de metano por unidad de volumen. `+descipcionElementosNaturales));
        realTimeValues.push(new ZamakonaRealTimeValue("Monóxido de Carbono", sensor.monoxide.toFixed(1).toString(), "LEL/PPM", "0", true, false, 43, `Indica la cantidad de gas de monóxido de carbono por unidad de volumen. `+descipcionElementosNaturales));
        realTimeValues.push(new ZamakonaRealTimeValue("Niveles acústicos instantáneos", sensor.noiseFast.toFixed(1).toString(), "dBA", "0", true, false, 44,
            'Medición del nivel de ruido en un periodo de tiempo de 0,125sg. Esta medida (LZF) permite obtener un mejor análisis de sonidos cortos e instantáneos.'));
        realTimeValues.push(new ZamakonaRealTimeValue("Niveles acústicos en un intervalo de tiempo", sensor.noiseSlow.toFixed(1).toString(), "dBA", "0", true, false, 45,
            'Medición del nivel de ruido en un periodo de tiempo de 1sg. Este nivel permite obtener un análisis del nivel de ruido promedio, ignorando sonidos cortos y rápidos. Este tipo de medida (LAS) permite hacer un estudio del ruido ambiental.'));
        realTimeValues.push(new ZamakonaRealTimeValue("Presión", sensor.pressure.toFixed(1).toString(), "KPa", "0", true, false, 46,
            `La presión atmosférica es la fuerza por unidad de superficie que ejerce la atmósfera en un punto específico. Es la consecuencia de la acción de la fuerza de la gravedad sobre la columna de aire situada por encima de este punto.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Partículas PMX01 (PM1)", sensor.particlePmx01.toFixed(0).toString(), "µg/m3", "0", true, false, 47,
            `Las partículas con un diámetro inferior o igual a 1 micrómetro se llaman “partículas ultrafinas” o “nanopartículas”. Estas partículas representan el mayor riesgo para la salud, ya que pueden atravesar la barrera alvéolo-capilar, penetrar directamente en los pulmones a través de la circulación sanguínea y, por lo tanto, propagarse a los órganos, incluido el cerebro. Algunas fuentes de PM1 son naturales, como fragmentos de minerales y aerosoles del rocío marino. 1 Ejemplo:
        • emisiones de fábricas y otras actividades industriales
        • escape del vehículo
        • partículas de neumáticos del uso del vehículo
        • humo de incendios forestales o quema de madera en interiores`));
        realTimeValues.push(new ZamakonaRealTimeValue("Partículas PMX02 (PM2,5)", sensor.particlePmx02.toFixed(1).toString(), "µg/m3", "0", true, false, 48, `
        La materia particulada, o PM, se refiere a las partículas que se encuentran en el aire, incluyendo el polvo, el hollín, la suciedad, el humo y las gotas de líquido. Las partículas PM2.5 miden 2,5 micras o menos de diámetro. Las partículas PM2.5 son tan pequeñas que sólo pueden verse con un microscopio electrónico. (combustión de motores, combustión de centrales eléctricas, procesos industriales, Estufas, chimeneas y combustión de madera en el hogar, Humo de fuegos artificiales, fumar)`));
        realTimeValues.push(new ZamakonaRealTimeValue("Partículas PMX10 (PM10)", sensor.particlePmx03.toFixed(0).toString(), "µg/m3", "0", true, false, 49, `
        Las PM10 se pueden definir como aquellas partículas sólidas o líquidas de polvo, cenizas,hollín, partículas metálicas, cemento o polen, dispersas en la atmósfera, y cuyo diámetro varía entre 2,5 y 10 µm (1 micrómetro corresponde la milésima parte de 1 milímetro). Están formadas principalmente por compuestos inorgánicos como silicatos y aluminatos, metales pesados entre otros, y material orgánico asociado a partículas de carbono (hollín). Se caracterizan por poseer un pH básico debido a la combustión no controlada de materiales.
        `));

        return new this(sensor.type, sensor.name, sensor.dateModified, realTimeValues);
    }

    static proccessDataWaterQuality(sensor: WaterQuality): ZamakonaRealTimeData {

        let realTimeValues: ZamakonaRealTimeValue[] = [];
        realTimeValues.push(new ZamakonaRealTimeValue("Batería", sensor.battery.toString(), "%", "0", false, true, 50, `Porcentaje de batería que dispone la estación de calidad del agua.`));

        realTimeValues.push(new ZamakonaRealTimeValue("Conductividad", sensor.conductivity9487.toFixed(2).toString(), "mS/cm", "0", true, false, 51, `La conductividad es la capacidad para conducir la electricidad, esta aumenta cuando la solución contiene electrolitos.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Conductividad no compensada", sensor.conductivityNotCompensated9487.toFixed(1).toString(), "mS/cm", "0", true, false, 52, ` La conductividad es capacidad para conducir la electricidad, esta aumenta cuando la solución contiene electrolitos. Esta medida se obtiene sin la influencia de la temperatura.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Salinidad", sensor.salinity9487.toFixed(2).toString(), "ppt", "0", true, false, 53, `La salinidad es una medida de la cantidad de sales disueltas en agua. La salinidad y la conductividad están relacionadas porque la cantidad de iones disueltos aumentan los valores de ambas.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Oxígeno MGL", sensor.pOxygenMgl9488.toFixed(2).toString(), "mg/L", "0", true, false, 54, "La cantidad de oxígeno disuelto en el agua."));
        realTimeValues.push(new ZamakonaRealTimeValue("Oxígeno PPM", sensor.pOxygenPpm9488.toFixed(2).toString(), "ppm", "0", true, false, 55, "La cantidad de oxígeno disuelto en el agua."));
        realTimeValues.push(new ZamakonaRealTimeValue("Saturación de oxígeno", sensor.pOxygenSat9488.toFixed(2).toString(), "%", "0", true, false, 56, `La saturación de oxígeno en el agua es la cantidad de oxigeno gaseoso que esta disuelto en el agua.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Temperatura 9488", sensor.pTemperature9488.toFixed(2).toString(), "ºC", "0", true, false, 57, `La temperatura es una magnitud física escalar que está relacionada con la energía interna de un sistema termodinámico, concretamente con la energía cinética media de sus partículas, referida al movimiento de éstas.
`));
        realTimeValues.push(new ZamakonaRealTimeValue("pH", sensor.pPh9485.toFixed(2).toString(), "pH", "0", true, false, 58, `El nivel de pH, mide el grado de acidez o alcalinidad de una sustancia o solución. El pH se mide en una escala de 0 a 14. En esta escala, un valor pH de 7 es neutro, lo que significa que la sustancia o solución no es ácida ni alcalina.`));
        realTimeValues.push(new ZamakonaRealTimeValue("pH HMV", sensor.pPhehtHMV9485.toFixed(2).toString(), "mV", "0", false, true, 59,`El nivel de pH, mide el grado de acidez o alcalinidad de una sustancia o solución.Se mide en milivoltios (mV)`));
        realTimeValues.push(new ZamakonaRealTimeValue("Redox", sensor.pRedox9485.toFixed(1).toString(), "mV", "0", false, true, 60, `Es una medida efectiva de medir la energía química de oxidación-reducción mediante un electrodo, convirtiéndola en energía eléctrica, la cual se utiliza para conocer el saneamiento del agua, se expresa en mili voltios – mV – y nos informa sobre el potencial de oxidación o de reducción.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Temperatura 9485", sensor.pTemperature9485.toFixed(2).toString(), "ºC", "0", true, false, 61, `La temperatura es una magnitud física escalar que está relacionada con la energía interna de un sistema termodinámico, concretamente con la energía cinética media de sus partículas, referida al movimiento de éstas.
`));
        realTimeValues.push(new ZamakonaRealTimeValue("Temperatura 9387", sensor.temperature9487.toFixed(2).toString(), "ºC", "0", true, false, 62, `La temperatura es una magnitud física escalar que está relacionada con la energía interna de un sistema termodinámico, concretamente con la energía cinética media de sus partículas, referida al movimiento de éstas.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Temperatura 9353", sensor.pxTemperature9353.toFixed(2).toString(), "ºC", "0", true, false, 62, `La temperatura medida a partir de la sonda de turbidez, esta magnitud está relacionada con la energía interna de un sistema termodinámico, concretamente con la energía cinética media de sus partículas, referida al movimiento de éstas.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Nivel de turbidez MGL", sensor.pxTurbidityMgl9353.toFixed(2).toString(), "mg/L", "0", false, true, 63 ,`La turbidez es una medida de la cantidad de partículas en suspensión en el agua. La NTU es la abreviación de Nephelometric Turbidity Unit , y es la unidad en la que se mide la turbidez de un fluido o la presencia de partículas en suspensión en el agua, cuantos más sólidos en suspensión haya en el agua, más sucia parecerá esta y más alta será la turbidez.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Nivel de turbidez NTU", sensor.pxTurbidityNtu9353.toFixed(2).toString(), "NTU", "0", true, false, 64, `La turbidez es una medida de la cantidad de partículas en suspensión en el agua. La NTU es la abreviación de Nephelometric Turbidity Unit , y es la unidad en la que se mide la turbidez de un fluido o la presencia de partículas en suspensión en el agua, cuantos más sólidos en suspensión haya en el agua, más sucia parecerá esta y más alta será la turbidez`));


        return new this(sensor.type, sensor.name, sensor.dateModified, realTimeValues);
    }

    static proccessDataWeatherStation(sensor: WeatherStation): ZamakonaRealTimeData {

        let realTimeValues: ZamakonaRealTimeValue[] = [];
        realTimeValues.push(new ZamakonaRealTimeValue("Batería", sensor.battery.toString(), "%", "0", false, true, 0, `Porcentaje de batería que dispone la estación meteorológica.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Humedad absoluta", sensor.absoluteHumidity.toFixed(2).toString(), "g/m3", "0", true, false, 0, `La humedad absoluta es la cantidad de vapor de agua (gr) contenido en un determinado volumen de aire (m3). Así pues la humedad absoluta la mediremos en gramos de vapor de agua por metro cúbico de aire.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Densidad del aire", sensor.airDensity.toFixed(2).toString(), "Kg/m3", "0", true, false, 0, `La densidad del aire, es la masa de aire por unidad de volumen de la atmósfera terrestre. Es una variable que decrece con la altitud. A nivel del mar y 20°C de temperatura la densidad del aire es aproximadamente 1.2 kg/m3.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Dirección media del viento", sensor.avgWindDirection.toFixed(0).toString(), "º", "0.05", true, false, 0,
            `Dirección media de donde viene el viento, en los diez minutos anteriores a la hora indicada, en relación a los 8 rumbos principales: norte-noreste (NNE), este-noreste (ENE), este-sureste (ESE), sur-sureste (SSE), sur-suroeste (SSO), oeste-suroeste (OSO), oeste-noroeste (ONO) y norte-noroeste (NNO).`));
        realTimeValues.push(new ZamakonaRealTimeValue("Velocidad media del viento", sensor.avgWindSpeed.toFixed(2).toString(), "m/s", "0.01", true, false, 0, `La velocidad media del viento es la media estadística de los valores instantáneos de la velocidad del viento, durante un intervalo de tiempo determinado.
        `));
        realTimeValues.push(new ZamakonaRealTimeValue("Dirección media de ráfagas de viento", sensor.avgWindGustDirection.toFixed(0).toString(), "º", "0.05", true, false, 0, `Dirección media de donde viene el viento, en los diez minutos anteriores a la hora indicada, en relación a los 8 rumbos principales con respecto a la velocidad máxima del viento calculada en cada intervalo de tiempo.
        `));
        realTimeValues.push(new ZamakonaRealTimeValue("Velocidad media de ráfagas de viento", sensor.avgWindGustSpeed.toFixed(2).toString(), "m/s", "0.01", true, false, 0, `La velocidad media de ráfaga es la velocidad media estadísticas de los valores instántaneos máximo en un periodo de tiempo determinado.`));
        // realTimeValues.push(new ZamakonaRealTimeValue("Dirección media del viento corregida", sensor.avgCorrectedWindDirection.toFixed(0).toString(), "º","0.05",'La velocidad del viento corregida se obtiene multiplicando la velocidad del viento por la fracción (densidad del aire AEP) / (curva de potencia de la densidad del aire) a la potencia 1/3.'));
        realTimeValues.push(new ZamakonaRealTimeValue("Compás", sensor.compass.toFixed(0).toString(), "º", "0", false, true, 0));
        realTimeValues.push(new ZamakonaRealTimeValue("Dirección del viento corregida", sensor.corectedWindDirection.toFixed(0).toString(), "º", "0.05", true, false, 0,
            `La dirección media del viento corregida, es la dirección media estadística de los valores instantáneos desde donde sopla el viento, teniendo en cuenta la temperatura y la presión en la que es tomada la medida.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Punto de rocío", sensor.dewPoint.toFixed(1).toString(), "°C", "0", true, false, 0, `El punto de rocío es la temperatura a la cual se debe enfriar el aire para que el vapor de agua se condense en rocío o escarcha.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Índice de calor", sensor.heatIndex.toFixed(1).toString(), "°C", "0", false, true, 0, `El índice de calor, también conocido como temperatura aparente, es lo que siente el cuerpo humano cuando la humedad relativa se combina con la temperatura del aire.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Intensidad de precipitación", Number.parseFloat(sensor.precipIntensity).toFixed(1).toString(), "mm", "0", false, true, 0,
            `Es la razón de incremento de la altura que alcanza la lluvia respecto al tiempo. Se clasifica en ligera, moderada y fuerte según se observa en la tabla. Criterios para determinar la intensidad de la lluvia:

- Ligera (2.5 mm/h o menos):
    Las gotas son fácilmente identificables, unas de otras, las superficies expuestas secas tardan más de dos minutos en mojarse completamente.

- Moderada (2.5-7.5 mm/h):
    No se pueden identificar gotas individuales, los charcos se forman rápidamente. Las salpicaduras de la precipitación se observan hasta cierta altura del suelo o de otras superficies planas.

- Fuerte (más de 7.5 mm/h):
    La visibilidad es bastante restringida y las salpicaduras que se producen sobre la superficie se levantan varias pulgadas.
`));
        realTimeValues.push(new ZamakonaRealTimeValue("Estado precipitación", Math.abs(Number.parseFloat(sensor.precipStatus)).toFixed(0).toString(), "", "0", false, true, 0));
        realTimeValues.push(new ZamakonaRealTimeValue("Precipitación total acumulada", Number.parseFloat(sensor.precipTotal).toFixed(1), "mm", "0", false, true, 0, `La precipitación total es la cantidad de agua caída durante un cierto periodo de tiempo sobre una superficie determinada (mm/h).`));
        realTimeValues.push(new ZamakonaRealTimeValue("Nivel de presión", sensor.pressure.toFixed(1).toString(), "hPa", "0", false, true, 0));
        realTimeValues.push(new ZamakonaRealTimeValue("Presión a nivel del mar", sensor.pressureSeaLevel.toFixed(1).toString(), "hPa", "0", false, true, 0, `Medida de presión a nivel del mar.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Presión estación", sensor.pressureStation.toFixed(1).toString(), "hPa", "0", false, true, 0, `Medida de presión en hPa.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Humedad relativa", sensor.relativeHumidity.toFixed(0).toString(), "%", "0", true, false, 0, `La humedad relativa es cómo describimos cuánta humedad hay en el aire frente a la cantidad máxima que el aire puede contener a una temperatura determinada.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Radiación solar", sensor.solarRadiation.toFixed(0).toString(), "W/m2", "0", true, false, 0, `La radiación solar es la energía emitida por el sol en forma de radiación electromagnética que llega a la atmósfera. Se mide en superficie horizontal, mediante el sensor de radiación o piranómetro.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Hora de mediodía", sensor.sunNoonTime, "h", "0", true, false, 0, `Hora de mediodia.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Posición solar", sensor.sunPosition, "", "0", true, false, 0, `Posición del sol.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Hora de amanecer", sensor.sunRiseTime, "h", "0", true, false, 0, 'Hora de amanecer.'));
        realTimeValues.push(new ZamakonaRealTimeValue("Hora de atardecer", sensor.sunsetTime, "h", "0", true, false, 0, `Hora de atardecer.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Horas de luz solar", sensor.sunshineHours.toFixed(1).toString(), "h", "0", false, true, 0, `Horas de luz solar.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Nivel de tensión", sensor.supplyVoltage.toFixed(2).toString(), "V", "0", false, true, 0, `Medida de tensión a la que se encuentra la batería de la estación meteorológica.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Temperatura", sensor.temperature.toFixed(1).toString(), "°C", "0", false, true, 0, `La temperatura es una magnitud física escalar que está relacionada con la energía interna de un sistema termodinámico, concretamente con la energía cinética media de sus partículas, referida al movimiento de éstas.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Hora de crepúsculo astronómico", sensor.twilightAstonom, "h", "0", true, false, 0, `El crepúsculo astronómico ocurre cuando la oscuridad es casi completa y el horizonte no es discernible. Se puede ver la Vía Láctea y se pueden observar a simple vista las estrellas y planetas débiles a menos que la Luna ilumine el cielo. Sin embargo, las galaxias, nebulosas y cúmulos  globulares requieren oscuridad total cuando el Sol está a más de 18° por debajo del horizonte.
        `));
        realTimeValues.push(new ZamakonaRealTimeValue("Hora de crepúsculo civil", sensor.twilightCivil, "h", "0", true, false, 0, `El crepúsculo civil se produce dos veces al día: Por la mañana, comienza cuando el Sol está a -6º de elevación y termina tan pronto como comienza la salida de Sol. Por la tarde, comienza tan pronto como termina la puesta de Sol y termina cuando el Sol está a -6º sobre el horizonte.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Hora de crepúsculo náutico", sensor.twilightNautica, "h", "0", true, false, 0, ` La hora naútica del crepuesculo es el periodo de tiempo antes o después del crepúsculo náutico - el Sol está entre 12 y 18 grados por debajo del horizonte. En este periodo el cielo aún está poco iluminado por la luz solar dispersada en la atmósfera, y por eso no se ven las estrellas menos brillantes.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Temperatura del bulbo", sensor.wetBulbTemperature.toFixed(1).toString(), "°C", "0", false, true, 0, `La temperatura de bulbo húmedo es la temperatura más baja a la que se puede enfriar el aire mediante la evaporación del agua en el aire a una presión constante.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Sensación térmica", Number.parseFloat(sensor.windChill).toFixed(1).toString(), "°C", "0", false, true, 0, `Wind Chill es un término utilizado para describir cómo se siente la temperatura del aire en la piel humana debido a la combinación de temperaturas frías y vientos que soplan sobre la piel expuesta. En términos simples, cuanto más fría sea la temperatura del aire y mayor sea la velocidad del viento, más fría sentirá su piel si está afuera.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Dirección del viento", sensor.windDirection.toFixed(0).toString(), "º", "0.05", true, false, 0,
            `La dirección del viento hace referencia al punto del horizonte de un observador desde el que sopla el viento. Se indica de dónde viene, no a dónde se dirige, por ejemplo, el viento norte es aquel que sopla desde el norte hacia el sur; en este caso, también sería común decir que el viento es de componente norte. Se mide en grados, desde 0° hasta 360°, girando en el sentido de las agujas del reloj, a partir del norte geográfico. De esta forma, valores cercanos a 0° o 360° indican viento del norte, valores alrededor de 90° indican viento del este, alrededor de 180° del sur, y de 270° del oeste. Entre medias de estos valores tendremos las componentes noreste, sureste, suroeste y noroeste.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Estado sensor de viento", sensor.windSensorStatus=='0100'?'1':'0', "", "0", true, false, 0));
        realTimeValues.push(new ZamakonaRealTimeValue("Velocidad del viento", sensor.windSpeed.toFixed(2).toString(), "m/s", "0.01", true, false, 0, `La velocidad del viento mide la componente horizontal del desplazamiento del aire en un punto y en un instante determinado.`));
        realTimeValues.push(new ZamakonaRealTimeValue("Inclinación eje X", sensor.xTilt.toFixed(0).toString(), "º", "0", false, true, 0, `Orientación de la estación meteorológica en el eje X`));
        realTimeValues.push(new ZamakonaRealTimeValue("Inclinación eje Y", sensor.yTilt.toFixed(0).toString(), "º", "0", false, true, 0, `Orientación de la estación meteorológica en el eje Y`));
        realTimeValues.push(new ZamakonaRealTimeValue("Orientación eje Z", sensor.zOrient.toFixed(0).toString(), "º", "0", false, true, 0, `Orientación de la estación meteorológica en el eje Z.`));

        return new this(sensor.type, sensor.name, sensor.dateModified, realTimeValues);
    }

}