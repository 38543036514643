/* eslint-disable camelcase */

import { AirQuality } from "../airQuality";

export const mockAirQuality = AirQuality.createByJson({
  id: "00-04-a3-0b-01-03-a0-a3",
  type: "air_quality",
  battery: 88,
  chargingStatus: 1,
  dateCreated: "12/9/2023, 12:18:15",
  dateModified: "13/9/2023 13:05:43",
  description: "",
  humidity: 47.759765625,
  hydrogen: 0,
  methane: 0,
  monoxide: 0,
  name: "Estación de calidad de aire ",
  noiseFast: 69,
  noiseSlow: 69.400001526,
  particlePmx01: 0.829999983,
  particlePmx02: 4.940000057,
  particlePmx03: 12.679999352,
  pressure: 101510.96875,
  signal: "connected",
  lat: 28.15973186350595, 
  lng: -15.407801734084606
});
