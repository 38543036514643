<mat-grid-list class="dialog-header" cols="2" rowHeight="30px">
  <mat-grid-tile>
    <span *ngIf="isNew">Add Sensor</span>
    <span *ngIf="!isNew">Edit Sensor</span>
  </mat-grid-tile>
  <mat-grid-tile>
    <button (click)="closeDialog()">
      <span class="material-icons"> close </span>
    </button>
  </mat-grid-tile>
</mat-grid-list>
<div mat-dialog-content>
  <form autocomplete="off" class="sensor-form" (ngSubmit)="onSubmit()" [formGroup]="sensorForm">
    <input id="username" style="display:none" type="text" name="fakeusernameremembered">
    <input id="password" style="display:none" type="password" name="fakepasswordremembered">
    <mat-grid-list cols="2" rowHeight="60px">
      <mat-grid-tile>
        <mat-form-field class="id-sensor-container">
          <mat-label i18n>Id:</mat-label>
          <input matInput formControlName="id" [readonly]="!newSensor">
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field class="type-sensor-container">
          <mat-label i18n>Type:</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let type of typeList" [value]="type.value">
              {{ type.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field class="roomId-sensor-container">
          <mat-label i18n>Room:</mat-label>
          <mat-select formControlName="roomId">
            <mat-option *ngFor="let room of roomList" [value]="room.id">
              {{ room.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field class="name-sensor-container">
          <mat-label i18n>Name:</mat-label>
          <input matInput type="text" formControlName="name">
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field class="description-sensor-container">
          <mat-label i18n>Description:</mat-label>
          <input matInput type="text" formControlName="description">
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile *ngIf="typeField.value === 'switch'">
        <mat-form-field class="device-sensor-container">
          <mat-label i18n>Device 1:</mat-label>
          <mat-select formControlName="device1">
            <mat-option *ngFor="let device of deviceList" [value]="device.value">
              {{ device.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile *ngIf="typeField.value === 'switch'">
        <mat-form-field class="device-sensor-container">
          <mat-label i18n>Device 2:</mat-label>
          <mat-select formControlName="device2">
            <mat-option *ngFor="let device of deviceList" [value]="device.value">
              {{ device.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list><br>
    <button i18n mat-raised-button class="submit" color="primary" type="submit"
        [disabled]="!sensorForm.valid">Submit</button>
  </form>
</div>
