<mat-card>
  <div class="section-header">
    <h1 *ngIf="roomId === 'no-room'">Sensors</h1>
    <h1 *ngIf="roomId !== 'no-room'">Sensors in <span>{{ roomName }}</span></h1>
  </div>

  <div class="sensors-container">
    <button mat-raised-button class="new-sensor" color="primary" (click)="createSensor()">
      <span class="material-icons"> add_circle_outline </span>
      Add Sensor
    </button><br>
    
    <mat-grid-list style="width: 100%;" cols="2" rowHeight="80px">
      <mat-grid-tile>
        <div class="title-container">
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="search">
          <mat-form-field appearance="outline">
            <mat-label i18n>Type your sensor name</mat-label>
            <input type="text" placeholder="Search sensor" aria-label="Sensor" matInput [formControl]="searchControl"
              [matAutocomplete]="auto" (keydown.enter)="selectSensor(searchValue)">
              <mat-icon class="icon-search" (click)="selectSensor(searchValue)" matSuffix>search</mat-icon>
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <table mat-table [dataSource]="sensorList">
  
      <ng-container matColumnDef="name">
        <th i18n mat-header-cell *matHeaderCellDef> Sensor's name </th>
        <td mat-cell *matCellDef="let sensor"> {{ sensor.name }} </td>
      </ng-container>

      <ng-container matColumnDef="signal">
        <th i18n mat-header-cell *matHeaderCellDef> Signal </th>
        <td mat-cell *matCellDef="let sensor">
          <span class="material-icons connected" *ngIf="sensor.signal === 'connected'"> sensors </span>
          <span class="material-icons disconnected" *ngIf="sensor.signal === 'disconnected'"> sensors </span>
          <span class="material-icons signal_lost" *ngIf="sensor.signal === 'signal_lost'"> sensors </span>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="battery">
        <th i18n mat-header-cell *matHeaderCellDef> Battery </th>
        <td mat-cell *matCellDef="let sensor"> {{ sensor.battery ? sensor.battery : 'N/A' }} </td>
      </ng-container>
  
      <ng-container matColumnDef="options">
        <th i18n width="25%" mat-header-cell *matHeaderCellDef> Options </th>
        <td width="25%" mat-cell *matCellDef="let sensor">
          <button mat-flat-button color="primary" (click)="seeDetails(sensor.id, sensor.type)">
            <span class="material-icons"> visibility </span>
          </button>
          <button mat-flat-button color="primary" (click)="updateSensor(sensor)">
            <span class="material-icons"> edit </span>
          </button>
          <button mat-flat-button color="primary" (click)="deleteSensor(sensor.id, sensor.type)">
            <span class="material-icons"> delete </span>
          </button>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</mat-card>