import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-zamakona-sensor-compass',
  templateUrl: './zamakona-sensor-compass.component.html',
  styleUrls: ['./zamakona-sensor-compass.component.css']
})
export class ZamakonaSensorCompassComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() maxRange: string = "";
  @Input() minRange: string = "";
  @Input() currentRange: string = "";
  @Input() description: string = "description";
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef<HTMLCanvasElement>;
  constructor(private cdr: ChangeDetectorRef) { }
  ngAfterViewInit(): void {
    this.drawCompass();
    this.cdr.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentRange && !changes.currentRange.firstChange) {
      this.drawCompass();
      console.log(changes.cur)
    }
  }

  ngOnInit() {
  }

  drawCompass() {
    const canvas = this.canvasRef.nativeElement;
    const ctx = canvas.getContext('2d');
  
    if (ctx) {
      const centerX = canvas.width / 2.5;
      const centerY = canvas.height / 2;
      const radius = Math.min(centerX, centerY) - 40;
  
      // Limpiar el lienzo
      ctx.clearRect(0, 0, canvas.width, canvas.height);
  
      // Dibujar el círculo de la brújula
      ctx.beginPath();
      ctx.strokeStyle = 'rgba(0, 0, 139, 0.8)'; // Dark blue
      ctx.lineWidth = 4;
      ctx.arc(centerX, centerY, radius + 10, 0, 2 * Math.PI);
      ctx.stroke();
  
      // Dibujar la flecha de la brújula
      const angle = (parseFloat(this.currentRange) - 90) * Math.PI / 180; // Calcular el ángulo en radianes
      const arrowLength = radius - 10;
      const arrowX = centerX + Math.cos(angle) * arrowLength;
      const arrowY = centerY + Math.sin(angle) * arrowLength;
  
      ctx.beginPath();
      ctx.fillStyle = 'red';
      ctx.moveTo(centerX, centerY);
      ctx.lineTo(arrowX, arrowY);
      ctx.lineTo(centerX + Math.cos(angle + Math.PI / 2) * 10, centerY + Math.sin(angle + Math.PI / 2) * 10);
      ctx.closePath();
      ctx.fill();
  
      // Dibujar el indicador del rango actual
      ctx.beginPath();
      ctx.fillStyle = 'black';
      ctx.font = '28px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(`${this.currentRange}º`, centerX * 2, 40);
  
      // Dibujar los puntos cardinales
      const cardinalPoints = ['N', 'E', 'S', 'O']; // Norte, Este, Sur, Oeste
      const angleIncrement = Math.PI / 2; // 90 grados para cada punto cardinal
      const textRadius = radius + 30; // Radio para el texto de los puntos cardinales
  
      for (let i = 0; i < cardinalPoints.length; i++) {
        const pointAngle = (i * angleIncrement) - Math.PI / 2; // Ajustar el ángulo para que el norte esté arriba
        const pointX = centerX + Math.cos(pointAngle) * textRadius;
        const pointY = centerY + Math.sin(pointAngle) * textRadius;
  
        ctx.beginPath();
        ctx.fillStyle = 'rgba(0, 0, 139, 0.8)'; // Dark blue
        ctx.font = '16px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(cardinalPoints[i], pointX, pointY);
      }
  
      // Dibujar marcas menores y valores
      const minorMarkLength = 5;
      const minorTextRadius = radius ;
  
      for (let i = 0; i < 360; i += 15) {
        const minorAngle = (i - 90) * Math.PI / 180;
        const startX = centerX + Math.cos(minorAngle) * (radius + 10);
        const startY = centerY + Math.sin(minorAngle) * (radius + 10);
        const endX = centerX + Math.cos(minorAngle) * ((radius) - minorMarkLength);
        const endY = centerY + Math.sin(minorAngle) * ((radius) - minorMarkLength);
  
        ctx.beginPath();
        ctx.strokeStyle = 'rgba(0, 139, 0, 0.3)'; // Lighter blue
        ctx.lineWidth = 1;
        ctx.moveTo(startX, startY);
        ctx.lineTo(endX, endY);
        ctx.stroke();
  
        // Dibujar valores menores cada 30 grados
        if (i % 30 === 0) {
          const valueX = centerX + Math.cos(minorAngle) * minorTextRadius;
          const valueY = centerY + Math.sin(minorAngle) * minorTextRadius;
  
          ctx.beginPath();
          ctx.fillStyle = 'rgba(0, 0, 139, 1)'; // Dark blue
          ctx.font = '9px Arial';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(i.toString(), valueX, valueY);
        }
      }
      ctx?.closePath()
    }
  }
  
  
  
}


