<div class="dashboard-container" (window:resize)="onResize($event)">

  <div class="dashboard-filter-container">
    <div class="dashboard-title">
      <h1 i18n>Current Dashboard</h1>
    </div>
    <form class="dashboard-filter" (ngSubmit)="onSubmit()" [formGroup]="dashboardFilter">
      <mat-form-field class="building-dashboard-filter">
        <mat-label i18n>Building:</mat-label>
        <mat-select formControlName="buildingName">
          <mat-option *ngFor="let building of buildingList" [value]="building.name">{{ building.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="room-dashboard-filter">
        <mat-label i18n>Room:</mat-label>
        <mat-select formControlName="roomName">
          <mat-option *ngFor="let room of roomList" [value]="room.name">{{ room.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <button i18n mat-raised-button type="submit" color="primary"
        [disabled]="!buildingNameField.valid || !roomNameField.valid">Filter</button>
    </form>
  </div>

  <div class="dashboard" *ngIf="showDashboard">
    <div class="basic-info">
      <mat-grid-list [cols]="cols" rowHeight="150px" gutterSize="30px">
        <mat-grid-tile>
          <div class="main-card">
            <div class="image sensors">
              <span class="material-icons"> sensors </span>
            </div>
            <div class="data">
              <div i18n class="title avaliable_sensors">Avaliable Sensors</div>
              <p class="number">{{ avaliableSensor }}</p>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="main-card">
            <div class="image createdDate">
              <span class="material-icons"> today </span>
            </div>
            <div class="data">
              <div i18n class="title">Creation Date</div>
              <p class="date">{{ dateCreated.toLocaleDateString() }}</p>
              <p class="time">{{ dateCreated.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }}</p>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="main-card">
            <div class="image lastModified">
              <span class="material-icons"> event </span>
            </div>
            <div class="data">
              <div i18n class="title">Last Update</div>
              <p class="date">{{ lastUpdated.toLocaleDateString() }}</p>
              <p class="time">{{ lastUpdated.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }}</p>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="sensorList.length !== 0">
          <div class="main-card">
            <div class="image battery">
              <span class="material-icons"> battery_alert </span>
            </div>
            <div class="data">
              <div i18n class="title">Low Battery</div>
              <p class="number">{{ batteryAlert }} / {{ avaliableSensor - switches }}</p>
            </div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <div class="graphics-container" *ngIf="sensorList.length !== 0">
      <div class="second-section">
        <div class="secondary-card numbers" *ngIf="magnetics !== 0">
          <div class="number-info">
            <div class="icon-container door-window">
              <span class="material-icons"> sensor_door </span>
              <span class="material-icons"> window </span>
            </div>
            <div class="info-container">
              <div class="data-info">{{ doorsWindowsOpen }} / {{ magnetics }}</div>
              <div i18n class="description"> open / total</div>
            </div>
          </div>
          <div i18n class="graphic-title">
            Doors & Windows Open
          </div>
        </div>

        <div class="secondary-card numbers" *ngIf="switches !== 0">
          <div class="number-info">
            <div class="icon-container device">
              <span class="material-icons"> light </span>
              <span class="material-icons"> electrical_services </span>
            </div>
            <div class="info-container">
              <div class="data-info">{{ devicesOn }} / {{ totalDevices }}</div>
              <div i18n class="description"> on / total</div>
            </div>
          </div>
          <div i18n class="graphic-title">
            Devices Turned On
          </div>
        </div>

        <div class="secondary-card numbers" *ngIf="advancedAmbiences[2] !== 0 && isNumber(ambienceData[2])">
          <div class="number-info">
            <div class="icon-container activity">
              <span class="material-icons"> directions_walk </span>
              <span class="material-icons"> pets </span>
            </div>
            <div class="info-container">
              <div class="data-info">{{ ambienceData[2] }} / {{ advancedAmbiences[2] }}</div>
              <div i18n class="description"> detected / total</div>
            </div>
          </div>
          <div i18n class="graphic-title">
            Activity Detected
          </div>
        </div>
      </div>
      <div class="second-section">
        <div class="secondary-card graphics" *ngIf="advancedAmbiences[0] !== 0 && isNumber(ambienceData[0])">
          <div class="graphic">
            <rg-gauge-chart [canvasWidth]=canvasWidth [needleValue]="(ambienceData[0] + 20) * 100 / 80"
              [options]="optionsTemperature" [centralLabel]="ambienceData[0] + ''"></rg-gauge-chart>
          </div>
          <div i18n class="graphic-title">
            Temperature
            <p>(ºC)</p>
          </div>
        </div>

        <div class="secondary-card graphics" *ngIf="advancedAmbiences[1] !== 0 && isNumber(ambienceData[1])">
          <div class="graphic">
            <rg-gauge-chart [canvasWidth]=canvasWidth [needleValue]="ambienceData[1]" [options]="optionsHumidity"
              [centralLabel]="ambienceData[1] + ''"></rg-gauge-chart>
          </div>
          <div i18n class="graphic-title">
            Humidity
            <p>(%)</p>
          </div>
        </div>

        <div class="secondary-card graphics" *ngIf="advancedAmbiences[3] !== 0 && isNumber(ambienceData[3])">
          <div class="graphic">
            <rg-gauge-chart [canvasWidth]=canvasWidth [needleValue]="ambienceData[3] * 100 / 1500"
              [options]="optionsLight" [centralLabel]="ambienceData[3] + ''"></rg-gauge-chart>
          </div>
          <div i18n class="graphic-title">
            Light
            <p>(lux)</p>
          </div>
        </div>

        <div class="secondary-card graphics" *ngIf="advancedAmbiences[4] !== 0 && isNumber(ambienceData[4])">
          <div class="graphic">
            <rg-gauge-chart [canvasWidth]=canvasWidth [needleValue]="(ambienceData[4] - 400) * 100 / 2600"
              [options]="optionsCO2" [centralLabel]="ambienceData[4] + ''"></rg-gauge-chart>
          </div>
          <div i18n class="graphic-title">
            CO2
            <p>(ppm)</p>
          </div>
        </div>

        <div class="secondary-card graphics" *ngIf="advancedAmbiences[5] !== 0 && isNumber(ambienceData[5])">
          <div class="graphic">
            <rg-gauge-chart [canvasWidth]=canvasWidth [needleValue]="ambienceData[5] * 100 / 1500"
              [options]="optionsTVOC" [centralLabel]="ambienceData[5] + ''"></rg-gauge-chart>
          </div>
          <div i18n class="graphic-title">
            TVOC
            <p>(ppb)</p>
          </div>
        </div>

        <div class="secondary-card graphics" *ngIf="advancedAmbiences[6] !== 0 && isNumber(ambienceData[6])">
          <div class="graphic">
            <rg-gauge-chart [canvasWidth]=canvasWidth [needleValue]="(ambienceData[6] - 900) * 100 / 200"
              [options]="optionsBarPress" [centralLabel]="ambienceData[6] + ''"></rg-gauge-chart>
          </div>
          <div i18n class="graphic-title">
            Barometric Pressure
            <p>(hPA)</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>