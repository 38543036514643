export class Action {
  constructor(
      public id: string,
      public type: string,
      public roomId: string,
      public maxValues: { maxTemp: number, maxCo2: number, maxTvoc: number },
      public minValues: { minBattery: number, minTemp: number },
      public scheduleRestrictions: { light: number[], airConditioning: number[],
        heating: number[], camera: number[] },
      public dayRestrictions: { light: number[], airConditioning: number[],
        heating: number[], camera: number[] },
      public checkIntervals: { noActivity: { totalTime: number, interval: number },
        doorWindowOpen: { totalTime: number, interval: number } },
      public cooldowns: { battery: number, co2: number, tvoc: number },
      public eventsActivated: {
        activity: { light: boolean, airConditioning: boolean,
          heating: boolean, camera: boolean },
        temperature: { light: boolean, airConditioning: boolean,
          heating: boolean, camera: boolean },
        doorWindow: { light: boolean, airConditioning: boolean,
          heating: boolean, camera: boolean } },
  ) {}
}
