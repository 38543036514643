// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// backendUrl: "http://zamakona-smart.tcsa.es/back/",
//grafanaUrl: "http://zamakona-smart.tcsa.es/grafana/",
export const environment = {
  enableMock: false,
  production: true,
  backendUrl: "https://zamakona-smart.tcsa.es/back/",
  grafanaUrl: "https://zamakona-smart.tcsa.es/grafana/",
  proxyimageURL: "https://zamakona-smart.tcsa.es/proxy/",
  realTimePanelAirQuality: 'd-solo/c3cb8f16-efea-4c99-8b7e-c38b0633befb/calidad-de-aire-tiempo-real?orgId=1&from=1696471521873&to=1696493121873&theme=light&panelId=',
  realTimePanelWaterQuality: 'd-solo/c21ba019-62e0-47ee-8db2-1f0f658d2e20/calidad-de-agua-tiempo-real?orgId=1&refresh=5m&from=1697680021858&to=1697701621858&theme=light&panelId=',
  realTimePanelWeatherStation: 'd-solo/bdcc8770-ddd6-4b0c-a687-9ece6551e1a9/estacion-meteorologica?orgId=1&refresh=5m&from=1696471560873&to=1696493160873&theme=light&panelId=',
  historicDataPanel: '',
  language: 'es',
  frontendUrl: "http://localhost:7202",
  optionsTemperature: {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['#7281F3', '#83D0C3', '#BAF9B0', '#FFF395', '#FCB345', '#EA7075'],
    arcDelimiters: [37.5, 50, 62.5, 75, 87.5],
    rangeLabel: ['-20', '60'],
    needleStartValue: 20,
  },

  optionsHumidity: {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['#E2F4CA', '#ABEFB0', '#8BEAD3', '#5DABC8', '#5E6EA2'],
    arcDelimiters: [20, 40, 60, 80],
    rangeLabel: ['0', '100'],
    needleStartValue: 20,
  },

  optionsLight: {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['#41C2E9', '#9CD7EB', '#F5FCFE', '#FCF3A9', '#F9EC65', '#FF9A2E'],
    arcDelimiters: [10, 20, 38.33, 50, 66.67],
    rangeLabel: ['0', '1500'],
    needleStartValue: 20,
  },

  optionsCO2: {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['#8EE085', '#FFF395', '#FDC97B', '#F88A46', '#EA7075'],
    arcDelimiters: [15.4, 23.1, 42.3, 76.9],
    rangeLabel: ['400', '3000'],
    needleStartValue: 20,
  },

  optionsTVOC: {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['#8EE085', '#FFF395', '#f09b3d', '#EA7075'],
    arcDelimiters: [16.7, 35, 66.7],
    rangeLabel: ['0', '1500'],
    needleStartValue: 20,
  },

  optionsBarPress: {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['#4312F9', '#7281F3', '#83D0C3', '#BAF9B0', '#FFF395', '#FCB345', '#EA7075'],
    arcDelimiters: [14.286, 28.571, 42.857, 57.143, 71.429, 85.714],
    rangeLabel: ['900', '1100'],
    needleStartValue: 20,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
