import { Action } from './../models/action';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ActionService {
  private baseUrl: string = environment.backendUrl;

  constructor(
    private http: HttpClient,
  ) { }

  getAllActions(): Observable<Action[]> {
    const url: string = "action/getAll";
    return this.http.get<Action[]>(this.baseUrl + url);
  }

  getActionByRoomId(roomId: string): Observable<Action> {
    const url: string = "action/getByRoomId";
    return this.http.get<Action>(this.baseUrl + url + '?roomId=' + roomId);
  }

  updateAction(action: Action): Observable<Action> {
    const url: string = "action/update";
    return this.http
      .post<Action>(this.baseUrl + url, action);
  }
}
