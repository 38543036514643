/* eslint-disable indent */

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { map, startWith } from 'rxjs/operators';

import { AirQualityService } from 'src/app/services/zamakona/airQuality.service';
import { Basic } from 'src/app/models/basic';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { RoomService } from 'src/app/services/room.service';
import { SensorDialogComponent } from './sensor-dialog/sensor-dialog.component';
import { SensorService } from 'src/app/services/sensor.service';
import { WaterQualityService } from 'src/app/services/zamakona/waterQuality.service';
import { WeatherStationService } from 'src/app/services/zamakona/weatherStation.service';

@Component({
  selector: 'app-sensors',
  templateUrl: './sensors.component.html',
  styleUrls: ['./sensors.component.scss'],
})
export class SensorsComponent implements OnInit {
  roomId: string = "no-room";
  roomName: string = "";

  displayedColumns: string[] = ['name', 'signal', 'battery', 'options'];
  sensorList: Basic[] = [];

  searchControl = new FormControl();
  filteredOptions: Observable<string[]> | undefined;
  content: string[] = [];
  searchValue: string = '';

  constructor(
    private sensorService: SensorService,
    private airQuality: AirQualityService,
    private waterQuality: WaterQualityService,
    private weatherStationService: WeatherStationService,
    private roomService: RoomService,
    private sensorDialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) {
    this.roomId = this.route.snapshot.params.roomId;
  }

  ngOnInit(): void {
    this.roomId = this.route.snapshot.params.roomId;

    if (this.roomId !== "no-room") {
      this.roomService.getRoomById(this.roomId).subscribe((result) => {
        this.roomName = result.name;
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
      });
    }

    this.filteredOptions = this.searchControl.valueChanges
      .pipe(startWith(''), map((value) => this._filter(value)));

    this.sensorList = [];
    this.content = [];
    (this.roomId === 'no-room') ? this.loadAllSensors() : this.loadSensorsByRoom();
  }

  private _filter(value: string): string[] {
    this.searchValue = value;
    const filterValue = value.toLowerCase();
    return this.content.filter((option) => option.toLowerCase().includes(filterValue));
  }

  private async loadAllSensors(): Promise<void> {
    const promise = new Promise<void>(async (resolve, reject) => {
      await this.loadAllMagneticSensors();
      await this.loadAllAdvancedAmbienceSensors();
      await this.loadAllSwitchSensors();
      await this.loadAllAirQuialitySensors();
      await this.loadAllWaterQuialitySensors();
      await this.loadAllWeatherQuialitySensors();
      resolve();
    });
    return promise;
  }

  private loadAllMagneticSensors(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.sensorService.getAllMagnetics().subscribe((result) => {
        this.sensorList = this.sensorList.concat(result);
        const nameList: string[] = [];
        result.forEach((sensor) => nameList.push(sensor.name));
        this.content = this.content.concat(nameList);
        resolve();
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        reject(error);
      });
    });
    return promise;
  }

  private loadAllAdvancedAmbienceSensors(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.sensorService.getAllAdvancedAmbiences().subscribe((result) => {
        this.sensorList = this.sensorList.concat(result);
        const nameList: string[] = [];
        result.forEach((sensor) => nameList.push(sensor.name));
        this.content = this.content.concat(nameList);
        resolve();
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        reject(error);
      });
    });
    return promise;
  }

  private loadAllSwitchSensors(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.sensorService.getAllSwitches().subscribe((result) => {
        this.sensorList = this.sensorList.concat(result);
        const nameList: string[] = [];
        result.forEach((sensor) => nameList.push(sensor.name));
        this.content = this.content.concat(nameList);
        resolve();
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        reject(error);
      });
    });
    return promise;
  }

  private loadAllAirQuialitySensors(): Promise<void> {
    throw new Error('Method not implemented.');

    // const promise = new Promise<void>((resolve, reject) => {
    //   this.airQuality.getAll().subscribe((result) => {
    //     this.sensorList = this.sensorList.concat(result);
    //     const nameList: string[] = [];
    //     result.forEach((sensor) => nameList.push(sensor.name));
    //     this.content = this.content.concat(nameList);
    //     resolve();
    //   }, (error) => {
    //     this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    //     reject(error);
    //   });
    // });
    // return promise;
  }

  private loadAllWaterQuialitySensors(): Promise<void> {
    throw new Error('Method not implemented.');

    // const promise = new Promise<void>((resolve, reject) => {
    //   this.waterQuality.getAll().subscribe((result) => {
    //     this.sensorList = this.sensorList.concat(result);
    //     const nameList: string[] = [];
    //     result.forEach((sensor) => nameList.push(sensor.name));
    //     this.content = this.content.concat(nameList);
    //     resolve();
    //   }, (error) => {
    //     this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    //     reject(error);
    //   });
    // });
    // return promise;
  }

  private loadAllWeatherQuialitySensors(): Promise<void> {
    throw new Error('Method not implemented.');

    // const promise = new Promise<void>((resolve, reject) => {
    //   this.weatherStationService.getAll().subscribe((result) => {
    //     this.sensorList = this.sensorList.concat(result);
    //     const nameList: string[] = [];
    //     result.forEach((sensor) => nameList.push(sensor.name));
    //     this.content = this.content.concat(nameList);
    //     resolve();
    //   }, (error) => {
    //     this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    //     reject(error);
    //   });
    // });
    // return promise;
  }

  private loadSensorsByRoom(): Promise<void> {
    const promise = new Promise<void>(async () => {
      await this.loadMagneticSensorsByRoom();
      await this.loadAdvancedAmbienceSensorsByRoom();
      await this.loadSwitchSensorsByRoom();
      await this.loadAirQuialitySensorsByRoom();
      await this.loadWaterQuialitySensorsByRoom();
      await this.loadWeatherQuialitySensorsByRoom();
    });
    return promise;
  }

  private loadMagneticSensorsByRoom(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.sensorService.getMagneticByRoomId(this.roomId).subscribe((result) => {
        this.sensorList = this.sensorList.concat(result);
        const nameList: string[] = [];
        result.forEach((sensor) => nameList.push(sensor.name));
        this.content = this.content.concat(nameList);
        resolve();
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        reject(error);
      });
    });
    return promise;
  }

  private loadAdvancedAmbienceSensorsByRoom(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.sensorService.getAdvancedAmbienceByRoomId(this.roomId).subscribe((result) => {
        this.sensorList = this.sensorList.concat(result);
        const nameList: string[] = [];
        result.forEach((sensor) => nameList.push(sensor.name));
        this.content = this.content.concat(nameList);
        resolve();
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        reject(error);
      });
    });
    return promise;
  }

  private loadSwitchSensorsByRoom(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.sensorService.getSwitchByRoomId(this.roomId).subscribe((result) => {
        this.sensorList = this.sensorList.concat(result);
        const nameList: string[] = [];
        result.forEach((sensor) => nameList.push(sensor.name));
        this.content = this.content.concat(nameList);
        resolve();
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        reject(error);
      });
    });
    return promise;
  }

  private loadAirQuialitySensorsByRoom(): Promise<void> {
    throw new Error('Method not implemented.');

    // const promise = new Promise<void>((resolve, reject) => {
    //   this.airQuality.getByRoomId(this.roomId).subscribe((result) => {
    //     this.sensorList = this.sensorList.concat(result);
    //     const nameList: string[] = [];
    //     result.forEach((sensor) => nameList.push(sensor.name));
    //     this.content = this.content.concat(nameList);
    //     resolve();
    //   }, (error) => {
    //     this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    //     reject(error);
    //   });
    // });
    // return promise;
  }
  private loadWaterQuialitySensorsByRoom(): Promise<void> {
    throw new Error('Method not implemented.');

    // const promise = new Promise<void>((resolve, reject) => {
    //   this.waterQuality.getByRoomId(this.roomId).subscribe((result) => {
    //     this.sensorList = this.sensorList.concat(result);
    //     const nameList: string[] = [];
    //     result.forEach((sensor) => nameList.push(sensor.name));
    //     this.content = this.content.concat(nameList);
    //     resolve();
    //   }, (error) => {
    //     this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    //     reject(error);
    //   });
    // });
    // return promise;
  }
  private loadWeatherQuialitySensorsByRoom(): Promise<void> {
    throw new Error('Method not implemented.');

    // const promise = new Promise<void>((resolve, reject) => {
    //   this.weatherStationService.getByRoomId(this.roomId).subscribe((result) => {
    //     this.sensorList = this.sensorList.concat(result);
    //     const nameList: string[] = [];
    //     result.forEach((sensor) => nameList.push(sensor.name));
    //     this.content = this.content.concat(nameList);
    //     resolve();
    //   }, (error) => {
    //     this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    //     reject(error);
    //   });
    // });
    // return promise;
  }

  selectSensor(name: string): void {
    this.sensorList = [];
    this.sensorService.getMagneticByName(name, this.roomId).subscribe((result) => {
      this.sensorList = this.sensorList.concat(result);
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });

    this.sensorService.getAdvancedAmbienceByName(name, this.roomId).subscribe((result) => {
      this.sensorList = this.sensorList.concat(result);
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });

    this.sensorService.getSwitchByName(name, this.roomId).subscribe((result) => {
      this.sensorList = this.sensorList.concat(result);
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });

    // this.airQuality.getByName(name, this.roomId).subscribe((result) => {
    //   this.sensorList = this.sensorList.concat(result);
    // }, (error) => {
    //   this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    // });

    // this.waterQuality.getByName(name, this.roomId).subscribe((result) => {
    //   this.sensorList = this.sensorList.concat(result);
    // }, (error) => {
    //   this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    // });

    // this.weatherStationService.getByName(name, this.roomId).subscribe((result) => {
    //   this.sensorList = this.sensorList.concat(result);
    // }, (error) => {
    //   this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    // });
  }

  createSensor(): void {
    const dialogRef = this.sensorDialog.open(SensorDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      minWidth: '500px',
      data: { new: true },
    });

    dialogRef.componentInstance.newSensor = true;

    dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }

  seeDetails(id: string, type: string): void {
    this.router.navigate(['/sensors/' + this.roomId + '/' + type + '/' + id]);
  }

  updateSensor(sensor: Basic): void {
    const dialogRef = this.sensorDialog.open(SensorDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      minWidth: '500px',
      data: { new: false },
    });

    dialogRef.componentInstance.sensor = sensor;
    dialogRef.componentInstance.newSensor = false;

    dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }

  deleteSensor(id: string, type: string): void {
    switch (type) {
      case "magnetic":
        this.sensorService.deleteMagnetic(id).subscribe(() => {
          this.ngOnInit();
        }, (error) => {
          this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        });
        break;
      case "advanced_ambience":
        this.sensorService.deleteAdvancedAmbience(id).subscribe(() => {
          this.ngOnInit();
        }, (error) => {
          this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        });
        break;
      case "switch":
        this.sensorService.deleteSwitch(id).subscribe(() => {
          this.ngOnInit();
        }, (error) => {
          this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        });
        break;
      case "air_quality":
        this.airQuality.delete(id).subscribe(() => {
          this.ngOnInit();
        }, (error) => {
          this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        });
        break;
      case "water_quality":
        this.waterQuality.delete(id).subscribe(() => {
          this.ngOnInit();
        }, (error) => {
          this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        });
        break;
      case "weather_station":
        this.weatherStationService.delete(id).subscribe(() => {
          this.ngOnInit();
        }, (error) => {
          this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        });
        break;
      default:
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        break;
    }
  }
}
