<mat-card>
  <div class="section-header">
    <h1 i18n *ngIf="buildingId === 'no-building'">Rooms</h1>
    <h1 *ngIf="buildingId !== 'no-building'">{{ buildingName }}</h1>
  </div>


  <div class="rooms-container">
    <button i18n mat-raised-button class="new-room" color="primary" (click)="createRoom()">
      <span class="material-icons"> add_circle_outline </span>
      Add Room
    </button><br>

    <table mat-table [dataSource]="roomList">

      <ng-container matColumnDef="buildingId">
        <th i18n mat-header-cell *matHeaderCellDef> Building Id </th>
        <td mat-cell *matCellDef="let room"> {{ room.buildingId }} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th i18n mat-header-cell *matHeaderCellDef> Room's Name </th>
        <td mat-cell *matCellDef="let room"> {{ room.name }} </td>
      </ng-container>
      
      <ng-container matColumnDef="roomType">
        <th i18n mat-header-cell *matHeaderCellDef> Room's Type </th>
        <td mat-cell *matCellDef="let room"> {{ room.roomType | roomType }} </td>
      </ng-container>

      <ng-container matColumnDef="options">
        <th i18n width="25%" mat-header-cell *matHeaderCellDef> Options </th>
        <td width="25%" mat-cell *matCellDef="let room">
          <button mat-flat-button color="primary" (click)="seeAction(room.id, room.name)">
            <span class="material-icons"> visibility </span>
          </button>
          <button mat-flat-button color="primary" (click)="seeSensors(room.id)">
            <span class="material-icons"> sensors </span>
          </button>
          <button mat-flat-button color="primary" (click)="updateRoom(room)">
            <span class="material-icons"> edit </span>
          </button>
          <button mat-flat-button color="primary" (click)="deleteRoom(room.id)">
            <span class="material-icons"> delete </span>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

</mat-card>