import { HttpClient } from '@angular/common/http';
import { IZamakonaSensorService } from './parent/zamakonaSensorService';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { WaterQuality } from '../../models/zamakona/waterQuality';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { mockWaterQuality } from 'src/app/models/zamakona/mocks/mockWaterQuality';

@Injectable({
  providedIn: 'root',
})
export class WaterQualityService implements IZamakonaSensorService {
  private baseUrl: string = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private socket: Socket,
  ) { }

  changeSignalSwitch(body: { id: string; signal: string; }): Observable<{ id: string; signal: string; }> {
    throw new Error('Method not implemented.');
  }

  newData(): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.on('new-data', (dato: any) => {
        subscriber.next(dato);
      });
    });
  }

  getByName(name: string): Observable<WaterQuality[]> {
    const url: string = "waterQuality/getByName";
    return this.http.get<WaterQuality[]>(this.baseUrl + url + '?name=' + name).pipe(map((result: WaterQuality[]) => this.typingDataArr(result)));
    // if (!environment.enableMock) {
    //   return this.http.get<WaterQuality[]>(this.baseUrl + url + '?name=' + name).pipe(map((result: WaterQuality[]) => this.typingDataArr(result)));
    // } else {
    //   const info = new Observable<WaterQuality[]>((subscriber) => {
    //     subscriber.next([mockWaterQuality]);
    //   });
    //   return info;
    // }
  }

  getAll(): Observable<WaterQuality[]> {
    const url: string = "waterQuality/getAll";
    if (!environment.enableMock) {
      return this.http.get<WaterQuality[]>(this.baseUrl + url).pipe(map((result: WaterQuality[]) => this.typingDataArr(result)));
    } else {
      const info = new Observable<WaterQuality[]>((subscriber) => {
        subscriber.next([mockWaterQuality]);
      });
      return info;
    }
  }

  getById(id: string): Observable<WaterQuality> {
    const url: string = "waterQuality/getById";
    if (!environment.enableMock) {
      return this.http.get<WaterQuality>(this.baseUrl + url + '?id=' + id).pipe(map((result: WaterQuality) => this.typingData(result)));
    } else {
      const info = new Observable<WaterQuality>((subscriber) => {
        subscriber.next(mockWaterQuality);
      });
      return info;
    }
  }

  create(sensor: WaterQuality): Observable<WaterQuality> {
    const url: string = "waterQuality/create";
    return this.http
      .post<WaterQuality>(this.baseUrl + url, sensor).pipe(map((result) => this.typingData(result)));
  }

  update(sensor: WaterQuality): Observable<WaterQuality> {
    const url: string = "waterQuality/update";
    return this.http
      .put<WaterQuality>(this.baseUrl + url, sensor).pipe(map((result) => this.typingData(result)));
  }

  delete(id: string): Observable<WaterQuality> {
    const url: string = "waterQuality/delete";
    return this.http.delete<any>(this.baseUrl + url + '?id=' + id).pipe(map((result) => this.typingData(result)));
  }

  typingDataArr(result: WaterQuality[]): WaterQuality[] {
    return result.map((sensor: WaterQuality) => {
      return WaterQuality.createByJson(sensor);
    });
  }

  typingData(result: WaterQuality): WaterQuality {
    return WaterQuality.createByJson(result);
  }
}
