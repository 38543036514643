<mat-spinner [ngStyle]="{'display': loading ? 'block' : 'none'}">
  
</mat-spinner>

<mat-card [ngStyle]="{'display': loading ? 'none' : 'block'}">
    <div class="advanced-title">
      <h1 i18n>Room Map</h1>
    </div>
    <div class="img-container">
      <mat-form-field appearance="outline">
        <mat-select [(ngModel)]="selectedRoom" (selectionChange)="getSensorsByRoomId()">
          <mat-option *ngFor="let room of roomList" [value]="room">
            {{room.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="magneticList.length > 0" class="color magnetic" (click)="infoDialogMagnetic()"></div>
      <div *ngIf="ambienceList.length > 0" class="color ambiance" (click)="infoDialogAmbience()"></div>
      <div *ngIf="switchList.length > 0" class="color switch" (click)="infoDialogSwitch()"></div>
      <img src="assets/room_isometric_final.png">
    </div>
</mat-card>