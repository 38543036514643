<mat-card>
  <div class="advanced-title">
    <h1 i18n>Switch Info</h1>
  </div>
  <div class="switch-detail-container">
    <button mat-raised-button class="back" color="primary" (click)="back()">
      <span class="material-icons"> arrow_back </span>
    </button>
  
    <div class="content">
      <div class="card">
        <div i18n class="title"> Id: </div>
        <div class="value"> {{ sensor.id }} </div>
      </div>
  
      <div class="card">
        <div i18n class="title"> Signal: </div>
        <div class="value">
          <span class="material-icons connected" *ngIf="sensor.signal === 'connected'"> sensors </span>
          <span class="material-icons disconnected" *ngIf="sensor.signal === 'disconnected'"> sensors </span>
          <span class="material-icons signal_lost" *ngIf="sensor.signal === 'signal_lost'"> sensors </span>
        </div>
      </div>
      
      <div class="card">
        <div i18n class="title"> Room Id: </div>
        <div class="value"> {{ sensor.roomId }} </div>
      </div>
      
      <div class="card">
        <div i18n class="title"> Name: </div>
        <div class="value"> {{ sensor.name }} </div>
      </div>

            
      <div class="card">
        <div i18n class="title"> Description: </div>
        <div class="value"> {{ sensor.description }} </div>
      </div>

      <div class="card">
        <div i18n class="title"> Creation Date: </div>
        <div class="value"> {{ sensor.dateCreated | date:'medium' }} </div>
      </div>

      <div class="card">
        <div i18n class="title"> Last Modification: </div>
        <div class="value"> {{ sensor.dateModified | date:'medium' }} </div>
      </div>
      
      <div class="card">
        <div i18n class="title"> Device 1: </div>
        <div class="value"> {{ sensor.device1 }} </div>
      </div>
      
      <div class="card">
        <div i18n class="title"> Status Device 1: </div>
        <div class="value">
          <mat-slide-toggle [checked]="sensor.status1==='on'" [disabled]="sensor.signal !== 'connected'" (click)="changeStatus(1)"> {{ sensor.status1 }}</mat-slide-toggle>
        </div>
      </div>
      
      <div class="card">
        <div i18n class="title"> Device 2: </div>
      <div class="value"> {{ sensor.device2 }} </div>
    </div>
    
      <div class="card">
        <div i18n class="title"> Status Device 2: </div>
        <div class="value">
          <mat-slide-toggle [checked]="sensor.status2==='on'" [disabled]="sensor.signal !== 'connected'" (click)="changeStatus(2)"> {{ sensor.status2 }}</mat-slide-toggle>
        </div>
      </div>
    </div>
    <button i18n class="disconnect-button" mat-flat-button color="primary" (click)="changeSignal()" *ngIf="sensor.signal !== 'signal_lost' && sensor.signal === 'connected'">
      Disconnect
    </button>
    <button i18n class="disconnect-button" mat-flat-button color="primary" (click)="changeSignal()" *ngIf="sensor.signal !== 'signal_lost' && sensor.signal === 'disconnected'">
      Connect
    </button>
  </div>
</mat-card>