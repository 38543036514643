export abstract class ZamakonaSensor {
  id: string
  type: string
  battery: number
  dateCreated: string
  dateModified: string
  description: string
  name: string
  signal: string
  lat: number
  lng: number

  constructor(
    id: string,
    type: string,
    battery: number,
    dateCreated: string,
    dateModified: string,
    description: string,
    name: string,
    signal: string,
    lat: number,
    lng: number
  ) {
    this.id = id
    this.type = type
    this.battery = battery
    this.dateCreated = dateCreated
    this.dateModified = dateModified
    this.description = description
    this.name = name
    this.signal = signal
    this.lat = lat
    this.lng = lng
  }
}
