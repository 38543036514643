import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { User } from '../models/user';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,
    private router: Router
  ) { }


  /**
   * Intercepta las peticiones HTTP para agregar el token de acceso y manejar errores.
   * @param request La petición HTTP a interceptar.
   * @param next El manejador HTTP.
   * @returns La petición manejada con el token de acceso.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Obtiene el token de acceso desde las cookies
    const access_token = this.authService.getCookie('access_token');
    const user_data = this.authService.getCookie('user_data')
    const refresh_token = this.authService.getCookie('refresh_token')
    // Si el token está disponible, agrégalo a la petición

    if (access_token) {
      request = this.addToken(request, access_token);
    }

    return next.handle(request).pipe(
      catchError((error) => {

        // Si el error es 401 (No autorizado) y el token está presente, intenta manejar el token expirado
    

        if (error.status == 401 && refresh_token && refresh_token!='' && user_data && user_data!='') {
        
          let user = JSON.parse(user_data)
          console.log(user_data)
          return this.handleTokenExpired(request, next, user.id);

        } else{
          this.authService.removeTokensAnddata()
          this.router.navigate(['login']);
        }
        // Si el error no es 401, reenvía el error original
      
        return throwError(error.message);
      })
    );
  }

  /**
   * Clona la petición original y agrega el token de acceso en los encabezados.
   * @param request La petición original.
   * @param token El token de acceso.
   * @returns La nueva petición con el token agregado.
   */
  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    });
  }

  /**
   * Maneja el caso en que el token de acceso ha expirado.
   * @param request La petición original que falló.
   * @param next El manejador HTTP.
   * @returns La petición reintentada con el nuevo token de acceso.
   */
  private handleTokenExpired(request: HttpRequest<any>, next: HttpHandler, user_Id: string): Observable<HttpEvent<any>> {

    // Intenta refrescar el token de acceso
    return this.authService.refreshAccessToken(user_Id).pipe(
      switchMap((data: { user: User, new_access_token: string; new_refresh_token: string }) => {
        // Verifica si el nuevo token de acceso es válido
        const new_access_token = data.new_access_token;
     
        if (new_access_token) {

          // Guarda el nuevo token de acceso en una cookie o en almacenamiento local si es necesario
          this.authService.setUser(data.user)

          this.authService.setCookie('access_token', data.new_access_token, 60)// 1 hora
          this.authService.setCookie('refresh_token', data.new_refresh_token, 60 * 24) // 1dia
          this.authService.setCookie('user_data', JSON.stringify(data.user), 60 * 24) // 1dia
          
          // Reintenta la petición original con el nuevo token
          return next.handle(this.addToken(request, new_access_token));

        } else {
          // Si el nuevo token es null, redirige a la página de inicio de sesión
          console.error('El nuevo token de acceso es null. Redirigiendo a la página de inicio de sesión.');
          this.authService.removeTokensAnddata()
          this.router.navigate(['login']);
        
          return throwError('No se pudo obtener un nuevo token de acceso.');
        }
      }),
      catchError((error) => {
        // Maneja cualquier error al refrescar el token (e.g., redirige a la página de inicio de sesión)
        console.error('Token expirado:', error);
  
        this.authService.removeTokensAnddata()
        this.router.navigate(['login']);
        return throwError(error);
      })
    );
  }

}
