import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const minMaxTemperatureValidator: ValidatorFn = (control: AbstractControl):
    ValidationErrors | null => {
  const tempMin = control.get('tempMin');
  const tempMax = control.get('tempMax');

  return tempMin && tempMax && ((tempMin.value < tempMax.value) ||
    (tempMin.value >= tempMax.value && tempMax.value <= 0) ||
    (tempMin.value === 0 && tempMax.value === 0)) ?
    null : { invalidTemp: true };
};

export const minMaxTemperatureDisabledValidator: ValidatorFn = (control: AbstractControl):
    ValidationErrors | null => {
  const tempMin = control.get('tempMin');
  const tempMax = control.get('tempMax');

  return tempMin && tempMax && ((tempMin.value === 0 && tempMax.value > 0) ||
      (tempMin.value > 0 && tempMax.value === 0)) ?
    { invalidDisabledTemp: true } : null;
};
