import { ZamakonaSensor } from "./parent/zamakonaSensor";

/* eslint-disable camelcase */
export class AirQuality extends ZamakonaSensor {
  chargingStatus: number
  humidity: number
  hydrogen: number
  methane: number
  monoxide: number
  noiseFast: number
  noiseSlow: number
  particlePmx01: number
  particlePmx02: number
  particlePmx03: number
  pressure: number

  constructor(
    id: string,
    type: string,
    battery: number,
    chargingStatus: number,
    dateCreated: string,
    dateModified: string,
    description: string,
    humidity: number,
    hydrogen: number,
    methane: number,
    monoxide: number,
    name: string,
    noiseFast: number,
    noiseSlow: number,
    particlePmx01: number,
    particlePmx02: number,
    particlePmx03: number,
    pressure: number,
    signal: string,
    lat: number,
    lng: number
  ) {
    super(id, type, battery, dateCreated, dateModified, description, name, signal, lat, lng);
    this.id = id;
    this.type = type;
    this.battery = battery;
    this.chargingStatus = chargingStatus;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.description = description;
    this.humidity = humidity;
    this.hydrogen = hydrogen;
    this.methane = methane;
    this.monoxide = monoxide;
    this.name = name;
    this.noiseFast = noiseFast;
    this.noiseSlow = noiseSlow;
    this.particlePmx01 = particlePmx01;
    this.particlePmx02 = particlePmx02;
    this.particlePmx03 = particlePmx03;
    this.pressure = pressure;
    this.signal = signal;
    this.lat = lat;
    this.lng = lng;
  }

  static createByJson(data: {
    id: string,
    type: string,
    battery: number,
    chargingStatus: number,
    dateCreated: string,
    dateModified: string,
    description: string,
    humidity: number,
    hydrogen: number,
    methane: number,
    monoxide: number,
    name: string,
    noiseFast: number,
    noiseSlow: number,
    particlePmx01: number,
    particlePmx02: number,
    particlePmx03: number,
    pressure: number,
    signal: string,
    lat: number,
    lng: number
  }) {
    return new AirQuality(
      data.id,
      data.type,
      data.battery,
      data.chargingStatus,
      data.dateCreated,
      data.dateModified,
      data.description,
      data.humidity,
      data.hydrogen,
      data.methane,
      data.monoxide,
      data.name,
      data.noiseFast,
      data.noiseSlow,
      data.particlePmx01,
      data.particlePmx02,
      data.particlePmx03,
      data.pressure,
      data.signal,
      data.lat,
      data.lng
    );
  }
}
