/* eslint-disable max-len */
import { CriteriaService } from './../../services/criteria.service';
import { Notification } from 'src/app/models/notification';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectForm } from 'src/app/interfaces/selectForm';
import { NotificationService } from 'src/app/services/notification.service';
import { BuildingService } from 'src/app/services/building.service';
import { RoomService } from 'src/app/services/room.service';
import { environment } from 'src/environments/environment';
import { Building } from 'src/app/models/building';
import { Room } from 'src/app/models/room';
import { PageEvent } from '@angular/material/paginator';
import { CriteriaNotificationsFilter } from 'src/app/interfaces/criteria-notifications-filter';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {
  pageEvent: PageEvent;
  language: string = '';
  buildingRoomSelector: FormGroup;
  buildingNameField: FormControl;
  roomNameField: FormControl;

  buildingList: Building[] = [];
  roomList: Room[] = [];

  notificationsFilter: FormGroup;
  levelField: FormControl;
  timeField: FormControl;
  readField: FormControl;

  levelList: SelectForm[] = [
    { value: 'All', name: $localize`All` },
    { value: 'Info', name: $localize`Info` },
    { value: 'Warning', name: $localize`Warning` },
    { value: 'Error', name: $localize`Error` },
  ];
  timeList: SelectForm[] = [
    { value: 'Last 24 hours', name: $localize`Last 24 hours` },
    { value: 'Last 7 days', name: $localize`Last 7 days` },
    { value: 'Last 30 days', name: $localize`Last 30 days` },
    { value: 'All', name: $localize`All` },
  ];
  readList: SelectForm[] = [
    { value: 'All', name: $localize`All` },
    { value: 'Read', name: $localize`Read` },
    { value: 'Not Read', name: $localize`Not Read` },
  ];

  textSearchControl: FormControl;

  notificationsPaged: Notification[] = [];
  notificationsSearched: Notification[] = [];

  constructor(
    public notificationService: NotificationService,
    private snackBar: MatSnackBar,
    private buildingService: BuildingService,
    private criteriaService: CriteriaService,
    private roomService: RoomService,
  ) {
    this.pageEvent = new PageEvent();
    this.language = environment.language;
    this.notificationService.building = '';
    this.notificationService.room = '';
    this.notificationService.level = 'all';
    this.notificationService.time = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
    this.notificationService.read = 'all';
    this.buildingNameField = new FormControl('', Validators.required);
    this.roomNameField = new FormControl('', Validators.required);
    this.buildingRoomSelector = new FormGroup({
      buildingName: this.buildingNameField,
      roomName: this.roomNameField,
    });
    this.levelField = new FormControl('All', Validators.required);
    this.timeField = new FormControl('Last 24 hours', Validators.required);
    this.readField = new FormControl('All', []);
    this.notificationsFilter = new FormGroup({
      level: this.levelField,
      time: this.timeField,
      read: this.readField,
    });
    this.textSearchControl = new FormControl('');
  }

  ngOnInit(): void {
    this.buildingService.getAllBuildings().subscribe((result) => {
      this.buildingList = result;
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`);
    });
    this.buildingNameField.valueChanges.subscribe((buildingName) => {
      this.roomNameField.setValue('');
      this.notificationService.building = buildingName;
      const building =
        <Building> this.buildingList.find((building) => building.name === buildingName);
      this.roomService.getRoomsByBuilding(building.id).subscribe((result) => {
        this.roomList = result;
      });
    });
    this.roomNameField.valueChanges.subscribe((roomName) => {
      this.notificationService.room = roomName;
      const filter: CriteriaNotificationsFilter = this.criteriaService.assignCriteria(this.notificationService.read === 'all', this.notificationService.level === 'all');
      filter.getNotificationsWithFilter().then((not: Notification[]) => {
        this.notificationService.notificationsFiltered = not;
        this.notificationService.clasifyNotifications(this.buildingNameField.value,
            this.roomNameField.value);
        this.pageEvent.length = this.notificationService.notificationsClasified.length;
        this.pageEvent.pageIndex = 0;
        this.pageEvent.pageSize = 50;
        this.notificationsSearched = this.notificationService.notificationsClasified;
        this.notificationsPaged = this.notificationService.notificationsClasified.slice(0, 50);
      });
    });
    this.notificationsFilter.valueChanges.subscribe(() => {
      this.filter();
    });
    this.textSearchControl.valueChanges.subscribe(() => {
      this.changePage();
    });
  }

  filter(): void {
    this.notificationService.level = this.levelField.value.toLowerCase();
    switch (this.timeField.value) {
      case 'Last 24 hours':
        this.notificationService.time = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
        break;
      case 'Last 7 days':
        this.notificationService.time = new Date(new Date().getTime() -
          (60 * 60 * 1000 + 7 * 24 * 60 * 60 * 1000));
        break;
      case 'Last 30 days':
        this.notificationService.time = new Date(new Date().getTime() -
          (60 * 60 * 1000 + 30 * 24 * 60 * 60 * 1000));
        break;
      default:
        this.notificationService.time = new Date(0);
        break;
    }
    if (this.readField.value.toLowerCase() === 'all') {
      this.notificationService.read = 'all';
    } else if (this.readField.value.toLowerCase() === 'read') {
      this.notificationService.read = 'true';
    } else {
      this.notificationService.read = 'false';
    }

    const filter: CriteriaNotificationsFilter = this.criteriaService.assignCriteria(this.notificationService.read === 'all', this.notificationService.level === 'all');
    filter.getNotificationsWithFilter().then((notifications: Notification[]) => {
      this.notificationService.notificationsFiltered = notifications;
      this.notificationService.clasifyNotifications(this.buildingNameField.value,
          this.roomNameField.value);
      this.changePage();
    });
  }

  changePage(pageEvent?: PageEvent): void {
    if (pageEvent) {
      this.pageEvent = pageEvent;
    } else {
      this.pageEvent.pageIndex = 0;
    }
    this.notificationsSearched = this.notificationService.notificationsClasified.filter((not) => {
      return not.message[this.language].toLowerCase().includes(
          this.textSearchControl.value.toLowerCase());
    });
    this.notificationsPaged = this.notificationsSearched.slice(
        this.pageEvent.pageIndex * this.pageEvent.pageSize,
        (this.pageEvent.pageIndex + 1) * this.pageEvent.pageSize);
    this.pageEvent.length = this.notificationsSearched.length;
    console.log(this.notificationsSearched);
  }

  readAll(): void {
    if (this.notificationService.notificationsNotRead == 0) {
      this.snackBar.open($localize`There isn\'t any notification not read`, '', { duration: 5000 });
    } else {
      this.notificationService.readAllNotification().subscribe(() => {
        this.notificationService.notificationsNotRead = 0;
        const filter: CriteriaNotificationsFilter = this.criteriaService.assignCriteria(this.notificationService.read === 'all', this.notificationService.level === 'all');
        filter.getNotificationsWithFilter().then((notifications: Notification[]) => {
          this.notificationService.notificationsFiltered = notifications;
          this.notificationService.clasifyNotifications(this.buildingNameField.value,
              this.roomNameField.value);
          this.changePage(this.pageEvent);
        });
      });
    }
  }

  markAsRead(id: string, read: boolean): void {
    this.notificationService.readNotification(id, !read).subscribe(async (result) => {
      const modifySuccess = await this.notificationService.modifyNotification(result);
      if (!modifySuccess) {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
      } else {
        this.changePage(this.pageEvent);
      }
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });
  }

  isString(v: string): boolean {
    return typeof v === 'string';
  }
}
