import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import {
  DateRange,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDateRangeSelectionStrategy,
  MatDatepickerModule,
} from '@angular/material/datepicker';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  // TODO: corregir esto para que use los perfiles
  grafanaUrl: string = environment.grafanaUrl;
  //panelUrl: string = "goto/hzI7lpWSR?orgId=1";
  // panelUrl: string = 'd-solo/f0e859d9-51b5-4f38-9f1d-44c35b9a9f6a/informacion-general?orgId=1&from=1696403608582&to=1696425208582&theme=light&panelId=1';
  //muestra los datos de las últimas 6h
  panelUrl = `d-solo/f0e859d9-51b5-4f38-9f1d-44c35b9a9f6a/informacion-general?orgId=1&refresh=30s&from=now-6h&to=now&theme=light&panelId=`
  grafanaUrlSafe!: SafeResourceUrl;
  WeatherGrafanaURL: any;
  AirGrafanaURL: any;
  WaterGrafanaURL: any
  selectedSensor: number = 1
  selectedTime: number = 12
  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    // eslint-disable-next-line max-len
    // console.log(environment)
    // console.log(this.grafanaUrl+this.panelUrl)
    // this.grafanaUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.grafanaUrl + this.panelUrl);
    // this.grafanaUrlSafe = "http://dev-smartcity.tcsa.es/gf";
    this.WeatherGrafanaURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.grafanaUrl + this.panelUrl + 3)
    this.AirGrafanaURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.grafanaUrl + this.panelUrl + 2)
    this.WaterGrafanaURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.grafanaUrl + this.panelUrl + 1)
  }

  onTimeChange(value: any) {
    const url = `d-solo/f0e859d9-51b5-4f38-9f1d-44c35b9a9f6a/informacion-general?orgId=1&refresh=30s&from=now-${value}h&to=now&theme=light&panelId=`
    this.WeatherGrafanaURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.grafanaUrl + url + 3)
    this.AirGrafanaURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.grafanaUrl + url + 2)
    this.WaterGrafanaURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.grafanaUrl + url + 1)
    this.selectedTime = value
  }
  onSensorChange(value: any) {
    this.selectedSensor = value
  }
  // selectedRange: DateRange<Date> = new DateRange(new Date(),new Date());
  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    const startDateStr = dateRangeStart.value;
    const endDateStr = dateRangeEnd.value;

    if (startDateStr && endDateStr) {
      const startDate = this.parseDate(startDateStr);
      const endDate = this.parseDate(endDateStr);

      // Verificar que las fechas sean válidas
      if (startDate && endDate) {
        // Convertir las fechas a milisegundos desde la época
        const startDateMs = startDate.getTime();
        const endDateMs = endDate.getTime();

        // Construir la URL para Grafana
        const url = `d-solo/f0e859d9-51b5-4f38-9f1d-44c35b9a9f6a/informacion-general?orgId=1&refresh=30s&from=${encodeURIComponent(startDateMs.toString())}&to=${encodeURIComponent(endDateMs.toString())}&theme=light&panelId=`;

        // Construir las URLs seguras
        this.WeatherGrafanaURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.grafanaUrl + url + 3);
        this.AirGrafanaURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.grafanaUrl + url + 2);
        this.WaterGrafanaURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.grafanaUrl + url + 1);

     
      } else {
        console.error('Fecha inválida proporcionada');
      }
    } else {
      console.error('Ambas fechas deben ser seleccionadas');
    }
  }
   parseDate(dateStr: string): Date | null {
    // Suponemos el formato dd/MM/yyyy
    const parts = dateStr.split('/');
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // Meses en JavaScript son 0-indexados
      const year = parseInt(parts[2], 10);

      const date = new Date(year, month, day);

      // Verificar si la fecha es válida
      if (date.getDate() === day && date.getMonth() === month && date.getFullYear() === year) {
        return date;
      }
    }
    return null;
  }
}
