import { ZamakonaSensor } from "./parent/zamakonaSensor";

/* eslint-disable camelcase */
export class WaterQuality extends ZamakonaSensor {
  conductivity9487: number;
  conductivityNotCompensated9487: number;
  pOxygenMgl9488: number;
  pOxygenPpm9488: number;
  pOxygenSat9488: number;
  pPh9485: number;
  pPhehtHMV9485: number;
  pRedox9485: number;
  pTemperature9485: number;
  pTemperature9488: number;
  pxTemperature9353: number;
  pxTurbidityMgl9353: number;
  pxTurbidityNtu9353: number;
  salinity9487: number;
  status: string;
  temperature9487: number;

  constructor(
    id: string,
    type: string,
    battery: number,
    conductivity9487: number,
    conductivityNotCompensated9487: number,
    dateCreated: string,
    dateModified: string,
    description: string,
    name: string,
    pOxygenMgl9488: number,
    pOxygenPpm9488: number,
    pOxygenSat9488: number,
    pPh9485: number,
    pPhehtHMV9485: number,
    pRedox9485: number,
    pTemperature9485: number,
    pTemperature9488: number,
    pxTemperature9353: number,
    pxTurbidityMgl9353: number,
    pxTurbidityNtu9353: number,
    salinity9487: number,
    signal: string,
    status: string,
    temperature9487: number,
    lat: number,
    lng: number
  ) {
    super(id, type, battery, dateCreated, dateModified, description, name, signal, lat, lng);
    this.conductivity9487 = conductivity9487;
    this.conductivityNotCompensated9487 = conductivityNotCompensated9487;
    this.pOxygenMgl9488 = pOxygenMgl9488;
    this.pOxygenPpm9488 = pOxygenPpm9488;
    this.pOxygenSat9488 = pOxygenSat9488;
    this.pPh9485 = pPh9485;
    this.pPhehtHMV9485 = pPhehtHMV9485;
    this.pRedox9485 = pRedox9485;
    this.pTemperature9485 = pTemperature9485;
    this.pTemperature9488 = pTemperature9488;
    this.pxTemperature9353 = pxTemperature9353;
    this.pxTurbidityMgl9353 = pxTurbidityMgl9353;
    this.pxTurbidityNtu9353 = pxTurbidityNtu9353;
    this.salinity9487 = salinity9487;
    this.status = status;
    this.temperature9487 = temperature9487;
    this.lat =lat;
    this.lng = lng;
  }

  static createByJson(data: {
    id: string,
    type: string,
    battery: number,
    conductivity9487: number,
    conductivityNotCompensated9487: number,
    dateCreated: string,
    dateModified: string,
    description: string,
    name: string,
    pOxygenMgl9488: number,
    pOxygenPpm9488: number,
    pOxygenSat9488: number,
    pPh9485: number,
    pPhehtHMV9485: number,
    pRedox9485: number,
    pTemperature9485: number,
    pTemperature9488: number,
    pxTemperature9353: number,
    pxTurbidityMgl9353: number,
    pxTurbidityNtu9353: number,
    salinity9487: number,
    signal: string,
    status: string,
    temperature9487: number,
    lat: number,
    lng: number
  }): WaterQuality {
    return new WaterQuality(
      data.id,
      data.type,
      data.battery,
      data.conductivity9487,
      data.conductivityNotCompensated9487,
      data.dateCreated,
      data.dateModified,
      data.description,
      data.name,
      data.pOxygenMgl9488,
      data.pOxygenPpm9488,
      data.pOxygenSat9488,
      data.pPh9485,
      data.pPhehtHMV9485,
      data.pRedox9485,
      data.pTemperature9485,
      data.pTemperature9488,
      data.pxTemperature9353,
      data.pxTurbidityMgl9353,
      data.pxTurbidityNtu9353,
      data.salinity9487,
      data.signal,
      data.status,
      data.temperature9487,
      data.lat,
      data.lng
    )
  }
}
