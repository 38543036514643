import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeSignalBody } from 'src/app/interfaces/changeSignalBody';
import { Magnetic } from 'src/app/models/magnetic';
import { SensorService } from 'src/app/services/sensor.service';

@Component({
  selector: 'app-magnetic-detail',
  templateUrl: './magnetic-detail.component.html',
  styleUrls: ['./magnetic-detail.component.scss'],
})
export class MagneticDetailComponent implements OnInit {
  sensorId!: string;
  roomId: string = 'no-room';
  sensor: Magnetic = new Magnetic('', '', '', '', '', new Date(), new Date(), 0, '', '');

  constructor(
    private sensorService: SensorService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) {
    // Get the group id from the route
    this.sensorId = this.route.snapshot.params.sensorId;
    this.roomId = this.route.snapshot.params.roomId;
  }

  ngOnInit(): void {
    this.sensorService.getMagneticById(this.sensorId).subscribe((result) => {
      this.sensor = result;
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });
  }

  back(): void {
    this.router.navigate(['/sensors/' + this.roomId]);
  }

  changeSignal(): void {
    const body: ChangeSignalBody = {
      id: this.sensor.id,
      signal: (this.sensor.signal === 'connected') ? 'disconnected' : 'connected',
    };
    this.sensorService.changeSignalSwitch(body).subscribe(() => {
      this.ngOnInit();
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });
  }
}
