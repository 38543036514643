import { Basic } from "./basic";

export class Magnetic extends Basic {
  constructor(
    id: string,
    type: string,
    roomId: string,
    name: string,
    description: string,
    dateCreated: Date,
    dateModified: Date,
    battery: number,
    signal: string,
    public status: string,
  ) {
    super(id, type, roomId, name, description, dateCreated,
      dateModified, battery, signal);
  }
}
