/* eslint-disable indent */

import { Component, OnInit } from '@angular/core';

import { AirQuality } from 'src/app/models/zamakona/airQuality';
import { AirQualityService } from 'src/app/services/zamakona/airQuality.service';
import { Basic } from 'src/app/models/basic';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { WaterQuality } from 'src/app/models/zamakona/waterQuality';
import { WaterQualityService } from 'src/app/services/zamakona/waterQuality.service';
import { WeatherStation } from 'src/app/models/zamakona/weatherStation';
import { WeatherStationService } from 'src/app/services/zamakona/weatherStation.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Subsensor } from 'src/app/models/zamakona/paneles/subSensor';
import { DatosSensores } from 'src/app/models/zamakona/paneles/datosSensores';
import { PanelsService } from 'src/app/services/panels.services';

@Component({
  selector: 'app-zamakona-sensors',
  templateUrl: './zamakona-sensors.component.html',
  styleUrls: ['./zamakona-sensors.component.scss'],
})
export class ZamakonaSensorsComponent implements OnInit {

  displayedColumns: string[] = ['name', 'signal', 'battery', 'options'];
  sensorList: any[] = [];
  filteredOptions: Observable<string[]> | undefined;
  searchValue: string = '';
  content: string[] = [];
  searchControl = new FormControl();
  grafanaUrl: string = environment.grafanaUrl;
  airQualityPanelId: string = environment.realTimePanelAirQuality;
  waterQualityPanelId: string = environment.realTimePanelWaterQuality;
  weatherStationPanelId: string = environment.realTimePanelWeatherStation;
  paneles: Subsensor[] = [];
  

  constructor(
    private airQuality: AirQualityService,
    private snackBar: MatSnackBar,
    private waterQuality: WaterQualityService,
    private weatherStationService: WeatherStationService,
    private router: Router,
    private panelsService: PanelsService) { }


  ngOnInit() {
    this.loadAllSensors();
  }
  loadAllSensors() {
    const promise = new Promise<void>(async (resolve) => {
      await this.loadAllAirQuialitySensors();
      await this.loadAllWaterQuialitySensors();
      await this.loadAllWeatherQuialitySensors();
      resolve();
    });
    return promise;
  }

  private loadAllAirQuialitySensors(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.airQuality.getAll().subscribe((result) => {
        this.sensorList = this.sensorList.concat(result);
        const nameList: string[] = [];
        result.forEach((sensor) => nameList.push(sensor.name));
        this.content = this.content.concat(nameList);
        resolve();
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        reject(error);
      });
    });
    return promise;
  }

  private loadAllWaterQuialitySensors(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.waterQuality.getAll().subscribe((result) => {
        this.sensorList = this.sensorList.concat(result);
        const nameList: string[] = [];
        result.forEach((sensor) => nameList.push(sensor.name));
        this.content = this.content.concat(nameList);
        resolve();
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        reject(error);
      });
    });
    return promise;
  }

  private loadAllWeatherQuialitySensors(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.weatherStationService.getAll().subscribe((result) => {
        this.sensorList = this.sensorList.concat(result);
        const nameList: string[] = [];
        result.forEach((sensor) => nameList.push(sensor.name));
        this.content = this.content.concat(nameList);
        resolve();
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        reject(error);
      });
    });
    return promise;
  }

  async obtenerSubsensoresPorDescripcion(sensorType: string): Promise<Subsensor[]> {

    const promise = new Promise<Subsensor[]>((resolve, reject) => {
      this.panelsService.getAll(sensorType).subscribe((result) => {
       //this.paneles = result;
        resolve(result);
      }, (error) => {
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        reject(error);
      });
    });
    return promise;

    /*try {
      // Lee el archivo JSON desde una ruta específica.
      const datosSensores: DatosSensores = JSON.parse(fs.readFileSync("ruta/al/archivo/datosSensores.json", "utf-8"));
  
      const panelEncontrado = datosSensores.paneles.find(
        (panel) => panel.descripcion_sensor === descripcion
      );
  
      if (panelEncontrado) {
        return panelEncontrado.subsensores;
      } else {
        // Si no se encuentra la descripción del sensor, devuelve un array vacío.
        return [];
      }
    } catch (error) {
      console.error("Error al leer y procesar los datos:", error);
      return [];
    }*/
  }

  async seeDetails(sensor: unknown): Promise<void> {
    try {
      let paneles: Subsensor[] = [];
      let urlPanel: string = '';

      if (sensor instanceof AirQuality) {
        paneles = await this.obtenerSubsensoresPorDescripcion("AirQuality");
        urlPanel = this.airQualityPanelId;
      } else if (sensor instanceof WaterQuality) {
        paneles = await this.obtenerSubsensoresPorDescripcion("WaterQuality");
        urlPanel = this.waterQualityPanelId;
      } else if (sensor instanceof WeatherStation) {
        paneles = await this.obtenerSubsensoresPorDescripcion("WeatherStation");
        urlPanel = this.weatherStationPanelId;
      } else {
        throw new Error('Invalid sensor type');
      }
  
      this.router.navigate(['/zamakona-sensors-real-time-dashboard'], {
        state: { historicData: { sensor, grafanaUrlSafe: this.grafanaUrl + urlPanel, paneles: paneles } }
      });
    } catch (error) {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    }
  }

  createSensor(): void {
    // const dialogRef = this.sensorDialog.open(SensorDialogComponent, {
    //   hasBackdrop: true,
    //   disableClose: true,
    //   minWidth: '500px',
    //   data: { new: true },
    // });

    // dialogRef.componentInstance.newSensor = true;

    // dialogRef.afterClosed().subscribe(() => {
    //   setTimeout(() => {
    //     this.ngOnInit();
    //   }, 500);
    // });
  }

  updateSensor(sensor: Basic): void {
    // const dialogRef = this.sensorDialog.open(SensorDialogComponent, {
    //   hasBackdrop: true,
    //   disableClose: true,
    //   minWidth: '500px',
    //   data: { new: false },
    // });

    // dialogRef.componentInstance.sensor = sensor;
    // dialogRef.componentInstance.newSensor = false;

    // dialogRef.afterClosed().subscribe(() => {
    //   setTimeout(() => {
    //     this.ngOnInit();
    //   }, 500);
    // });
  }

  deleteSensor(id: string, type: string): void {
    switch (type) {
      case "air_quality":
        this.airQuality.delete(id).subscribe(() => {
          this.ngOnInit();
        }, (error) => {
          this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        });
        break;
      case "water_quality":
        this.waterQuality.delete(id).subscribe(() => {
          this.ngOnInit();
        }, (error) => {
          this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        });
        break;
      case "weather_station":
        this.weatherStationService.delete(id).subscribe(() => {
          this.ngOnInit();
        }, (error) => {
          this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        });
        break;
      default:
        this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
        break;
    }
  }

  selectSensor(name: string): void {
    this.sensorList = [];

    this.airQuality.getByName(name).subscribe((result) => {
      this.sensorList = this.sensorList.concat(result);
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });

    this.waterQuality.getByName(name).subscribe((result) => {
      this.sensorList = this.sensorList.concat(result);
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });

    this.weatherStationService.getByName(name).subscribe((result) => {
      this.sensorList = this.sensorList.concat(result);
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });
  }
}
