export class Building {
  constructor(
    public id: string,
    public type: string,
    public name: string,
    public description: string,
    public dateCreated: Date,
    public dateModified: Date,
    public buildingType: string,
    public lat: number,
    public lng: number,
  ) {}
}
