import { HttpClient } from '@angular/common/http';
import { IZamakonaSensorService } from './parent/zamakonaSensorService';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { WeatherStation } from '../../models/zamakona/weatherStation';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { mockWeatherStation } from 'src/app/models/zamakona/mocks/mockWeatherStation';

@Injectable({
  providedIn: 'root',
})
export class WeatherStationService implements IZamakonaSensorService {
  private baseUrl: string = environment.backendUrl;

  constructor(
    private http: HttpClient,
    private socket: Socket,
  ) { }
  changeSignalSwitch(body: { id: string; signal: string; }): Observable<{ id: string; signal: string; }> {
    throw new Error('Method not implemented.');
  }

  newData(): Observable<WeatherStation> {
    return new Observable((subscriber) => {
      this.socket.on('new-data', (dato: WeatherStation) => {
        subscriber.next(dato);
      });
    });
  }

  getAll(): Observable<WeatherStation[]> {
    const url: string = "weatherStation/getAll";
    return this.http.get<WeatherStation[]>(this.baseUrl + url).pipe(map((result: WeatherStation[]) => this.typingDataArr(result)));
    // if (!environment.enableMock) {
    //   return this.http.get<WeatherStation[]>(this.baseUrl + url).pipe(map((result: WeatherStation[]) => this.typingDataArr(result)));
    // } else {
    //   const info = new Observable<WeatherStation[]>((subscriber) => {
    //     subscriber.next([mockWeatherStation]);
    //   });
    //   return info;
    // }
  }

  getByName(name: string): Observable<WeatherStation[]> {
    const url: string = "weatherStation/getByName";
    if (!environment.enableMock) {
      return this.http.get<WeatherStation[]>(this.baseUrl + url + '?name=' + name).pipe(map((result: WeatherStation[]) => this.typingDataArr(result)));
    } else {
      const info = new Observable<WeatherStation[]>((subscriber) => {
        subscriber.next([mockWeatherStation]);
      });
      return info;
    }
  }

  getById(id: string): Observable<WeatherStation> {
    const url: string = "weatherStation/getById";
    if (!environment.enableMock) {
      return this.http.get<WeatherStation>(this.baseUrl + url + '?id=' + id).pipe(map((result: WeatherStation) => this.typingData(result)));
    } else {
      const info = new Observable<WeatherStation>((subscriber) => {
        subscriber.next(mockWeatherStation);
      });
      return info;
    }
  }

  create(sensor: WeatherStation): Observable<WeatherStation> {
    const url: string = "weatherStation/create";
    return this.http.post<WeatherStation>(this.baseUrl + url, sensor);
  }

  update(sensor: WeatherStation): Observable<WeatherStation> {
    const url: string = "weatherStation/update";
    return this.http
      .put<WeatherStation>(this.baseUrl + url, sensor);
  }

  delete(id: string): Observable<WeatherStation> {
    const url: string = "weatherStation/delete";
    return this.http.delete<WeatherStation>(this.baseUrl + url + '?id=' + id);
  }

  typingDataArr(result: WeatherStation[]) {
    return result.map((sensor: any) => {
      return WeatherStation.createByJson(sensor);
    });
  }

  typingData(result: any) {
    return WeatherStation.createByJson(result);
  }
}
