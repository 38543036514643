import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-zamakona-sensor-speedmeter',
  templateUrl: './zamakona-sensor-speedmeter.component.html',
  styleUrls: ['./zamakona-sensor-speedmeter.component.css']
})
export class ZamakonaSensorSpeedmeterComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() minSpeed: string = "";
  @Input() maxSpeed: string = "";
  @Input() currentSpeed: string = "";
  @Input() description: string = "description";
  @Input() unit: string = "";

  @ViewChild('canvas', {static: true}) canvasRef!: ElementRef<HTMLCanvasElement>;

  constructor( private cdr: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.drawSpeedMeter();
    this.cdr.detectChanges();
  }

  ngOnInit() { 
    
  }

  ngOnChanges(changes: SimpleChanges) {
 
    if (changes.currentSpeed && !changes.currentSpeed.firstChange) {
      this.drawSpeedMeter();
    }
  }

  drawSpeedMeter() {
    const canvas = this.canvasRef.nativeElement;
    const ctx = canvas.getContext('2d');
  
    if (ctx) {
      const radius = ctx.canvas.width / 2 - 40;
      const centerX = ctx.canvas.width / 2;
      const centerY = ctx.canvas.height - 10;
      const minSpeedNum = parseFloat(this.minSpeed);
      const maxSpeedNum = parseFloat(this.maxSpeed);
      const currentSpeedNum = parseFloat(this.currentSpeed);
  
      // Limpiar el lienzo
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  
      // Dibujar el arco del medidor
      ctx.beginPath();
      ctx.strokeStyle = 'rgba(173, 216, 230, 0.3)'; 
      ctx.lineWidth = 30;
      ctx.arc(centerX, centerY, radius, Math.PI, 2 * Math.PI);
      ctx.stroke();
  
      // Dibujar el marcador de velocidad actual
      ctx.beginPath();
      ctx.strokeStyle = 'rgb(35, 102, 190)'; 
      ctx.lineWidth = 29;
      const speedAngle = Math.PI + (currentSpeedNum / maxSpeedNum) * Math.PI;
      ctx.arc(centerX, centerY, radius, Math.PI, speedAngle);
      ctx.stroke();
  
      // Dibujar el indicador de velocidad actual
      ctx.beginPath();
      ctx.fillStyle = 'black';
      ctx.font = '28px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const textY = centerY - radius * 0.3;
      ctx.fillText(`${currentSpeedNum} `+this.unit, centerX, textY);
  
      // Dibujar líneas y valores alrededor del arco
      const lineLength = 15;
      const valueRadius = radius + 25;
      const valueIncrement = (maxSpeedNum - minSpeedNum) / 10;
      for (let i = 0; i <= 10; i++) {
        const angle = Math.PI + (i / 10) * Math.PI;
        const startX = centerX + Math.cos(angle) * radius;
        const startY = centerY + Math.sin(angle) * radius;
        const endX = centerX + Math.cos(angle) * (radius - lineLength);
        const endY = centerY + Math.sin(angle) * (radius - lineLength);
        ctx.beginPath();
        ctx.moveTo(startX, startY);
        ctx.lineTo(endX, endY);
        ctx.strokeStyle = 'rgba(0, 0, 139, 1)'; 
        ctx.lineWidth = 1;
        ctx.stroke();
  
        // Dibujar valores
        const value = minSpeedNum + i * valueIncrement;
        const valueX = centerX + Math.cos(angle) * valueRadius;
        const valueY = centerY + Math.sin(angle) * valueRadius;
        ctx.beginPath();
        ctx.fillStyle = 'navy';
        ctx.font = '9px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(`${value.toFixed(1)}`, valueX, valueY);
      }
  
      // Dibujar la aguja
      ctx.beginPath();
      ctx.strokeStyle = 'red';
      ctx.lineWidth = 4;
      const needleAngle = Math.PI + (currentSpeedNum / maxSpeedNum) * Math.PI;
      const needleX = centerX + Math.cos(needleAngle) * (radius - 10);
      const needleY = centerY + Math.sin(needleAngle) * (radius - 10);
      ctx.moveTo(centerX, centerY);
      ctx.lineTo(needleX, needleY);
      ctx.stroke();
  
      // Dibujar el centro de la aguja
      ctx.beginPath();
      ctx.fillStyle = 'red';
      ctx.arc(centerX, centerY, 5, 0, 2 * Math.PI);
      ctx.fill();

      ctx?.closePath()
    }
  }
  
  






}
