<mat-grid-list class="dialog-header" cols="2" rowHeight="30px">
  <mat-grid-tile>
    <span i18n *ngIf="isNew">Add Room</span>
    <span i18n *ngIf="!isNew">Edit Room</span>
  </mat-grid-tile>
  <mat-grid-tile>
    <button (click)="closeDialog()">
      <span class="material-icons"> close </span>
    </button>
  </mat-grid-tile>
</mat-grid-list>
<div mat-dialog-content>
  <form autocomplete="off" class="room-form" (ngSubmit)="onSubmit()" [formGroup]="roomForm">
    
    <input id="username" style="display:none" type="text" name="fakeusernameremembered">
    <input id="password" style="display:none" type="password" name="fakepasswordremembered">

    <mat-grid-list cols="2" rowHeight="60px">
      <mat-grid-tile>
        <mat-form-field class="id-room-container">
          <mat-label i18n>Id:</mat-label>
          <input id="id" matInput formControlName="id" readonly>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field class="buildingId-room-container">
          <mat-label i18n>Building:</mat-label>
          <mat-select formControlName="buildingId">
            <mat-option *ngFor="let building of buildingList" [value]="building.id">
              {{ building.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field class="room-type-building-container">
          <mat-label i18n>Room type:</mat-label>
          <mat-select formControlName="roomType">
            <mat-option *ngFor="let type of roomTypes" [value]="type.value">
              {{ type.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field class="name-room-container">
          <mat-label i18n>Name:</mat-label>
          <input matInput type="text" formControlName="name">
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-form-field class="description-room-container">
          <mat-label i18n>Description:</mat-label>
          <input matInput type="text" formControlName="description">
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list><br>
    <button i18n mat-raised-button class="submit" color="primary" type="submit" [disabled]="!buildingIdField.valid || !nameField.valid || !descriptionField.valid">Submit</button>
  </form>
</div>