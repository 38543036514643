import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  language: string = '';

  constructor(
    public notificationService: NotificationService,
    public router: Router,
    public authService: AuthService
  ) {

  }

  ngOnInit(): void {
    this.language = environment.language;
  }

  redirectTo(uri: string): void {
    this.router.navigateByUrl('/', { skipLocationChange: false }).then(() => {
      this.router.navigate([uri]);
    });
  }

  changeLanguage(lng: string): void {
    if (environment.production) {
      window.location.href = environment.frontendUrl + lng + this.router.url;
    } else {
      const url = environment.frontendUrl.substring(0, environment.frontendUrl.length - 2);
      if (lng === 'es') {
        window.location.href = url + '2' + this.router.url;
      } else if (lng === 'en') {
        window.location.href = url + '1' + this.router.url;
      }
    }
  }
  logout() {
    this.authService.logout().subscribe((response) => {
      if (response) {
        // this.authService.deleteCookie("refresh_token")
        // this.authService.deleteCookie("access_token")
        // this.authService.deleteCookie("user_data")
        this.authService.removeTokensAnddata()
        this.router.navigate(["login"]);
      }

    }, (error) => {
      console.log("No se pudo cerrar sesión.")
      // this.authService.deleteCookie("refresh_token")
      // this.authService.deleteCookie("access_token")
      // this.authService.deleteCookie("user_data")
      this.authService.removeTokensAnddata()
      this.router.navigate(["login"]);
    })
  }
}
