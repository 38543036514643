import { Component, OnInit } from '@angular/core';
import { CameraService } from 'src/app/services/zamakona/camera.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ResponseCamera } from 'src/app/interfaces/ResponseCamera';



@Component({
  selector: 'app-zamakona-cameras',
  templateUrl: './zamakona-cameras.component.html',
  styleUrls: ['./zamakona-cameras.component.css']
})

export class ZamakonaCamerasComponent implements OnInit {


  constructor(private cameraService: CameraService,
    private snackBar: MatSnackBar,
    public router: Router,
  ) { }

  cameraList: ResponseCamera[] = [];
  columnas: string[] = ['ID', 'Nombre', 'Estado', 'Opciones'];

  ngOnInit() {
    this.getAllCameras()
  }



  getAllCameras() {
    this.cameraService.getCamera().subscribe((response: ResponseCamera[]) => {
      if (response && response.length > 0) {
        this.cameraList = response;
        this.cameraList.sort((a,b)=>
        {
          let numA = parseInt(a.id.split('_')[1]);
          let numB = parseInt(b.id.split('_')[1]);
          return numA - numB;
        })
        this.cameraList.forEach(i=>{console.log(i.id)})
      }
    }, (error) => {
      console.log(error)
    })
  }

  seeDetails(camera: ResponseCamera) {
    this.router.navigateByUrl('/', { skipLocationChange: false }).then((url) => {
      this.router.navigate(["/zamakona-cameras/camera/" + camera.id]);
    });
  }
}
