<div class="container">
  <mat-card class="login-box">
    <img class="logo" src="assets/logo/zamakona-yards-logo-vector.png" style="width: 300px;">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill">
        <mat-label>Usuario</mat-label>
        <mat-icon matPrefix>person</mat-icon>
        <input matInput formControlName="username" placeholder="Introduce tu usuario" required>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Contraseña</mat-label>
        <mat-icon matPrefix>lock</mat-icon>
        <input matInput formControlName="password" type="password" placeholder="Introduce tu contraseña" required>
      </mat-form-field>

      <button mat-raised-button  type="submit">Iniciar sesión</button>
    </form>
  </mat-card>
</div>