import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeSignalBody } from 'src/app/interfaces/changeSignalBody';
import { AdvancedAmbience } from 'src/app/models/advanced-ambience';
import { SensorService } from 'src/app/services/sensor.service';

@Component({
  selector: 'app-advanced-ambience-detail',
  templateUrl: './advanced-ambience-detail.component.html',
  styleUrls: ['./advanced-ambience-detail.component.scss'],
})
export class AdvancedAmbienceDetailComponent implements OnInit {
  sensorId!: string;
  roomId: string = 'no-room';
  sensor: AdvancedAmbience =
      new AdvancedAmbience('', '', '', '', '', new Date(), new Date(), 0, '', 0, 0, '', 0, 0, 0, 0);

  constructor(
    private sensorService: SensorService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) {
    // Get the group id from the route
    this.sensorId = this.route.snapshot.params.sensorId;
    this.roomId = this.route.snapshot.params.roomId;
  }

  ngOnInit(): void {
    this.sensorService.getAdvancedAmbienceById(this.sensorId).subscribe((result) => {
      this.sensor = result;
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });
  }

  back(): void {
    this.router.navigate(['/sensors/' + this.roomId]);
  }

  changeSignal(): void {
    const body: ChangeSignalBody = {
      id: this.sensor.id,
      signal: (this.sensor.signal === 'connected') ? 'disconnected' : 'connected',
    };
    this.sensorService.changeSignalSwitch(body).subscribe(() => {
      this.ngOnInit();
    }, (error) => {
      this.snackBar.open($localize`An error has occurred`, '', { duration: 5000 });
    });
  }
}
